// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { MainAction, OverwriteStoreAction } from "../types/actionTypes";
import { MainState } from "../types/stateTypes";
import { Main } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

const initialState: MainState = {
  // Object for Main timer.
  // This owns all Main timer information.
  inUse: false,
  timer: 0,
  offset: 0,
};

type Action = MainAction | OverwriteStoreAction;

export default (state: MainState = initialState, action: Action): MainState => {
  
  switch (action.type) {

    case "TOGGLE_MAIN": {
      const inUse = action.inUse;
      return {
        ...state,
        inUse: inUse,
      };
    }

    case "UPDATE_MAIN_OFFSET": {
      const offset = action.offset;
      return {
        ...state,
        offset: offset,
      };
    }

    case "START_MAIN_TIMER": {
      return {
        ...state,
        timer: action.value,
      };
    }

    case "STOP_MAIN_TIMER": {
      return {
        ...state,
        timer: action.value,
      };
    }

    case "UPDATE_MAIN_TIMER": {
      return {
        ...state,
        timer: action.value,
      };
    }
    
    case "START_LIGHTS_TIMER": {
      // this is a dummy, there is nothing to persist, this message is for the server
      return {
        ...state,
      };
    }

    case "CLEAR_LIGHTS_TIMER": {
      // this is a dummy, there is nothing to persist, this message is for the server
      return {
        ...state,
      };
    }

    case "OVERWRITE_STORE": {
      // TODO: currently code to add a main to the store if one doesn't exist
      if (action.store.main) {
        return action.store.main;
      } else {
        const main = {...initialState};
        return {
          inUse: main.inUse,
          timer: main.timer,
          offset: main.offset,
        };
      }
    }

    default:
      checkExhausted(action);
      return state;
  }
};
