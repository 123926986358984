// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { 
  newDefaultToggle,
} from "../logic/toggle";

import { ToggleRefButtonsAction, ToggleLockFlightAction, OverwriteStoreAction } from "../types/actionTypes";
import { ToggleState } from "../types/stateTypes";
import { Toggle } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

const toggle = newDefaultToggle();

const initialState: ToggleState = {
  // This object owns all toggle information.
  enableRefButtons: false,
  lockFlight: false,
};

type Action = ToggleRefButtonsAction | ToggleLockFlightAction | OverwriteStoreAction;

export default (state: ToggleState = initialState, action: Action): ToggleState => {
  switch (action.type) {

    case "TOGGLE_REF_BUTTONS": {
      return {
        ...state,
        enableRefButtons: action.enableRefButtons,
      };
    }

    case "TOGGLE_LOCK_FLIGHT": {
      return {
        ...state,
        lockFlight: action.lockFlight,
      };
    }

    case "OVERWRITE_STORE": {
      if (action.store.toggle) {
        return action.store.toggle;
      } else {
        return toggle;
      }
    }

    default:
//      checkExhausted(action);
      return state;
  }
};
