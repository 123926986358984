// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { 
  newAttnl,
} from "../logic/timer";
import { AttnlAction, UpdateBarLoadedAction, PlatformUpdateBarLoadedAction, OverwriteStoreAction } from "../types/actionTypes";
import { AttnlState } from "../types/stateTypes";
import { EntryAttnl } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

const initialState: AttnlState = {
  attnls: [[],[]],
};

type Action = AttnlAction | UpdateBarLoadedAction | PlatformUpdateBarLoadedAction | OverwriteStoreAction;

export default (state: AttnlState = initialState, action: Action): AttnlState => {

  switch (action.type) {

    case "UPDATE_ATTNL": {

      const attnls = state.attnls.slice()
      attnls[action.platform-1] = action.attnls

      return {
        ...state,
        attnls: attnls,
      };
    }

    // special case when forwarding across platforms - we only want this reducer to react
    case "PLATFORM_UPDATE_BARLOADED":
    // normal case
    case "UPDATE_BARLOADED": {
      // assume next lift will start after 1 minute
      if (action.loaded) {
        if (action.attnls !== null) {
          const attnls = state.attnls.slice()
          attnls[action.platform-1] = action.attnls
          return {
            ...state,
            attnls: attnls,
          };
        } else {
          return state
        }
      } else {
        return state
      }
    }

    case "OVERWRITE_STORE": {
      if (action.store.attnl) {
        return action.store.attnl;
      } else {
        const attnl = initialState;
        return attnl;
      }
    }

    default:
//      checkExhausted(action);
      return state;
  }
};
