// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import React, { FormEvent } from "react";
import { connect } from "react-redux";

import FlightDetailsBare from "./FlightDetailsBare";

import { Entry, Flight, Language } from "../../types/dataTypes";
import { GlobalState, MeetState, LiftingState, ServerState, ClockState } from "../../types/stateTypes";

import styles from "./ObsFlightDetails.module.scss";
import { FormControlTypeHack } from "../../types/utils";
import { Card, FormControl } from "react-bootstrap";
import { getString } from "../../logic/strings";
import { displayNumber } from "../../logic/units";
import { getClockSessionName } from "../../logic/clock";

interface StateProps {
  meet: MeetState;
  lifting: ReadonlyArray<LiftingState>;
  entries: ReadonlyArray<Entry>;
  language: Language;
  server: ServerState;
  clock: ClockState;
}

interface OwnProps {
  currentOnly: boolean;
}

interface InternalState {
  day: number;
  session: number;
  platform: number;
}

type Props = StateProps;

class ObsSessionDetailsBare extends React.Component<Props, InternalState> {

  localState: string | null = null;
  
  constructor(props: Props) {
    super(props);

    this.updateDay = this.updateDay.bind(this);
    this.updateSession = this.updateSession.bind(this);
    this.updatePlatform = this.updatePlatform.bind(this);

    this.localState = window.localStorage.getItem('sessionDetailsLocalState');
    this.state = this.localState !== null ? JSON.parse(this.localState) :
    {
      day: 1,
      session: 1,
      platform: 1,
    };
//    if (this.state.day > this.props.meet.lengthDays) {
//      this.setState({ day: 1 } as InternalState);
//    }
//    if (this.state.session > this.props.meet.sessionsOnDaysOnPlatforms[this.state.day-1][this.state.platform-1]) {
//      this.setState({ session: 1 } as InternalState);
//    }    
  }

  setState(state: InternalState) {
    // should be OK to merge state here as it's synchronous
    const newState = {...this.state, ...state };
    window.localStorage.setItem('sessionDetailsLocalState', JSON.stringify(newState));
    super.setState(state);
  }
  
  updateDay = (event: FormEvent<FormControlTypeHack>) => {
    const day = Number(event.currentTarget.value);
    if (this.state.day !== day) {
      // If changing day, select the first platform
      const platform = 1;
      // If changing day, select the first session
      const session = 1;
      this.setState({ day: day, session: session, platform: platform } as InternalState);
    }
  };

  updateSession = (event: FormEvent<FormControlTypeHack>) => {
    const session = Number(event.currentTarget.value);
    if (session !== undefined && this.state.session !== session) {
      this.setState({ session: session } as InternalState);
    }
  };

  updatePlatform = (event: React.FormEvent<FormControlTypeHack>) => {
    const platform = Number(event.currentTarget.value);
    if (this.state.platform !== platform) {
      this.setState({ platform: platform } as InternalState);
    }
  };

  // TODO: put this in utils somewhere as it's duplicated (same with the others)
  makeSessionOptions = () => {
  
    const sessionOptions = [];
    const sessionTemplate = "Session {session}";
    
    const day = this.state.day;
    const platform = this.state.platform;

    const sessions = this.props.meet.sessionsOnDaysOnPlatforms[day-1][platform-1]
    for (let i = 1; i <= sessions; i++) {
      const key = day + "-" + i;
      let sessionName = getClockSessionName(this.props.clock, day, platform, i)
      if (sessionName === '') {
        sessionName = i.toString()
      }
      sessionOptions.push(
        <option value={i} key={key}>
          {sessionTemplate.replace("{session}", sessionName)}
        </option>
      );
    }
    if (sessionOptions.length === 0) {
      sessionOptions.push(<option key="none">No Sessions</option>);
    }
    return sessionOptions;
  }  

  render() {


    if (this.props.entries.length === 0) {
      // have to wait for store to be overwritten (live view may get refreshed a lot)
      return(<></>)
    }

    if (this.state.day > this.props.meet.lengthDays) {
      this.setState({ day: 1 } as InternalState);
    }
    if (this.state.session > this.props.meet.sessionsOnDaysOnPlatforms[this.state.day-1][this.state.platform-1]) {
      this.setState({ session: 1 } as InternalState);
    }    
  
    const selectorStyle = { width: "230px", marginRight: "15px", fontSize: "18px" };
    const language = this.props.language;
    const lifting = this.props.lifting[this.props.server.platform-1];
//    const day = lifting.day;
//    const session = lifting.session;
//    const platform = lifting.platform;
    const day = this.state.day;
    const session = this.state.session;
    const platform = this.state.platform;

    // Make options for all of the days.
    const dayOptions = [];
    const dayTemplate = getString("lifting.footer-day-template", language);
    for (let i = 1; i <= this.props.meet.lengthDays; i++) {
      dayOptions.push(
        <option value={i} key={i}>
          {dayTemplate.replace("{N}", displayNumber(i, language))}
        </option>
      );
    }

    // Make options for all of the available platforms on the current day.
    const platformOptions = [];
    const platformTemplate = getString("lifting.footer-platform-template", language);
    const numPlatforms = this.props.meet.platformsOnDays[day - 1];
    for (let i = 1; i <= numPlatforms; i++) {
      platformOptions.push(
        <option value={i} key={i}>
          {platformTemplate.replace("{N}", displayNumber(i, language))}
        </option>
      );
    }
    const onlyPlatform = numPlatforms === 1
    
    // Get all the entries under the current lifting day, session, platform.
    const shownEntries = this.props.entries.filter((e) => {
      return e.day === day && e.session === session && e.platform === platform;
    });

    // Look through the entries to discover what flights exist, unless current flight only
    const knownFlights: Flight[] = [];
    for (let i = 0; i < shownEntries.length; i++) {
      const entry = shownEntries[i];
      if (knownFlights.indexOf(entry.flight) === -1) {
        knownFlights.push(entry.flight);
      }
    }
    knownFlights.sort();

    return (
      <div>

        <Card style={{ marginBottom: "17px" }}>
          <Card.Body style={{ display: "flex" }}>
            <FormControl
              size="lg" 
              value={this.state.day.toString()}
              as="select"
              onChange={this.updateDay}
              style={selectorStyle}
            >
              {dayOptions}
            </FormControl>

            <FormControl
              size="lg" 
              value={this.state.session.toString()}
              as="select"
              onChange={this.updateSession}
              style={selectorStyle}
            >
              {this.makeSessionOptions()}
            </FormControl>
          </Card.Body>
        </Card>        

        <div id="obsFlightDetailsView" className={styles.liftingView}>
          <div style={{minHeight: "100%", fontSize: 'calc((1vw + 1vh)*' + 1 + ')'}}>
            <FlightDetailsBare onlyPlatform={onlyPlatform} day={day} session={session} platform={platform} knownFlights={knownFlights} shownEntries={shownEntries} lifting={lifting} scale={1} bare={true}/>
          </div>
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState): StateProps => {
  return {
    meet: state.meet,
    lifting: state.lifting,
    entries: state.registration.entries,
    language: state.language,
    server: state.server,
    clock: state.clock,
  };
};

export default connect(mapStateToProps)(ObsSessionDetailsBare);
