// Defines logic for creating and working with Clock objects.

import { ClockState } from "../types/stateTypes";
import { SessionClockData, SessionClock } from "../types/dataTypes";

export const getClockSessionName = (clocks: ClockState, day: number, platform: number, session: number): string => {
  const d = day.toString()
  const p = platform.toString()
  const s = session.toString()
  const dayIndex = Object.keys(clocks).indexOf(d)
  if (dayIndex === -1) {
    return ''
  } else {
    const dayClock = clocks[d]
    const platformIndex = Object.keys(dayClock).indexOf(p)
    if (platformIndex === -1) {
      return ''
    } else {
      const platformClock = dayClock[p]
      const sessionIndex = Object.keys(platformClock).indexOf(s)
      if (sessionIndex === -1) {
        return ''
      } else {
        return platformClock[s].name
      }
    }
  }
}

// FIXME: duplicated
export const getClockSessionPlacing = (clocks: ClockState, day: number, platform: number, session: number): string => {
  const d = day.toString()
  const p = platform.toString()
  const s = session.toString()
  const dayIndex = Object.keys(clocks).indexOf(d)
  if (dayIndex === -1) {
    return 'SEX_EQUIPMENT_WEIGHTCLASS'
  } else {
    const dayClock = clocks[d]
    const platformIndex = Object.keys(dayClock).indexOf(p)
    if (platformIndex === -1) {
      return 'SEX_EQUIPMENT_WEIGHTCLASS'
    } else {
      const platformClock = dayClock[p]
      const sessionIndex = Object.keys(platformClock).indexOf(s)
      if (sessionIndex === -1 || platformClock[s].placing === undefined) {
        return 'SEX_EQUIPMENT_WEIGHTCLASS'
      } else {
        return platformClock[s].placing
      }
    }
  }
}

export const getClockPlatformClocks = (clocks: ClockState, day: number, platform: number): {[session: string]: SessionClockData} | null => {
  const d = day.toString()
  const p = platform.toString()
  const dayIndex = Object.keys(clocks).indexOf(d)
  if (dayIndex === -1) {
    return null
  } else {
    const dayClock = clocks[d]
    const platformIndex = Object.keys(dayClock).indexOf(p)
    if (platformIndex === -1) {
      return null
    } else {
      return dayClock[p]
    }
  }
}

/* to fix for platforms
export const getClockSessionClock = (clocks: ClockState, day: number, session: number): SessionClock | null => {
  const d = day.toString()
  const s = session.toString()
  const dayIndex = Object.keys(clocks).indexOf(d)
  if (dayIndex === -1) {
    return null
  } else {
    const dayClock = clocks[d]
    const sessionIndex = Object.keys(dayClock).indexOf(s)
    if (sessionIndex === -1) {
      return null
    } else {
      return dayClock[s].sessionClock
    }
  }
}
*/
