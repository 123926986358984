// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { TimeDataAction, OverwriteStoreAction } from "../types/actionTypes";
import { TimeDataState } from "../types/stateTypes";
import { TimeData, LiftTime } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

const initialState: TimeDataState = {
  sessionData: {},
  liftData: {},
};

type Action = TimeDataAction | OverwriteStoreAction;

export default (state: TimeDataState = initialState, action: Action): TimeDataState => {
  
  switch (action.type) {

    case "UPDATE_TIMEDATA": 
    case "UPDATE_TIMEDATA_TIME": 
    case "UPDATE_TIMEDATA_OFFSET": 
    {
      const session = action.session.toString();
      const tag = action.tag;

      let stateData = state.sessionData
      let sessionData
      if (stateData === undefined) {
        sessionData = {}
      } else {
        const sessionDataIndex = Object.keys(stateData).indexOf(session)
        if (sessionDataIndex === -1) {
          sessionData = {}
        } else {
          sessionData = {...stateData[session]}
        }
      }

      let timeData: TimeData
      const tagIndex = Object.keys(sessionData).indexOf(tag)
      if (tagIndex === -1) {
        timeData = {
            startTime: "",
            endTime: "",
            startOffset: -1,
            endOffset: -1,
        }
      } else {
        timeData = {...sessionData[tag]}
      }

      if (action.type === "UPDATE_TIMEDATA") {
        timeData.startTime = action.startTime
        timeData.endTime = action.endTime
        timeData.startOffset = action.startOffset
        timeData.endOffset = action.endOffset
      } else if (action.type === "UPDATE_TIMEDATA_TIME") {
        timeData.startTime = action.startTime
        timeData.endTime = action.endTime
      } else if (action.type === "UPDATE_TIMEDATA_OFFSET") {
        timeData.startOffset = action.startOffset
        timeData.endOffset = action.endOffset
      }

      sessionData[tag] = {...timeData}

      return {
        ...state,
        sessionData: {
          ...stateData,
          [session]: sessionData,
        }
      };
 
    }

    case "UPDATE_TIMEDATA_LIFTS": 
    {
      const session = action.session.toString();
      let stateData = state.liftData
      const liftData = {...action.liftData}
      return {
        ...state,
        liftData: {
          ...stateData,
          [session]: liftData,
        }
      };

    }
    
    case "OVERWRITE_STORE": {
      // TODO: currently code to add a timeData to the store if one doesn't exist
      if (action.store.timeData) {
        return action.store.timeData;
      } else {
        return {...initialState};
      }
    }

    default:
      checkExhausted(action);
      return state;
  }
};
