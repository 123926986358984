// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { useDispatch } from 'react-redux'

import { liftToAttemptFieldName, liftToStatusFieldName } from "../logic/entry";
import { MarkLiftAction, UpdateLiftAction, OverwriteStoreAction } from "../types/actionTypes";
import { Lift, FieldKg, EntryLights, EntryLight } from "../types/dataTypes";
import { EntryLightsState } from "../types/stateTypes";
import { checkExhausted } from "../types/utils";

const initialState: EntryLightsState = {
  // This array owns all entry light information.
  entryLights: [],
};

const newDefaultEntryLights = (entryId: number): EntryLights => {
  return {
    entryId: entryId,
    squatStatus: [null, null, null, null, null],
    benchStatus: [null, null, null, null, null],
    deadliftStatus: [null, null, null, null, null],
  }
};

type Action = MarkLiftAction | UpdateLiftAction | OverwriteStoreAction;

export default (state: EntryLightsState = initialState, action: Action): EntryLightsState => {
  switch (action.type) {

    case "MARK_LIFT": 
    case "UPDATE_LIFT":
    {
      const entryId = action.entryId;
      const lift: Lift = action.lift;
      const attemptOneIndexed = Number(action.attemptOneIndexed);
      const success = Boolean(action.success);

      // Map true to '1' and false to '-1'.
      const status = success === true ? 1 : -1;

      let entryLight: EntryLight;
      if (action.type === "MARK_LIFT") {
        // this is from ORL - we don't have the individual light colours, so assume them
        // (and set the status so we know we have done that)
        const good = success;
        const fail = ! success;
        entryLight = {
          leftGoodlift: good,
          leftRed: fail,
          leftBlue: false,
          leftYellow: false,
          chiefGoodlift: good,
          chiefRed: fail,
          chiefBlue: false,
          chiefYellow: false,
          rightGoodlift: good,
          rightRed: fail,
          rightBlue :false,
          rightYellow: false,
          status: status,
        }
      } else { 
        // this is from DRL
        entryLight = {
          leftGoodlift: action.leftGoodlift,
          leftRed: action.leftRed,
          leftBlue: action.leftBlue,
          leftYellow: action.leftYellow,
          chiefGoodlift: action.chiefGoodlift,
          chiefRed: action.chiefRed,
          chiefBlue: action.chiefBlue,
          chiefYellow: action.chiefYellow,
          rightGoodlift: action.rightGoodlift,
          rightRed: action.rightRed,
          rightBlue :action.rightBlue,
          rightYellow: action.rightYellow,
          status: 0,
        }
      }
      
      const fieldStatus = liftToStatusFieldName(lift);

      // Clone the entryLights array, since one slot will reference a new object.
      const newEntryLights: Array<EntryLights> = state.entryLights.slice();
      const index = newEntryLights.findIndex((obj) => obj.entryId === entryId);
      let oldEntry;
      if (index === -1) {
        oldEntry = newDefaultEntryLights(entryId);
      } else {
        oldEntry = newEntryLights[index];
      }

      // Make a copy of the status array containing the new status.
      const newarray = oldEntry[fieldStatus].slice();
      newarray[attemptOneIndexed - 1] = entryLight;

      // Put that new array into an object so we can use Object.assign().
      const newfields: Partial<EntryLights> = {};
      newfields[fieldStatus] = newarray;

      // Make a new entry from the old entry, with the status field overwritten.
      const newEntry = Object.assign({}, oldEntry);
      if (index === -1) {
        newEntryLights.push(Object.assign(newEntry, newfields));
      } else {
        newEntryLights[index] = Object.assign(newEntry, newfields);
      }

      return {
        ...state,
        entryLights: newEntryLights,
      };
    }

    case "OVERWRITE_STORE":
      // TODO: currently code to add a entryLights to the store if one doesn't exist
      if (action.store.entryLights) {
        return action.store.entryLights;
      } else {
        return {
          entryLights: [],
        }
      }

    default:
      checkExhausted(action);
      return state;
  }
};
