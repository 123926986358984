// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { liftToAttemptFieldName, liftToStatusFieldName, MAX_ATTEMPTS } from "./entry";

import { LiftingOrder, Entry, EntryAttnl, FieldKg, FieldStatus, Flight, Lift, Event } from "../types/dataTypes";
import { LiftingState } from "../types/stateTypes";

import { checkExhausted } from "../types/utils";

export const getLiftName = (lift: Lift) => {
  switch (lift) {
    case "S":
      return "Squat";
    case "B":
      return "Bench";
      break;
    case "D":
      return "Deadlift";
      break;
    default:
      return "";
  }
}

// Helper function: for a given entry, see what attempt number would be next.
//
// Returns a number >= 1 if the entry is still lifting, representing the next attempt.
// Returns zero if the entry does not have any pending attempts.
export const getNextAttemptNumberForEntry = (entry: Entry, fieldKg: FieldKg, fieldStatus: FieldStatus): number => {
  const weightsKg = entry[fieldKg];
  const statuses = entry[fieldStatus];

  // Lifters only set the next attempt, so loop backwards,
  // looking for the first attempt that meets the criteria.
  for (let i = MAX_ATTEMPTS - 1; i >= 0; i--) {
    if (weightsKg[i] !== 0 && statuses[i] === 0) {
      return i + 1;
    }
  }
  return 0;
};

// Helper function: for a given entry, see the maximum attempt number made.
//
// Returns a number >= 1 representing the highest-numbered attempt attempted.
// Returns zero if the entry has not attempted any attempts.
const getMaxAttemptNumberForEntry = (entry: Entry, fieldKg: FieldKg, fieldStatus: FieldStatus): number => {
  const weightsKg = entry[fieldKg];
  const statuses = entry[fieldStatus];

  for (let i = MAX_ATTEMPTS - 1; i >= 0; i--) {
    if (weightsKg[i] !== 0 && statuses[i] !== 0) {
      return i + 1;
    }
  }
  return 0;
};

// Determine the current active attempt for the current lift.
//
// An attempt is active if either:
// 1. It has been overridden by the Attempt selector.
// 2. There exists an attempt of that number with no success/failure value,
//    and there is no lower attempt number with that property.
//
// Returns a number in the (inclusive) range of [1, MAX_ATTEMPTS].
// If there is not enough data to make a decision, returns 1.
export const getActiveAttemptNumber = (entriesInFlight: ReadonlyArray<Readonly<Entry>>, lifting: LiftingState): number => {
  const lift = lifting.lift;
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);

  // Allow manual override.
  if (lifting.overrideAttempt !== null) {
    return Number(lifting.overrideAttempt);
  }

  // Iterate in reverse, looking for the earliest attempt that hasn't been lifted.
  let earliestAttemptOneIndexed = MAX_ATTEMPTS + 1;
  for (let i = 0; i < entriesInFlight.length; i++) {
    const entry = entriesInFlight[i];
    const next = getNextAttemptNumberForEntry(entry, fieldKg, fieldStatus);
    // Zero return value means "no pending attempts for this entry."
    if (next > 0 && next < earliestAttemptOneIndexed) {
      earliestAttemptOneIndexed = next;
    }
  }

  // The lowest pending attempt number is the current one.
  if (earliestAttemptOneIndexed < MAX_ATTEMPTS + 1) {
    return earliestAttemptOneIndexed;
  }

  // In the case of no pending lifts, try to helpfully infer the next attempt.
  let latestAttemptOneIndexed = 0;
  for (let i = 0; i < entriesInFlight.length; i++) {
    const entry = entriesInFlight[i];
    const max = getMaxAttemptNumberForEntry(entry, fieldKg, fieldStatus);
    // Zero return value means "no attempted attempts for this entry."
    if (max > latestAttemptOneIndexed) {
      latestAttemptOneIndexed = max;
    }
  }

  // If >0, we know there are no pending attempts, and we know that everyone
  // has taken all of the Nth attempt. So we should display the (N+1)th attempt.
  if (latestAttemptOneIndexed > 0) {
    // Don't auto-advance from 3rd to 4th attempts.
    // However, if we're already on 4th attempts, stay there.
    if (latestAttemptOneIndexed + 1 >= 4) {
      return latestAttemptOneIndexed;
    }

    // Roll-over to the next attempt (with no pending entries).
    return latestAttemptOneIndexed + 1;
  }

  // Otherwise, just default to the first attempt.
  return 1;
};

// Helper function for recursive comparison.
export const compareEntriesByAttempt = (a: Entry, b: Entry, fieldKg: FieldKg, attemptOneIndexed: number): number => {
  const aKg = a[fieldKg][attemptOneIndexed - 1];
  const bKg = b[fieldKg][attemptOneIndexed - 1];

  // If non-equal, sort by weight, ascending.
  if (aKg !== bKg) return aKg - bKg;

  // If the federation uses lot numbers, break ties using lot.
  if (a.lot !== 0 && b.lot !== 0) return a.lot - b.lot;

  // If this is not the first attempt, preserve the order from the last attempt.
  if (attemptOneIndexed > 1) {
    return compareEntriesByAttempt(a, b, fieldKg, attemptOneIndexed - 1);
  }

  // Try to break ties using bodyweight, with the lighter lifter going first.
  if (a.bodyweightKg !== b.bodyweightKg) return a.bodyweightKg - b.bodyweightKg;

  // If we've run out of properties by which to compare them, resort to Name.
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

// Helper function: performs an in-place sort on an array of entries.
// Assumes that zero entries are not mixed in with non-zero entries.
export const orderEntriesByAttempt = (
  entries: Array<Entry>,
  fieldKg: FieldKg,
  attemptOneIndexed: number
): Array<Entry> => {
  return entries.sort((a, b) => {
    return compareEntriesByAttempt(a, b, fieldKg, attemptOneIndexed);
  });
};

// Returns a copy of the entries in lifting order for the current attempt.
export const orderEntriesForAttempt = (
  entriesInFlight: ReadonlyArray<Readonly<Entry>>,
  lifting: LiftingState,
  attemptOneIndexed: number
): Array<Entry> => {
  const lift = lifting.lift;
  const fieldKg = liftToAttemptFieldName(lift);

  const attemptZeroIndexed = attemptOneIndexed - 1;
  const existsNextAttempt = attemptOneIndexed + 1 <= MAX_ATTEMPTS;
  const existsPrevAttempt = attemptOneIndexed > 1;

  // Divide the entries into disjoint groups:
  const byNextAttempt: Array<Entry> = []; // Entries sorted by their next attempt.
  const byThisAttempt: Array<Entry> = []; // Entries sorted by this attempt.
  const byPrevAttempt: Array<Entry> = []; // Entries sorted by previous attempt.
  const notLifting: Array<Entry> = []; // Entries that don't have this or next attempts in.

  for (let i = 0; i < entriesInFlight.length; i++) {
    const entry = entriesInFlight[i];

    if (existsNextAttempt && entry[fieldKg][attemptZeroIndexed + 1] !== 0) {
      byNextAttempt.push(entry);
    } else if (entry[fieldKg][attemptZeroIndexed] !== 0) {
      byThisAttempt.push(entry);
    } else if (existsPrevAttempt && entry[fieldKg][attemptZeroIndexed - 1] !== 0) {
      byPrevAttempt.push(entry);
    } else {
      notLifting.push(entry);
    }
  }

  // Perform sorting on the relative groups.
  if (existsNextAttempt) {
    orderEntriesByAttempt(byNextAttempt, fieldKg, attemptOneIndexed + 1);
  }
  orderEntriesByAttempt(byThisAttempt, fieldKg, attemptOneIndexed);
  if (existsPrevAttempt) {
    orderEntriesByAttempt(byPrevAttempt, fieldKg, attemptOneIndexed - 1);
  }
  orderEntriesByAttempt(notLifting, fieldKg, attemptOneIndexed);

  // Arrange these three groups consecutively.
  return Array.prototype.concat(byNextAttempt, byThisAttempt, byPrevAttempt, notLifting);
};

// Returns either the current entry ID or null if no active entry.
//
// In the ordered entries, the earliest lifter that hasn't gone yet is going.
// This can be manually overridden by UI controls.
const getCurrentEntryId = (
  lifting: LiftingState,
  orderedEntries: Array<Entry>,
  attemptOneIndexed: number
): number | null => {
  const lift = lifting.lift;
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);

  if (lifting.overrideEntryId !== null) {
    return Number(lifting.overrideEntryId);
  }

  for (let i = 0; i < orderedEntries.length; i++) {
    const entry = orderedEntries[i];
    const idx = attemptOneIndexed - 1;
    if (entry[fieldKg][idx] !== 0 && entry[fieldStatus][idx] === 0) {
      return entry.id;
    }
  }
  return null;
};

type NextEntryInfo = {
  entryId: number;
  attemptOneIndexed: number;
};

// Returns either an Object of {entryId, attemptOneIndexed}, or null.
const getNextEntryInfo = (
  lifting: LiftingState,
  currentEntryId: number | null,
  orderedEntries: Array<Entry>,
  attemptOneIndexed: number
): NextEntryInfo | null => {
  const lift = lifting.lift;
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);

  if (currentEntryId === null) {
    return null;
  }

  // Find the index of the currentEntryId in the orderedEntries.
  const currentEntryIndex = orderedEntries.findIndex((e) => e.id === currentEntryId);
  if (currentEntryIndex === -1) {
    return null;
  }

  // Walk forward, looking for additional valid attempts.
  for (let i = currentEntryIndex + 1; i < orderedEntries.length; i++) {
    const hasAttempt = orderedEntries[i][fieldKg][attemptOneIndexed - 1] !== 0;
    const notTaken = orderedEntries[i][fieldStatus][attemptOneIndexed - 1] === 0;

    if (hasAttempt && notTaken) {
      return {
        entryId: orderedEntries[i].id,
        attemptOneIndexed: attemptOneIndexed,
      };
    }
  }

  // If none were found walking forward, check the next attempt by wrapping around.
  if (attemptOneIndexed + 1 > MAX_ATTEMPTS) {
    return null;
  }
  const nextAttemptOneIndexed = attemptOneIndexed + 1;

  for (let i = 0; i < currentEntryIndex; i++) {
    const hasAttempt = orderedEntries[i][fieldKg][nextAttemptOneIndexed - 1] !== 0;
    const notTaken = orderedEntries[i][fieldStatus][nextAttemptOneIndexed - 1] === 0;

    if (hasAttempt && notTaken) {
      return {
        entryId: orderedEntries[i].id,
        attemptOneIndexed: nextAttemptOneIndexed,
      };
    }
  }

  return null;
};

// Main application logic. Resolves the LiftingState to a LiftingOrder.
export const getLiftingOrder = (entriesInFlight: Array<Entry>, lifting: LiftingState): LiftingOrder => {
  const attemptOneIndexed = getActiveAttemptNumber(entriesInFlight, lifting);
  const orderedEntries = orderEntriesForAttempt(entriesInFlight, lifting, attemptOneIndexed);
  const currentEntryId = getCurrentEntryId(lifting, orderedEntries, attemptOneIndexed);
  const nextEntryInfo = getNextEntryInfo(lifting, currentEntryId, orderedEntries, attemptOneIndexed);

  return {
    orderedEntries: orderedEntries,
    attemptOneIndexed: attemptOneIndexed,
    currentEntryId: currentEntryId,
    nextAttemptOneIndexed: nextEntryInfo ? nextEntryInfo.attemptOneIndexed : null,
    nextEntryId: nextEntryInfo ? nextEntryInfo.entryId : null,
  };
};

export const getEntriesOnPlatform = (
  day: number,
  session: number,
  platform: number,
  entries: ReadonlyArray<Readonly<Entry>>
): Entry[] => {
  const entriesOnPlatform = entries.filter((entry) => entry.day === day && entry.session === session && entry.platform === platform);
  return entriesOnPlatform;
};

export const getFlightsOnPlatform = (entriesOnPlatform: readonly Entry[]): Flight[] => {
  const flightsOnPlatform: Array<Flight> = [];
  for (let i = 0; i < entriesOnPlatform.length; i++) {
    const entry = entriesOnPlatform[i];
    if (flightsOnPlatform.indexOf(entry.flight) === -1) {
      flightsOnPlatform.push(entry.flight);
    }
  }
  flightsOnPlatform.sort();

  return flightsOnPlatform;
};

export const getEntriesInSession = (
  day: number,
  session: number,
  allEntries: readonly Entry[]
): Entry[] => {
  return allEntries.filter((entry) => entry.day === day && entry.session === session);
};

export const getEntriesInFlight = (
  day: number,
  session: number,
  platform: number,
  flight: Flight,
  allEntries: readonly Entry[]
): Entry[] => {
  return allEntries.filter((entry) => entry.day === day && entry.session === session && entry.platform === platform && entry.flight === flight);
};

export const getEntriesInFlightLift = (
  day: number,
  session: number,
  platform: number,
  flight: Flight,
  lift: Lift,
  allEntries: readonly Entry[]
): Entry[] => {
  return allEntries.filter((entry) => entry.day === day && entry.session === session && entry.platform === platform && entry.flight === flight && entry.events[0].includes(lift));
};

// returns entries that are lifting this lift in this flight
export const getLiftersInFlightLift = (
  day: number,
  session: number,
  platform: number,
  flight: Flight,
  lift: Lift,
  allEntries: readonly Entry[]
): Entry[] => {
  return allEntries.filter((entry) => entry.day === day && entry.session === session && entry.platform === platform && entry.flight === flight && checkLifting(entry, lift));
};

// checks if the entry is registered for this lift
const checkLifting = (entry: Entry, lift: Lift): boolean => {
  const event = entry.events.length > 0 ? entry.events[0] : "SBD";
  return event.includes(lift) ? true : false;
}

// checks if the entry has a weight entered for this lift for their next attempt
const checkLiftingAttempt = (entry: Entry, lift: Lift): boolean => {
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);
  // Zero return value means "no pending attempts for this entry."
  const next = getNextAttemptNumberForEntry(entry, fieldKg, fieldStatus);
  return next === 0 ? false : true;
}

// returns entries that are still to lift this lift in this attempt in this flight
export const getLiftersLeftInFlightLiftAttempt = (
  lift: Lift,
  lifting: LiftingState,
  entriesInFlight: readonly Entry[],
): Entry[] => {
  const attemptOneIndexed = getActiveAttemptNumber(entriesInFlight, lifting);
  const orderedEntries = orderEntriesForAttempt(entriesInFlight, lifting, attemptOneIndexed);
  const currentEntryId = getCurrentEntryId(lifting, orderedEntries, attemptOneIndexed);
  if (currentEntryId === null) return [];
  const liftingEntries = orderedEntries.filter((entry) => (checkLifting(entry, lift) && checkLiftingAttempt(entry, lift)));
  const currentEntryIndex = liftingEntries.findIndex((e) => e.id === currentEntryId);
  return liftingEntries.slice(currentEntryIndex);
};

// returns entries that are still to lift this lift in the next attempt in this flight
export const getLiftersLeftInFlightLiftNextAttempt = (
  lift: Lift,
  lifting: LiftingState,
  entriesInFlight: readonly Entry[],
): Entry[] => {
  const attemptOneIndexed = getActiveAttemptNumber(entriesInFlight, lifting);
  //FIXME: 3!
  if (attemptOneIndexed === 3) return [];
  // FIXME: only lifters who have lifted the current attempt will have a weight set, 
  //        but as long as flights are bigger than the next up count that will be fine
  //        - can just use the current flight info if required
  const orderedEntries = orderEntriesForAttempt(entriesInFlight, lifting, attemptOneIndexed + 1);
  const liftingEntries = orderedEntries.filter((entry) => (checkLifting(entry, lift) && checkLiftingAttempt(entry, lift)));
  return liftingEntries;
};

// returns entries in the next flight for this lift that are still to lift
export const getLiftersInNextFlightLift = (
  day: number,
  session: number,
  platform: number,
  flight: Flight,
  lift: Lift,
  lifting: LiftingState,
  entries: readonly Entry[],
): Entry[] => {
  const liftingEntries = getEntriesInNextFlightLift(day, session, platform, flight, lift, entries);
  const orderedEntries = orderEntriesForAttempt(liftingEntries, lifting, 1);
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);
  return orderedEntries.filter((entry) => ! checkLifted(entry, fieldKg, fieldStatus));
}

// returns entries in the next flight
export const getEntriesInNextFlight = (
  day: number,
  session: number,
  platform: number,
  flight: Flight,
  entries: readonly Entry[],
): Entry[] => {
  const entriesOnPlatform = getEntriesOnPlatform(day, session, platform, entries);
  const flightsOnPlatform = getFlightsOnPlatform(entriesOnPlatform);
  const nextFlight = getNextFlight(flight, flightsOnPlatform);
  if (nextFlight === flight) {
    return []
  }
  const flightEntries = entriesOnPlatform.filter((entry) => entry.flight === nextFlight);
  return flightEntries;
}

export const getEntriesInNextFlightLift = (
  day: number,
  session: number,
  platform: number,
  flight: Flight,
  lift: Lift,
  entries: readonly Entry[],
): Entry[] => {
  const flightEntries = getEntriesInNextFlight(day, session, platform, flight, entries);
  const liftingEntries = flightEntries.filter((entry) => checkLifting(entry, lift));
  return liftingEntries;
}

// returns next flight (which can wrap around)
export const getNextFlight = (
  currentFlight: Flight,
  flights: Array<Flight>,
): Flight => {
  const currentFlightIndex = flights.indexOf(currentFlight);
  return (currentFlightIndex+1 === flights.length) ? flights[0] : flights[currentFlightIndex+1];
}

// checks whether an entry has any remaining attempts at a lift
const checkLifted = (entry: Entry, fieldKg: FieldKg, fieldStatus: FieldStatus): boolean => {
  const next = getNextAttemptNumberForEntry(entry, fieldKg, fieldStatus);
//  console.log(next);
  return next > 0 ? false : true;
}

// FIXME: we use 3 rather than MAX_ATTEMPTS below

// Returns a number >= 1 if the entry is still lifting, representing the next attempt.
// - note that this version checks weights for the current attempt
// Returns zero if the entry does not have any pending attempts.
const getNextAttemptNumberForEntryAttempt = (entry: Entry, fieldKg: FieldKg, fieldStatus: FieldStatus, attemptOneIndexed: number): number => {
  const weightsKg = entry[fieldKg];
  const statuses = entry[fieldStatus];
//  console.log(statuses);
  // looking for the last attempt attempted
  for (let i = 0; i < 3; i++) {
    if (weightsKg[i] !== 0 && statuses[i] === 0) {
      return i + 1;
    }
    if (weightsKg[i] === 0 && statuses[i] === 0 && attemptOneIndexed === (i+1)) {
      return i;
    }
    if (weightsKg[i] === 0 && statuses[i] === 0 && attemptOneIndexed < (i+1)) {
      return i + 1;
    }
  }
  return 0;
};

// gets number of lifts left for this entry
const getRemainingAttemptsForEntry = (entry: Entry, fieldKg: FieldKg, fieldStatus: FieldStatus, attemptOneIndexed: number): number => {
  const nextAttemptNumberForEntry = getNextAttemptNumberForEntryAttempt(entry, fieldKg, fieldStatus, attemptOneIndexed);
//  console.log(nextAttemptNumberForEntry);
  if (nextAttemptNumberForEntry === 0) {
    return 0;
  }
  // ignore any outstanding attempts if the lifter hasn't set a weight for this attempt
  // (this will only happen when the attempt value increments)
  if (nextAttemptNumberForEntry < attemptOneIndexed) {
    return 0;
  }
  return 3 - (nextAttemptNumberForEntry - 1);
}

export const getLiftsLeftInCurrentFlightLift = (lift: Lift, entriesInFlight: ReadonlyArray<Readonly<Entry>>, lifting: LiftingState): number => {
  // note that this logic only works for the current flight/lift
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);
  let totalAttemptsLeft = 0;
  const attemptOneIndexed = getActiveAttemptNumber(entriesInFlight, lifting);
//  console.log(attemptOneIndexed);
  for (let i = 0; i < entriesInFlight.length; i++) {
    const entry = entriesInFlight[i];
    const attemptsLeft = getRemainingAttemptsForEntry(entry, fieldKg, fieldStatus, attemptOneIndexed);
//    console.log(attemptsLeft);
    totalAttemptsLeft += attemptsLeft;
  }
  return totalAttemptsLeft;
}

export const checkLiftsLeftInFlightLift = (lift: Lift, entriesInFlight: ReadonlyArray<Readonly<Entry>>): boolean => {
  // checks whether there are lifts left in this flight/lift
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);
  let hasAttemptsLeft = false;
  for (let i = 0; i < entriesInFlight.length; i++) {
    const entry = entriesInFlight[i];
    const hasLifted = checkLifted(entry, fieldKg, fieldStatus);
    if (hasLifted === false) {
      hasAttemptsLeft = true;
      return hasAttemptsLeft;
    }
  }
  return hasAttemptsLeft;
}

export const checkFlightLiftStarted = (lift: Lift, entriesInFlight: ReadonlyArray<Readonly<Entry>>): boolean => {
  // checks whether any attempts have been made in this flight/lift
  // note - it allows for empty attempt values, e.g. after weighin
  const fieldStatus = liftToStatusFieldName(lift);
  for (let i = 0; i < entriesInFlight.length; i++) {
    const entry = entriesInFlight[i];
    const statuses = entry[fieldStatus];
    for (let i = 0; i < MAX_ATTEMPTS; i++) {
      if (statuses[i] !== 0) {
        return true;
      }
    }
  }
  return false;
}

export const getLastFlightAttempt = (lift: Lift, entriesInFlight: ReadonlyArray<Readonly<Entry>>): Entry | undefined => {
  // get the entry for the latest attempt made in a flight
  // if there are no attempts, returns undefined
  const fieldStatus = liftToStatusFieldName(lift);
  for (let i = 2; i >= 0; i--) {
    for (let j = entriesInFlight.length - 1; j >= 0; j--) {
      const entry = entriesInFlight[j];
      const statuses = entry[fieldStatus];
      if (statuses[i] !== 0) {
        return entry
      }
    }
  }
  return undefined
}

export const checkFlightStarted = (entriesInFlight: ReadonlyArray<Readonly<Entry>>): boolean => {
  const lifts = "SBD";
  for (const platformLift of lifts) {
    const lift: Lift = platformLift as Lift;
    const flightLiftStarted = checkFlightLiftStarted(lift, entriesInFlight)
    if (flightLiftStarted) {
      return true
    }
  }
  return false;
}

export const checkLiftsLeftInLift = (lift: Lift, entry: Entry): boolean => {
  // checks whether there are lifts left in this lift for this entry
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);
  let hasAttemptsLeft = false;
  const hasLifted = checkLifted(entry, fieldKg, fieldStatus);
  if (hasLifted === false) {
    hasAttemptsLeft = true;
    return hasAttemptsLeft;
  }
  return hasAttemptsLeft;
}

// checks if a flight is complete
export const checkFlightComplete = (flight: Flight, entries: readonly Entry[]): boolean => {
  let flightComplete = true;
  entries.filter(entry => entry.flight === flight).every((entry) => {
    if (checkLifterComplete(entry) === false) {
      flightComplete = false;
      return false;
    }
    return true;
  });
  return flightComplete;
}

// checks if a lifter has completed all their attempts
const checkLifterComplete = (entry: Entry): boolean => {
  const events = entry.events.length > 0 ? entry.events[0] : "SBD";
  for (const lift of events) {
    const fieldKg = liftToAttemptFieldName(lift as Lift);
    const fieldStatus = liftToStatusFieldName(lift as Lift);
    // Zero return value means "no pending attempts for this entry."
    const next = getNextAttemptNumberForEntry(entry, fieldKg, fieldStatus);
    if (next > 0) return false;
  }
  return true;
}

// checks if a lifter has completed all their attempts for a lift
const checkLifterLiftComplete = (entry: Entry, lift: Lift): boolean => {
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);
  // Zero return value means "no pending attempts for this entry."
  const next = getNextAttemptNumberForEntry(entry, fieldKg, fieldStatus);
  if (next > 0) return false;
  return true;
}

// note: this returns >0 only if there have been attempts
export const checkMaxAttemptNumberForEntry = (entry: Entry, lift: Lift): number => {
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);
  const weightsKg = entry[fieldKg];
  const statuses = entry[fieldStatus];
  for (let i = MAX_ATTEMPTS - 1; i >= 0; i--) {
    if (weightsKg[i] !== 0 && statuses[i] !== 0) {
      return i + 1;
    }
  }
  return 0;
};

export const getFlightLift = (entriesInFlight: ReadonlyArray<Readonly<Entry>>): Lift | null => {
  const events = "SBD";
  for (const lift of events) {
    const liftsLeftInFlightLift = checkLiftsLeftInFlightLift(lift as Lift, entriesInFlight)
    if (liftsLeftInFlightLift) {
      return lift as Lift
    }
  }
  return null;
}

// FIXME: fix these for bench only

export const getLifterLift = (entry: Entry): Lift => {
  const events = "SBD";
  for (const lift of events) {
    const liftsLeftInLift = checkLiftsLeftInLift(lift as Lift, entry)
    if (liftsLeftInLift) {
      return lift as Lift
    }
  }
  return 'D' as Lift;
}

export const getTimersInCurrentFlightLift = (timerValue: number, lift: Lift, entriesInFlight: Array<Entry>, lifting: LiftingState): EntryAttnl[] => {
  // note that this logic only works for the current flight/lift
  const attnls: EntryAttnl[] = [];
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);
  const attemptOneIndexed = getActiveAttemptNumber(entriesInFlight, lifting);
//  console.log(attemptOneIndexed);
  // for anyone still to attempt this attempt, set their timer offset
  let timer = timerValue;
  for (let i = 0; i < entriesInFlight.length; i++) {
    const entry = entriesInFlight[i];
    const attemptsLeft = getRemainingAttemptsForEntry(entry, fieldKg, fieldStatus, attemptOneIndexed);
    const attemptNumber = 3 - attemptsLeft + 1;
//    console.log(attemptsLeft, attemptNumber);
    if (attemptNumber === attemptOneIndexed) {
      attnls.push({id: entry.id, attnl: timer});
      timer += 1;
    }
  }
  // for anyone else (they will be on the next attempt, or the next lift), set their timer offset
  for (let i = 0; i < entriesInFlight.length; i++) {
    const entry = entriesInFlight[i];
    const attemptsLeft = getRemainingAttemptsForEntry(entry, fieldKg, fieldStatus, attemptOneIndexed);
    const attemptNumber = 3 - attemptsLeft + 1;
//    console.log(attemptsLeft, attemptNumber);
    if (attemptNumber < 4 && attemptNumber === attemptOneIndexed + 1) {
      attnls.push({id: entry.id, attnl: timer});
      timer += 1;
    }
  }
  return attnls;
}

export const getTimersInFlightLift = (currentAttnls: EntryAttnl[], timerValue: number, lift: Lift, entriesInFlight: Array<Entry>, lifting: LiftingState): EntryAttnl[] => {
  let timer = timerValue;
  for (let i = 0; i < entriesInFlight.length; i++) {
    const entry = entriesInFlight[i];
    const entryId = entry.id;
    const entryAttnlIndex = currentAttnls.findIndex((e) => e.id === entryId);
    const lifterTimerValue = timer;
    const potentialEntryAttnl = {id: entry.id, attnl: Math.floor(lifterTimerValue / 60)};
    if (entryAttnlIndex === -1) {
      currentAttnls.push(potentialEntryAttnl);
    } else {
      const entryAttnl = currentAttnls[entryAttnlIndex];
      // we only want the next attnl, so ignore any higher values
      if (lifterTimerValue < entryAttnl.attnl) {
        currentAttnls[entryAttnlIndex] = potentialEntryAttnl;
      }
    }
    timer += 60;
  }
  return currentAttnls;
}

export const getNextAttemptNumberForEntryLift = (entry: Entry, lift: Lift): number => {
  const fieldKg = liftToAttemptFieldName(lift);
  const fieldStatus = liftToStatusFieldName(lift);
  const next = getNextAttemptNumberForEntry(entry, fieldKg, fieldStatus);
  // Zero return value means "no pending attempts for this entry."
  return next
}
