// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { 
  newTimer,
  newDefaultOffset,
} from "../logic/timer";
import { TimerAction, UpdateBarLoadedAction, OverwriteStoreAction } from "../types/actionTypes";
import { TimerState } from "../types/stateTypes";
import { Timer, EntryAttnl } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

const offset = newDefaultOffset();

const initialState: TimerState = {
  // This object owns all timer information.
  offset: offset,
};

type Action = TimerAction | UpdateBarLoadedAction | OverwriteStoreAction;

export default (state: TimerState = initialState, action: Action): TimerState => {
  switch (action.type) {

    case "UPDATE_TIMER": {
      return {
        ...state,
        offset: action.offset,
      };
    }

    case "UPDATE_BARLOADED": {
      // assume next lift will start after 1 minute
      const offset = 1;
      return {
        ...state,
        offset: offset,
      };
    }

    case "OVERWRITE_STORE": {
      if (action.store.timer) {
        return action.store.timer;
      } else {
        const timer = newTimer(offset);
        return timer;
      }
    }

    default:
//      checkExhausted(action);
      return state;
  }
};
