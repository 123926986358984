// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Lifting state only tracks manual overrides.
//
// Outside of overrides, the state of the meet is fully-calculated by the LiftingView.
//
// For safety, correctness, and ease of understanding, the state of the meet is
// intentionally *not* stored in the global state. It is continuously recalculated.
//
// Please do not attempt to store meet state in the Redux store!

import {
  SetLiftingPlatformAction,
  ClearCacheAction,
  OverwriteStoreAction,
} from "../types/actionTypes";
import { ServerState } from "../types/stateTypes";
import { checkExhausted } from "../types/utils";

type Action = SetLiftingPlatformAction | ClearCacheAction | OverwriteStoreAction;

const initialState: ServerState = {
  platform: 1,
  connected: false,
  cache: false,
};

export default (state: ServerState = initialState, action: Action): ServerState => {
  switch (action.type) {

    case "SET_LIFTING_PLATFORM":
      return { ...state, platform: action.platform, connected: action.connected };

    case "CLEAR_CACHE":
      const cache = !state.cache
      return { ...state, cache: cache };

    case "OVERWRITE_STORE":
      if (action.store.server === undefined) return initialState;
      return action.store.server;

    default:
      checkExhausted(action);
      return state;
  }
};
