// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// The main component of the Lifting page, contained by the LiftingView.

import React, { CSSProperties } from "react";
import { connect } from "react-redux";

import LocalizedString from "../translations/LocalizedString";

import { getWeightClassStr, getWeightClassLbsStr } from "../../reducers/meetReducer";
import { getPoints } from "../../logic/coefficients/coefficients";
import {
  getProjectedTotalKg,
  getFinalTotalKg,
  liftToAttemptFieldName,
  liftToStatusFieldName,
  mapSexToClasses,
  getPrognosisTotalKg,
} from "../../logic/entry";

import { getProjectedResults, getFinalResults, getPrognosisResults } from "../../logic/divisionPlace";
import { kg2lbs, displayWeight, displayPoints, displayPlaceOrdinal, displayWeightOnePlace } from "../../logic/units";

import { CategoryResults } from "../../logic/divisionPlace";
import { Entry, Equipment, Language, Lift, Flight } from "../../types/dataTypes";
import { GlobalState, MeetState, LiftingState, RegistrationState, AttnlState, ServerState, ClockState } from "../../types/stateTypes";

import styles from "./LiftingTableBare.module.scss";
import { checkExhausted } from "../../types/utils";
import { getString, localizeEquipment } from "../../logic/strings";
import { getClockSessionPlacing } from "../../logic/clock";
import { getFlightRanktypes, getPointsResults, getRankTypeName, PointsResults, RankTypePointsResults } from "../../logic/pointsPlace";
import DataTable, { BACKGROUNDCOLOR, getTitleWidth, TableEntry } from "../overlays/DataTable";
import { getTableData } from "../../logic/config";

interface OwnProps {
  top: number;
  flight: Flight;
  orderedEntries: Array<Entry>;
  currentEntryId: number | null;
  platform: number;
  left?: number;
}

interface StateProps {
  meet: MeetState;
  registration: RegistrationState;
  lifting: ReadonlyArray<LiftingState>;
  language: Language;
  attnl: AttnlState;
  server: ServerState;
  clock: ClockState;  
}

type Props = OwnProps & StateProps;

class LiftingTableBare extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  getPointsStr = (points: number): string => {
    if (points === 0) return ''
    const p = displayPoints(points, this.props.language);
    return p;
  };

  getWeightStr = (weightKg: number): string => {
    if (weightKg === 0) return ''
    const weight = displayWeightOnePlace(weightKg, this.props.language);
    return weight;
  };

  getTableRow = (entry: Entry, nameBackground: BACKGROUNDCOLOR, categoryResults: Array<CategoryResults>, prognosisResults: CategoryResults[], rankTypePointsResults: RankTypePointsResults[]): TableEntry[] => {

    const row: TableEntry[] = []
    row.push({data: entry.name, backgroundColor: nameBackground})

    row.push({data: this.getPointsStr(entry.bodyweightKg), backgroundColor: BACKGROUNDCOLOR.DEFAULT})

    const bw = entry.bodyweightKg;
    const classesForSex = mapSexToClasses(
      entry.sex,
      this.props.meet.weightClassesKgMen,
      this.props.meet.weightClassesKgWomen,
      this.props.meet.weightClassesKgMx
    );
    const weightClass = this.props.meet.inKg
      ? getWeightClassStr(classesForSex, bw, this.props.language)
      : getWeightClassLbsStr(classesForSex, bw);
    row.push({data: weightClass, backgroundColor: BACKGROUNDCOLOR.DEFAULT})

    row.push({data: entry.lot.toString(), backgroundColor: BACKGROUNDCOLOR.DEFAULT})

    for (const lift of ['S', 'B', 'D']) {
      let fieldKg = liftToAttemptFieldName(lift as Lift);
      let fieldStatus = liftToStatusFieldName(lift as Lift);
      for (let attempt = 0; attempt < 3; attempt++) {
        const kg = entry[fieldKg][attempt];
        const entryWeight = this.getWeightStr(kg);
        const entryStatus = entry[fieldStatus][attempt];
        let background = BACKGROUNDCOLOR.ATTEMPT
        if (entryStatus === 1) background = BACKGROUNDCOLOR.GOODLIFT
        if (entryStatus === -1) background = BACKGROUNDCOLOR.NOLIFT
        row.push({data: entryWeight, backgroundColor: background})
      }
    }
    
    const totalKg = getProjectedTotalKg(entry);
    const asNumber = this.props.meet.inKg ? totalKg : kg2lbs(totalKg);
    const total = totalKg === 0 ? '' : this.getWeightStr(asNumber)
    row.push({data: total, backgroundColor: BACKGROUNDCOLOR.DEFAULT})

    const event = entry.events.length > 0 ? entry.events[0] : "SBD";
    const points: number = getPoints(this.props.meet.formula, entry, event, totalKg, this.props.meet.inKg);
    row.push({data: this.getPointsStr(points), backgroundColor: BACKGROUNDCOLOR.DEFAULT})

    // place
    let place = ''
    const rankType = getRankTypeName(entry.state)
    if (rankType === 'SEX_EQUIPMENT_WEIGHTCLASS') {

      if (getFinalTotalKg(entry) === 0) {
        // If the lifter has no total, then don't report a place.
        // do nothing
      } else if (entry.guest) {
        // If the lifter is a guest, they cannot place, so just display the guest symbol.
      place = getString("results.lifter-guest", this.props.language)
      } else if (entry.divisions.length === 0) {
        // do nothing
      } else {
        // Just show the Place from the first division in the list.
        // This is the first division shown in the "Division" column.
        const firstDiv = entry.divisions[0];
        // Look at all the categories, and find the first one including this division
        // and entry. Because the categories are in sorted order, SBD takes priority
        // over B by default.
        for (let i = 0; i < categoryResults.length; i++) {
          const result = categoryResults[i];
          if (result.category.division !== firstDiv || result.category.event !== 'SBD') {
            continue;
          }
          const catEntries = result.orderedEntries;
          for (let j = 0; j < catEntries.length; j++) {
            const catEntry = catEntries[j];
            if (catEntry.id === entry.id) {
              // We can use the index into the array as their place, since it sorted and guests will be last in the array
              const ordinal = displayPlaceOrdinal(j + 1, entry, this.props.language);
              place = ordinal
              break;
            }
          }
          if (place !== '') break
        }
      }
      row.push({data: place, backgroundColor: BACKGROUNDCOLOR.DEFAULT})
    } else if (rankType === 'SEX_EQUIPMENT_POINTS') {
      const rtPointsResults = rankTypePointsResults.find(rt => rt.rankType === rankType)
      let pointsResults = undefined
      if (rtPointsResults !== undefined) pointsResults = rtPointsResults.pointsResults
      let place = 0
      let points = 0
      const esPointsResults = pointsResults !== undefined ? pointsResults.esPointsResults.find(e => e.equipmentSexEvent.equipment === entry.equipment && e.equipmentSexEvent.sex === entry.sex && e.equipmentSexEvent.event === entry.events[0]) : undefined
      if (esPointsResults !== undefined) {
        const index = esPointsResults.orderedFinalPointsEntries.findIndex(e => e.entryId === entry.id)
        if (index !== -1) {
          points = esPointsResults.orderedFinalPointsEntries[index].finalPoints
          place = index + 1
        }
      }
      let placeStr = ''
      if (points === 0 || place === 0) {
        // do nothing
      } else if (entry.guest) {
        placeStr = getString("results.lifter-guest", this.props.language)
      } else {
        const ordinal = displayPlaceOrdinal(place, entry, this.props.language);
        placeStr = ordinal
      }
      row.push({data: placeStr, backgroundColor: BACKGROUNDCOLOR.DEFAULT})
    } else if (rankType === 'EQUIPMENT_POINTS') {
      const rtPointsResults = rankTypePointsResults.find(rt => rt.rankType === rankType)
      let pointsResults = undefined
      if (rtPointsResults !== undefined) pointsResults = rtPointsResults.pointsResults
      let place = 0
      let points = 0
      const ePointsResults = pointsResults !== undefined ? pointsResults.ePointsResults.find(e => e.equipmentEvent.equipment === entry.equipment && e.equipmentEvent.event === entry.events[0]) : undefined
      if (ePointsResults !== undefined) {
        const index = ePointsResults.orderedFinalPointsEntries.findIndex(e => e.entryId === entry.id)
        if (index !== -1) {
          points = ePointsResults.orderedFinalPointsEntries[index].finalPoints
          place = index + 1
        }
      }
      let placeStr = ''
      if (points === 0 || place === 0) {
        // do nothing
      } else if (entry.guest) {
        placeStr = getString("results.lifter-guest", this.props.language)
      } else {
        const ordinal = displayPlaceOrdinal(place, entry, this.props.language);
        placeStr = ordinal
      }
      row.push({data: placeStr, backgroundColor: BACKGROUNDCOLOR.DEFAULT})
    }

    // prognosis
    let prognosis = ''
    if (rankType === 'SEX_EQUIPMENT_WEIGHTCLASS') {
      // get place from order in array
      let prognosisPlace = 0
      const prognosisTotal = getPrognosisTotalKg(entry)
      if (prognosisTotal === 0) {
        // If the lifter has no total, then don't report a place.
        prognosisPlace = 0
      } else {
        // Just show the Place from the first division in the list.
        // This is the first division shown in the "Division" column.
        if (entry.divisions.length === 0) {
          // do nothing
        } else {
          const firstDiv = entry.divisions[0];
          // Look at all the categories, and find the first one including this division
          // and entry. Because the categories are in sorted order, SBD takes priority
          // over B by default.
          for (let i = 0; i < prognosisResults.length; i++) {
            const result = prognosisResults[i];
            if (result.category.division !== firstDiv || result.category.event !== 'SBD') {
              continue;
            }
            const catEntries = result.orderedEntries;
            for (let j = 0; j < catEntries.length; j++) {
              const catEntry = catEntries[j];
              if (catEntry.id === entry.id) {
                // We can use the index into the array as their place, since it sorted and guests will be last in the array
                prognosisPlace = j + 1
                break
              }
            }
            if (prognosisPlace !== 0) break
          }
        }
      }
      const asNumber = this.props.meet.inKg ? prognosisTotal : kg2lbs(prognosisTotal);
      prognosis = prognosisTotal === 0 ? '' : displayWeight(asNumber, this.props.language)
      if (prognosis !== '') {
        if (prognosisPlace !== 0) {
          if (entry.guest) prognosis += ' [' + getString("results.lifter-guest", this.props.language) + ']'
          else prognosis += ' [' + prognosisPlace + ']'
        }
      }
    } else if (rankType === 'SEX_EQUIPMENT_POINTS') {
      const rtPointsResults = rankTypePointsResults.find(rt => rt.rankType === rankType)
      let pointsResults = undefined
      if (rtPointsResults !== undefined) pointsResults = rtPointsResults.pointsResults
      let prognosisPoints = 0
      let prognosisPlace = 0
      const esPointsResults = pointsResults !== undefined ? pointsResults.esPointsResults.find(e => e.equipmentSexEvent.equipment === entry.equipment && e.equipmentSexEvent.sex === entry.sex && e.equipmentSexEvent.event === entry.events[0]) : undefined
      if (esPointsResults !== undefined) {
        const index = esPointsResults.orderedPrognosisPointsEntries.findIndex(e => e.entryId === entry.id)
        if (index !== -1) {
          prognosisPlace = index + 1
          prognosisPoints = esPointsResults.orderedPrognosisPointsEntries[index].prognosisPoints
        }
      }
      prognosis = prognosisPoints === 0 ? '' : displayPoints(prognosisPoints, this.props.language)
      if (prognosis !== '') {
        if (prognosisPlace !== 0) {
          if (entry.guest) prognosis += ' [' + getString("results.lifter-guest", this.props.language) + ']'
          else prognosis += ' [' + prognosisPlace + ']'
        }
      }
    } else if (rankType === 'EQUIPMENT_POINTS') {
      const rtPointsResults = rankTypePointsResults.find(rt => rt.rankType === rankType)
      let pointsResults = undefined
      if (rtPointsResults !== undefined) pointsResults = rtPointsResults.pointsResults
      let prognosisPoints = 0
      let prognosisPlace = 0
      const ePointsResults = pointsResults !== undefined ? pointsResults.ePointsResults.find(e => e.equipmentEvent.equipment === entry.equipment && e.equipmentEvent.event === entry.events[0]) : undefined
      if (ePointsResults !== undefined) {
        const index = ePointsResults.orderedPrognosisPointsEntries.findIndex(e => e.entryId === entry.id)
        if (index !== -1) {
          prognosisPlace = index + 1
          prognosisPoints = ePointsResults.orderedPrognosisPointsEntries[index].prognosisPoints
        }
      }
      prognosis = prognosisPoints === 0 ? '' : displayPoints(prognosisPoints, this.props.language)
      if (prognosis !== '') {
        if (prognosisPlace !== 0) {
          if (entry.guest) prognosis += ' [' + getString("results.lifter-guest", this.props.language) + ']'
          else prognosis += ' [' + prognosisPlace + ']'
        }
      }

    }
    row.push({data: prognosis, backgroundColor: BACKGROUNDCOLOR.DEFAULT})

    // ATTNL
    const entryAttnl = this.props.attnl.attnls[this.props.platform-1].find((entryAttnl) => entryAttnl.id === entry.id);
    const attnl = (entryAttnl === undefined || entryAttnl.attnl === 0) ? "" : entryAttnl.attnl.toString()
    row.push({data: attnl, backgroundColor: BACKGROUNDCOLOR.DEFAULT})

    return row
  };

  getTableRows = (categoryResults: Array<CategoryResults>, prognosisResults: CategoryResults[], rankTypePointsResults: RankTypePointsResults[]) => {

    const orderedEntries = this.props.orderedEntries;
    const currentEntryId = this.props.currentEntryId;
    
    const color = this.props.meet.textColor
    const backgroundColor = this.props.meet.backgroundColor
    const highlightBackgroundColor = this.props.meet.highlightBackgroundColor

    const rows = [];
    for (let i = 0; i < orderedEntries.length; i++) {
      const entry = orderedEntries[i];

      const isCurrent = entry.id === currentEntryId;
      const entryBackgroundColor = isCurrent ? BACKGROUNDCOLOR.HIGHLIGHT : BACKGROUNDCOLOR.DEFAULT;
      const row = this.getTableRow(entry, entryBackgroundColor, categoryResults, prognosisResults, rankTypePointsResults)
      
      rows.push(row)
    }
    return rows;
  };

  render() {

    const tableData = getTableData()

    const entryHeight = tableData.entryHeight
    const entryYGap = tableData.entryYGap
    const entryXGap = tableData.entryXGap

    const useProjected = true
    // Calculate the Division placings for each of the lifters.
    const categoryResults = useProjected
      ? getProjectedResults(
          this.props.registration.entries,
          this.props.meet.weightClassesKgMen,
          this.props.meet.weightClassesKgWomen,
          this.props.meet.weightClassesKgMx,
          this.props.meet.combineSleevesAndWraps,
          this.props.meet.combineSingleAndMulti,
        )
      : getFinalResults(
          this.props.registration.entries,
          this.props.meet.weightClassesKgMen,
          this.props.meet.weightClassesKgWomen,
          this.props.meet.weightClassesKgMx,
          this.props.meet.combineSleevesAndWraps,
          this.props.meet.combineSingleAndMulti,
        );

    // get the prognosis placings
    const prognosisResults = getPrognosisResults(
      this.props.registration.entries,
      this.props.meet.weightClassesKgMen,
      this.props.meet.weightClassesKgWomen,
      this.props.meet.weightClassesKgMx,
      this.props.meet.combineSleevesAndWraps,
      this.props.meet.combineSingleAndMulti
    );

    // calculate points placings if appropriate
    const lifting = this.props.lifting[this.props.server.platform-1];
    const rankTypes = getFlightRanktypes(this.props.orderedEntries)
    console.log(rankTypes)
    const rankTypePointsResults: RankTypePointsResults[] = []
    rankTypes.forEach(rankType => {
      const pointsResults: PointsResults = getPointsResults(
        rankType,
        this.props.orderedEntries,
        this.props.registration.entries,
        lifting.day,
        lifting.session,
        'D',  // for session view, always assume all lifts rather than the lift the current flight is on
        3,    // for session view, always assume all lifts rather than the lift the current flight is on
        )
      rankTypePointsResults.push({rankType: rankType, pointsResults: pointsResults})
    })

    const data = this.getTableRows(categoryResults, prognosisResults, rankTypePointsResults)

    const flightTitle = 'Flight ' + this.props.flight
    const title: TableEntry = {data: flightTitle, backgroundColor: BACKGROUNDCOLOR.TITLE}
    const header: TableEntry[] = [
      {data: 'Name', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'BW', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'Class', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'Lot', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'S1', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'S2', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'S3', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'B1', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'B2', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'B3', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'D1', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'D2', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'D3', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'Total', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'Points', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'Place', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'Prognosis', backgroundColor: BACKGROUNDCOLOR.HEADER},
      {data: 'ATTNL', backgroundColor: BACKGROUNDCOLOR.HEADER},
    ]
    const aligns = ['left', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right', 'right']
    const widths = [140, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 66, 42]
    const titleWidth = getTitleWidth(widths, entryXGap)
    let left = (1920 - titleWidth) / 2
    if (this.props.left !== undefined) left = this.props.left

    const tableHeight = ((data.length + 2) * entryHeight) + ((data.length + 1) * entryYGap)

    const dataTable =  (
      <DataTable top={this.props.top} left={left} title={title} header={header} data={data} widths={widths} aligns={aligns}/>
    )
    return dataTable

  }
}

const mapStateToProps = (state: GlobalState): StateProps => {
  return {
    registration: state.registration,
    meet: state.meet,
    lifting: state.lifting,
    language: state.language,
    attnl: state.attnl,
    server: state.server,
    clock: state.clock,
  };
};

export default connect(mapStateToProps)(LiftingTableBare);
