import { AnyAction, Store } from "redux";
import clientIo from "socket.io-client";
import { GlobalState } from "./types/stateTypes";
import { getServerUrl } from "./server/server";
import { AuthMessage, newAuthMessage } from "./server/connection";

export class ReduxSocketIoSynchronisationService {
  private _store: Store;
  private _socketClient!: SocketIOClient.Socket;
  private _username: string = "";
  private _password: string = "";
  
  constructor(store: Store<GlobalState, any>) {
    this._store = store;
  }

  connect(hostdetails: string, username: string, password: string) {
    // get the url of the specified server and connect to it
    const serverUrl = getServerUrl(hostdetails);
    console.log("RSIOSS constructor", serverUrl!);
    this._username = username;
    this._password = password;
    this._socketClient = clientIo.connect(serverUrl!);
    this._socketClient.on("connect", () => this.onConnect());
    this._socketClient.on("server-action", (action: AnyAction) => this._store.dispatch(action));
    console.log(`Trying to connect to backend service`);
  }

  onConnect() {
    console.log(`Connected to backend service, sending auth`);
    const authMessage: AuthMessage = newAuthMessage('client', this._username, this._password);
    this._socketClient.emit("AUTH-ACTION", authMessage);
  }
  
  onStoreAction(action: AnyAction) {
    // Don't sync if the action originated remotely, 
    if (!action.remote) {
      this._socketClient.emit("client-action", action);
    }
  }
}

