import React, { useEffect } from 'react';
import { useState } from 'react';

import { useSelector } from 'react-redux';

import ReactPlayer from 'react-player';

import { ReplaysState } from "../../types/stateTypes";
import { GlobalState } from '../../types/stateTypes';
import { getLiftName } from '../../logic/liftingOrder';
import { Lift } from '../../types/dataTypes';

const uploadPath = 'uploads/'

const ObsReplaysView: React.FC = () => {


  const url = window.location.protocol + '//' + window.location.host
  const replayState: ReplaysState = useSelector((state: GlobalState) => state.replays)
  
  const getCurrentFromLS = () => {
    return localStorage.getItem('currentReplayLifter') || '';
  }  
  const [currentLifter, setCurrentLifter] = useState(getCurrentFromLS());

  useEffect(() => {
      localStorage.setItem("currentReplayLifter", currentLifter);
  }, [currentLifter]);

  const replaySelectOptions: JSX.Element[] = []
  replaySelectOptions.push(<option key={'select'} value={''}>{'<Select a lifter>'}</option>)
  replayState.replays.forEach(replay => {
      replaySelectOptions.push(<option key={replay.lifterName} value={replay.lifterName}>{replay.lifterName}</option>)
  })

  let vids = null
  if (currentLifter !== '') {
    const lifter = replayState.replays.find(l => l.lifterName === currentLifter)
    if (lifter !== undefined) {
        const replays = lifter.replays
        if (replays.length > 0) {
            const videos: JSX.Element[] = []
            replays.forEach(replay => {
                const nameParts = replay.split('-')
                const position = nameParts[0]
                const lift = nameParts[2]
                const liftName = getLiftName(lift as Lift)
                const attempt = nameParts[3]
                videos.push(
                    <div style={{fontSize: "36px"}}>
                        {liftName} - Attempt {attempt} - {position}
                    </div>
                )
                videos.push(
                    <ReactPlayer 
                        controls={true}
                        width={'100%'}
                        height={'100%'}
                        key={replay}
                        url={url + '/' + uploadPath + replay} 
                    />
                )
            })
            vids = (
                <>
                    {videos}
                </>
            )
        }
    }
  }

  return (
    <div className="App">
      <div style={{fontSize: "36px"}}>
        <label>
            Replays
            <select
            value={currentLifter}
            onChange={e => setCurrentLifter(e.target.value)}
            >
                {replaySelectOptions}
            </select>
        </label>
      </div>
      {vids}
    </div>
  );
    
}

export default ObsReplaysView;
