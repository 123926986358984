// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import React from "react";
import { connect } from "react-redux";

import FlightDetailsBare from "./FlightDetailsBare";

import { Entry, Flight, Language } from "../../types/dataTypes";
import { GlobalState, MeetState, LiftingState, ServerState } from "../../types/stateTypes";

import styles from "./ObsFlightDetails.module.scss";

interface StateProps {
  meet: MeetState;
  lifting: ReadonlyArray<LiftingState>;
  entries: ReadonlyArray<Entry>;
  language: Language;
  server: ServerState;
}

interface OwnProps {
  currentOnly: boolean;
}

type Props = StateProps;

class ObsFlightDetailsBare extends React.Component<Props> {
  
  render() {
    
    const lifting = this.props.lifting[this.props.server.platform-1];
    const day = lifting.day;
    const session = lifting.session;
    const platform = lifting.platform;

    const numPlatforms = this.props.meet.platformsOnDays[day - 1];
    const onlyPlatform = numPlatforms === 1
    
    // Get all the entries under the current lifting day, session, platform.
    const shownEntries = this.props.entries.filter((e) => {
      return e.day === day && e.session === session && e.platform === platform;
    });

    const knownFlights: Flight[] = [];
    let flightDetails
    if (shownEntries.length > 0) {
      knownFlights.push(lifting.flight);
      flightDetails = (
        <FlightDetailsBare onlyPlatform={onlyPlatform} day={day} session={session} platform={platform} knownFlights={knownFlights} shownEntries={shownEntries} lifting={lifting} scale={1} bare={true}/>
      )
    } else {
      flightDetails = (
        <></>
      )
    }

    return (
      <div>
        <div id="obsFlightDetailsView" className={styles.liftingView}>
          <div style={{minHeight: "100%", fontSize: 'calc((1vw + 1vh)*' + 1 + ')'}}>
            {flightDetails}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState): StateProps => {
  return {
    meet: state.meet,
    lifting: state.lifting,
    entries: state.registration.entries,
    language: state.language,
    server: state.server,
  };
};

export default connect(mapStateToProps)(ObsFlightDetailsBare);

