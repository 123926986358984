// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Defines the Navigation component for navigating between pages using react-router.

import React from "react";
import { useState, useEffect } from 'react';
import { FormattedMessage } from "react-intl";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

// The LinkContainer is used to wrap Components that change the URL,
// hooking them up with the Router.
import { LinkContainer } from "react-router-bootstrap";

const Navigation = () => {

  const PrivateLinkContainer = ({ to, id, defaultMessage, exact = false }: 
                                { to: string, id: string, defaultMessage: string, exact?: boolean}) => {
    // FIXME - how do you add exact conditionally?
    const nav = (<Nav.Link active={false}>
                    <FormattedMessage id={id} defaultMessage={defaultMessage} />
                </Nav.Link>);
    if (exact) {
      return (
        <LinkContainer exact to={to}>
          {nav}
        </LinkContainer>
      );
    } else {
      return (
        <LinkContainer to={to}>
          {nav}
        </LinkContainer>
      );
    };
  };

  return (
    <Navbar sticky="top" bg="dark" variant="dark">
      <Navbar.Brand>
        <img alt="OpenLifter" src="nwcb.png" height="20px" width="130px" />
      </Navbar.Brand>
      {/* Navbar uses Toggle and Collapse to automatically create a hamburger menu
          in case of overflow on small screens.*/}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="nav-link" style={{lineHeight: "1", paddingTop: 0, paddingBottom: 0, fontSize: "1rem"}}>
          <PrivateLinkContainer to="/live" id="nav.live" defaultMessage="Live" />
          <PrivateLinkContainer to="/flight" id="nav.flight" defaultMessage="Flight" />
          <PrivateLinkContainer to="/session" id="nav.session" defaultMessage="Session" />
          <PrivateLinkContainer to="/results" id="nav.results" defaultMessage="Results" />
          <PrivateLinkContainer to="/replays" id="nav.replays" defaultMessage="Replays" />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
