import { GlobalState } from "../types/stateTypes";

export interface InitializeMessage {
  state: GlobalState;
  source: string;
}

export interface AuthMessage {
  source: string;
  username: string;
  password: string;
}

export const newAuthMessage = (source: string, username: string, password: string): AuthMessage => {
  return {
    source: source,
    username: username,
    password: password,
  };
};
