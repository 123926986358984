// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { newDefaultImage, newDefaultText, newDefaultBox, newScreen, newDefaultDrl } from "../logic/drl";
import { ImageAction, TextAction, BoxAction, ClearAction, ScreenAction, OverwriteStoreAction } from "../types/actionTypes";
import { DrlState } from "../types/stateTypes";
import { Image, Text, Box, Screen, Drl } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

const screen = newScreen();
const initialState: DrlState = {
  // Image and text objects for DRL.
  // This array owns all DRL information.
  images: [],
  texts: [],
  boxes: [],
  screen: screen,
};

type Action = ImageAction | TextAction | BoxAction | ClearAction | ScreenAction | OverwriteStoreAction;

export default (state: DrlState = initialState, action: Action): DrlState => {
  switch (action.type) {

    case "UPDATE_IMAGE": {

      const imageId = action.imageId;
      // Clone the images array
      const images: Array<Image> = state.images.slice();
      // Find the image if it exists.
      const index = images.findIndex((obj) => obj.imageId === imageId);
      if (index == -1) {
        const newImage = newDefaultImage(imageId);
        // Overwrite any newImage properties with those given in changes.
        images.push(Object.assign(newImage, action));
      } else {
        const thisImage = Object.assign({}, images[index]);
        images[index] = Object.assign(thisImage, action);
      }
      return {
        ...state,
        images: images,
      };
    }

    case "UPDATE_TEXT": {

      const textId = action.textId;
      // Clone the text array
      const texts: Array<Text> = state.texts.slice();
      // Find the text if it exists.
      const index = texts.findIndex((obj) => obj.textId === textId);
      if (index == -1) {
        const newText = newDefaultText(textId);
        // Overwrite any newText properties with those given in changes.
        texts.push(Object.assign(newText, action));
      } else {
        const thisText = Object.assign({}, texts[index]);
        texts[index] = Object.assign(thisText, action);
      }
      return {
        ...state,
        texts: texts,
      };
    }

    case "UPDATE_BOX": {
        
      const boxId = action.boxId;
      // Clone the box array
      const boxes: Array<Box> = state.boxes.slice();
      // Find the text if it exists.
      const index = boxes.findIndex((obj) => obj.boxId === boxId);
      if (index == -1) {
        const newBox = newDefaultBox(boxId);
        // Overwrite any newBox properties with those given in changes.
        boxes.push(Object.assign(newBox, action));
      } else {
        const thisBox = Object.assign({}, boxes[index]);
        boxes[index] = Object.assign(thisBox, action);
      }
      return {
        ...state,
        boxes: boxes,
      };
    }

    case "UPDATE_CLEAR": {
        
      const clearType = action.clearType;
      const images: Array<Image> = state.images.slice();
      const texts: Array<Text> = state.texts.slice();
      const boxes: Array<Box> = state.boxes.slice();
      if (clearType == "image") {
        // Set every image in the passed array to off
        const imageIds = action.clearIds;
        imageIds.forEach(imageId => {
          const index = images.findIndex((obj) => obj.imageId === imageId);
          if (index != -1) {
            var thisImage = Object.assign({}, images[index]);
            thisImage.imageShow = false;
            images[index] = thisImage;
          }
        });
      } else if (clearType == "text") {
        // set every text in the passed array to off
        const textIds = action.clearIds;
        textIds.forEach(textId => {
          const index = texts.findIndex((obj) => obj.textId === textId);
          if (index != -1) {
            var thisText = Object.assign({}, texts[index]);
            thisText.textShow = false;
            texts[index] = thisText;
          }
        });
      } else if (clearType == "box") {
        // set every box in the passed array to off
        const boxIds = action.clearIds;
        boxIds.forEach(boxId => {
          const index = boxes.findIndex((obj) => obj.boxId === boxId);
          if (index != -1) {
            var thisBox = Object.assign({}, boxes[index]);
            thisBox.boxShow = false;
            boxes[index] = thisBox;
          }
        });
      } else if (clearType == "all") {
        // remove everything - all images, texts and boxes
        images.length = 0;
        texts.length = 0;
        boxes.length = 0;
      }
      return {
        ...state,
        images: images,
        texts: texts,
        boxes: boxes,
    };
    }

    case "UPDATE_SCREEN": {
        
      const componentId = action.componentId;
      const width = action.width;
      const height = action.height;
      const screenShow = action.screenShow;
      // Clone the screen
      var screen: Screen = { ...state.screen };
      if (screen == null) {
        screen = newScreen(componentId, width, height, screenShow);
      } else {
        screen = Object.assign(screen, action);
      }
      return {
        images: [],
        texts: [],
        boxes: [],
        screen: screen,
      };
    }

    case "OVERWRITE_STORE": {
      // TODO: currently code to add a drl to the store if one doesn't exist
      if (action.store.drl) {
        return action.store.drl;
      } else {
        const drl = newDefaultDrl();
        return {
          images: drl.images,
          texts: drl.texts,
          boxes: drl.boxes,
          screen: drl.screen,
        };
      }
    }

    default:
      checkExhausted(action);
      return state;
  }
};
