// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { 
  newStream,
  newDefaultLifterData,
  getBest3LiftKg,
} from "../logic/stream";
import { UpdateBarLoadedAction, MarkLiftAction, UpdateLiftAction, UpdateLifterVisibleAction, UpdateReloadConfigAction, OverwriteStoreAction } from "../types/actionTypes";
import { StreamState } from "../types/stateTypes";
import { Stream } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

const lifterVisible = false;
const loaded = false;
const attempted = false;
const lifterData = newDefaultLifterData();
const reloadConfig = 0;

const initialState: StreamState = {
  // Data for overlays.
  // This object owns all stream information.
  lifterVisible: lifterVisible,
  loaded: loaded,
  attempted: attempted,
  lifterData: lifterData,
  reloadConfig: reloadConfig,
};

type Action = UpdateBarLoadedAction | MarkLiftAction | UpdateLiftAction | UpdateLifterVisibleAction | UpdateReloadConfigAction | OverwriteStoreAction;

export default (state: StreamState = initialState, action: Action): StreamState => {
  switch (action.type) {

    case "UPDATE_BARLOADED": {

      const loaded = action.loaded;
      const lifterData = action.lifterData;
      const attnls = action.attnls;
      const platform = action.platform;
      if (lifterData) {
        return {
            ...state,
            lifterVisible: true,
            loaded: loaded,
            attempted: false,
            lifterData: lifterData,
        };
      } else {
        return {
            ...state,
            lifterVisible: false,
            loaded: loaded,
        };
      }
    }

    case "MARK_LIFT":
    case "UPDATE_LIFT":
    {
        
      // update the lifterData with the updated lift details
      // we do this as the details are a snapshot and won't be auto-updated
        
      const attemptOneIndexed = Number(action.attemptOneIndexed);
      const success = Boolean(action.success);
      // Map true to '1' and false to '-1'.
      const status = success === true ? 1 : -1;

      // update the lift result and remove any ranking info
      const liftResults: Array<number> = state.lifterData.liftResults.slice();
      liftResults[attemptOneIndexed - 1] = status;
      const bestForLift = getBest3LiftKg(state.lifterData.lifts, liftResults);
      const lifterData = {
        ...state.lifterData,
        liftResults: liftResults,
        bestForLift: bestForLift,
        divisionEventRankings: [],
      };
      return {
        ...state,
        attempted: true,
        lifterData: lifterData,
      };
    }
    
    case "UPDATE_LIFTER_VISIBLE": {
      const visible = action.visible;
      return {
          ...state,
          lifterVisible: visible,
      };
    }

    case "UPDATE_RELOAD_CONFIG": {
      const reload = action.reload;
      console.log("UPDATE_RELOAD_CONFIG", reload)
      return {
          ...state,
          reloadConfig: reload,
      };
    }

    case "OVERWRITE_STORE": {
      if (action.store.stream) {
        return action.store.stream;
      } else {
        const stream = newStream(lifterVisible, loaded, attempted, lifterData, reloadConfig);
        return stream;
      }
    }

    default:
//      checkExhausted(action);
      return state;
  }
};
