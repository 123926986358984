// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Defines logic for creating and working with Timer objects.

import { Timer, Entry, Attnl, EntryAttnl, TimerString, Lift, Language, SessionLiftTime, LiftTime, FlightTime, TimeData, Flight } from "../types/dataTypes";
import { LiftingState, TimerState, MeetState, TimeDataState } from "../types/stateTypes";
import { getLiftBreak, getLongLiftBreak, getFlightBreak, getLiftDuration } from "./config";
import {
  getLiftersInFlightLift,
  getLiftersLeftInFlightLiftAttempt,
  getLiftsLeftInCurrentFlightLift,
  checkLiftsLeftInFlightLift,
  getEntriesOnPlatform,
  getFlightsOnPlatform,
  getLiftingOrder,
  getTimersInCurrentFlightLift,
  getTimersInFlightLift,
  checkFlightComplete,
  checkFlightLiftStarted,
  getFlightLift,
} from "./liftingOrder";
import { MAX_ATTEMPTS } from "./entry";

import { getString, localizeFlight } from "./strings";

export const newDefaultOffset = () => {
  return 0;
};

export const newAttnl = (): Attnl => {
  return {
    attnls: [],
  };
};

export const newTimer = (offset: number): Timer => {
  return {
    offset: offset,
  };
};

export const getUpdatedTimers = (offset: number, entries: ReadonlyArray<Readonly<Entry>>, lifting: LiftingState, language: Language): [] => {
  return getUpdatedTimerData("TIMERS", offset, entries, lifting, language);
}

export const getUpdatedAttnls = (offset: number, entries: ReadonlyArray<Readonly<Entry>>, lifting: LiftingState, language: Language): [] => {
  return getUpdatedTimerData("ATTNLS", offset, entries, lifting, language);
}

export const getUpdatedTimerData = (returnType: string, offset: number, entries: ReadonlyArray<Readonly<Entry>>, lifting: LiftingState, language: Language): any => {
 
  const day = lifting.day;
  const session = lifting.session;
  const platform = lifting.platform;
  const currentFlight = lifting.flight;
  const currentLift = lifting.lift;
  
  const entriesOnPlatform = getEntriesOnPlatform(day, session, platform, entries);
  const flightsOnPlatform = getFlightsOnPlatform(entriesOnPlatform);
  
  
  // FIXME: pass this as a param rather than working out each time here
  
  // Get all the entries under the current (day, platform) selection.
  const shownEntries = entries.filter((e) => {
    return e.day === day && e.session === session && e.platform === platform;
  });
  // Look through the entries to discover what flights exist.
  const knownFlights: Flight[] = [];
  for (let i = 0; i < shownEntries.length; i++) {
    const entry = shownEntries[i];
    if (knownFlights.indexOf(entry.flight) === -1) {
      knownFlights.push(entry.flight);
    }
  }
  
  let liftBreakValue = getLiftBreak()
  if (knownFlights.length === 1) {
    liftBreakValue = getLongLiftBreak()
  }
  
  
  const flightBreakValue = getFlightBreak();
  const liftDuration = getLiftDuration();

  const squatString = getString("lifting.footer-squat", language);
  const benchString = getString("lifting.footer-bench", language);
  const deadliftString = getString("lifting.footer-deadlift", language)
  
  let timerValue = offset;
  
  const timers: TimerString[] = [];
  let attnls: EntryAttnl[] = [];
  
  const flightTemplate = getString("lifting.footer-flight-template", language);

  const inProgressTemplate = "{flightName} {liftString} in progress ({liftsLeftInCurrentFlightLift} lifts left)";
  const startsInTemplate = "{flightName} {liftString} ({liftersInFlightLift} lifters) starts in approx {timerValueString}";
  const liftBreakTemplate = "{liftBreak} minutes break";
  const flightBreakTemplate = "{flightBreak} minutes break";
  
  enum WhichFlight {
    PREVIOUS,
    CURRENT,
    FUTURE,
  }
  let whichFlight;
  if (currentFlight === null && currentLift === null) {
    whichFlight = WhichFlight.FUTURE;
  } else {
    whichFlight = WhichFlight.PREVIOUS;
  }

  const lifts = "SBD";
  let l = 0;
  
  for (const platformLift of lifts) {
    let liftString = "";
    switch (platformLift) {
      case "S":
        liftString = squatString;
        break;
      case "B":
        liftString = benchString;
        break;
      case "D":
        liftString = deadliftString;
        break;
    }        

    let lift: Lift = platformLift as Lift;

    let liftHasLiftsLeft = false;
    for (let f = 0; f < flightsOnPlatform.length; f++) {
      
      const flight = flightsOnPlatform[f];
      // if timer is more than 1, it must be being conrolled by the interval timer,
      // so even though the table may have set the next session/flight/lift, it won't have started
      if (timerValue > 1) {
        // check if the flight lift has actually already been completed (i.e. we're in a flight or lift break)
        const unorderedLiftersInFlightLift = getLiftersInFlightLift(day, session, platform, flight, lift, entries);
        const liftersInFlightLift = getLiftingOrder(unorderedLiftersInFlightLift, lifting).orderedEntries;
        const liftsLeftInFlightLift = checkLiftsLeftInFlightLift(lift, liftersInFlightLift);
        if (liftsLeftInFlightLift === false) {
          whichFlight = WhichFlight.PREVIOUS;
        } else {
          whichFlight = WhichFlight.FUTURE;
        }
      } else {
        if (flight === currentFlight && lift === currentLift) {
          whichFlight = WhichFlight.CURRENT;
        } else {
          if (whichFlight === WhichFlight.CURRENT) {
            whichFlight = WhichFlight.FUTURE;
          }
        }
      }
        
      //console.log(flight, lift, whichFlight);
      
      //TODO: don't need to update both arrays in a single call, add conditionals around them
      
      // only consider flights that have not finished
      if (! (whichFlight === WhichFlight.PREVIOUS)) {
      
        const flightName = flightTemplate.replace("{flight}", localizeFlight(flight, language));
        
        const unorderedLiftersInFlightLift = getLiftersInFlightLift(day, session, platform, flight, lift, entries);
        
        // check what lift the flight is on, and use that to get the lifting order
        const flightLifting = {...lifting}
        const flightLift = getFlightLift(unorderedLiftersInFlightLift)
        if (flightLift != null) {
          flightLifting.lift = flightLift
        }
        
        const liftersInFlightLift = getLiftingOrder(unorderedLiftersInFlightLift, flightLifting).orderedEntries;
        
        const measuredTime = new Date(0);
        if (timerValue == 1) {
          timerValue = 0;
        }
        measuredTime.setSeconds(timerValue);
        const timerValueString = measuredTime.toISOString().substr(11, 8);

        if (whichFlight === WhichFlight.CURRENT) {
          // for current flight, get number of lifts left
          const liftsLeftInCurrentFlightLift = getLiftsLeftInCurrentFlightLift(lift, liftersInFlightLift, flightLifting);
          //console.log(liftsLeftInCurrentFlightLift);
          if (liftsLeftInCurrentFlightLift > 0) {
            // some lifts left in flight, display number of lifts left
            liftHasLiftsLeft = true;
            const inProgress = inProgressTemplate.replace("{flightName}", flightName)
                                                 .replace("{liftString}", liftString)
                                                 .replace("{liftsLeftInCurrentFlightLift}", liftsLeftInCurrentFlightLift.toString());
            const timerString: TimerString = {timerType: "inProgress", timerText: inProgress, timerLift: liftString, timerTime: timerValueString};
            timers.push(timerString)
            // get approx times to next lift
            attnls = getTimersInCurrentFlightLift(timerValue, lift, liftersInFlightLift, flightLifting);
            
            timerValue += liftsLeftInCurrentFlightLift*liftDuration;
          }
        } else {
          // no lifts attempted in this flight, display full flight details
          liftHasLiftsLeft = true;
          const startsIn = startsInTemplate.replace("{flightName}", flightName)
                                           .replace("{liftString}", liftString)
                                           .replace("{liftersInFlightLift}", liftersInFlightLift.length.toString())
                                           .replace("{timerValueString}", timerValueString);
          const timerString: TimerString = {timerType: "startsIn", timerText: startsIn, timerLift: liftString, timerTime: timerValueString};
          timers.push(timerString)
          // get approx time to next lift (won't update any already set, we only want the next lift time)
          getTimersInFlightLift(attnls, timerValue, lift, liftersInFlightLift, flightLifting);
          
          // FIXME: how do we cater for MAX_ATTEMPTS being 5 and not 3?
          
          timerValue += (liftersInFlightLift.length*3)*liftDuration;
        }
      }
      
      if (flightBreakValue > 0) {
        if ((f+1) < flightsOnPlatform.length && liftHasLiftsLeft) {
          const flightBreak = flightBreakTemplate.replace("{flightBreak}", flightBreakValue.toString());
          const timerString: TimerString = {timerType: "flightBreak", timerText: flightBreak, timerLift: liftString, timerTime: ""};
          timers.push(timerString)
          timerValue += flightBreakValue*60;
        }
      }
      
    }
    
    l += 1;
    if (liftBreakValue > 0) {
      if (l < lifts.length && liftHasLiftsLeft) {
        const liftBreak = liftBreakTemplate.replace("{liftBreak}", liftBreakValue.toString());
        const timerString: TimerString = {timerType: "liftBreak", timerText: liftBreak, timerLift: liftString, timerTime: ""};
        timers.push(timerString)
        timerValue += liftBreakValue*60;
      }
    }
    
  }

  if (returnType === "TIMERS") {
    return timers;
  } else if (returnType === "ATTNLS") {
    return attnls;
  }
}

// FIXME: move other similar functions from Primary server
export const getEndTimeSecs = (time: string, deltaSecs: number): string => {
  const date = new Date("01/01/2020 " + time);
  const millis = date.getTime()
  const date2 = new Date(millis + (deltaSecs * 1000))
  return date2.toTimeString().substring(0,5);
}

export const getDeltaTime = (time: string, deltaMins: number): string => {
  const date = new Date("01/01/2020 " + time);
  const millis = date.getTime()
  const date2 = new Date(millis + (deltaMins * 60000))
  return date2.toTimeString().substring(0,5);
}

export const getOffsetSecs = (time1: string, time2: string): number => {
  const date1 = new Date("01/01/2020 " + time1);
  const date2 = new Date("01/01/2020 " + time2);
  const diff = date2.getTime() - date1.getTime();
  const secs = diff/1000
  return secs
}

export const getSessionTimerData = (returnType: string, session: number, currentTime: string, offset: number, time: string, entries: ReadonlyArray<Readonly<Entry>>, lifting: LiftingState, meet: MeetState, language: Language, timeData: TimeDataState): (SessionLiftTime | TimerString[]) => {
 
  // offset is number of minutes to time passed in - convert to seconds to cater for use of non-60s lift duration
  
  
  // FIXME: fix/uncomment TIMERS output - if started, state start time rather than expected time
  
  
  const day = lifting.day;
  const platform = lifting.platform;

  const timers: TimerString[] = [];
  const sessionTimes: SessionLiftTime = {} as SessionLiftTime;

  
  // FIXME: pass this as a param rather than working out each time here
  
  // Get all the entries under the current (day, platform) selection.
  const shownEntries = entries.filter((e) => {
    return e.day === day && e.session === session && e.platform === platform;
  });
  // Look through the entries to discover what flights exist.
  const knownFlights: Flight[] = [];
  for (let i = 0; i < shownEntries.length; i++) {
    const entry = shownEntries[i];
    if (knownFlights.indexOf(entry.flight) === -1) {
      knownFlights.push(entry.flight);
    }
  }
  
  let liftBreakValue = getLiftBreak()
  if (knownFlights.length === 1) {
    liftBreakValue = getLongLiftBreak()
  }
  
  
  const flightBreakValue = getFlightBreak();
  const liftDuration = getLiftDuration();

  const squatString = getString("lifting.footer-squat", language);
  const benchString = getString("lifting.footer-bench", language);
  const deadliftString = getString("lifting.footer-deadlift", language)
  
  const flightTemplate = getString("lifting.footer-flight-template", language);
  const startsInTemplate = "{flightName} {liftString} ({liftersInFlightLift} lifters) starts in approx {timerValueString}";
  const liftBreakTemplate = "{liftBreak} minutes break";
  const flightBreakTemplate = "{flightBreak} minutes break";

  let offsetSeconds = offset * 60
  let timerValue = offsetSeconds;
  
  const liftData = timeData.liftData
  const sessionData = {...liftData[session.toString()]}
  
  const entriesOnPlatform = getEntriesOnPlatform(day, session, platform, entries);
  const flightsOnPlatform = getFlightsOnPlatform(entriesOnPlatform);
  
  const lifts = "SBD";
  let l = 0;

  const liftTimes: LiftTime = {} as LiftTime
  
  for (const platformLift of lifts) {
  
    let liftString = "";
    switch (platformLift) {
      case "S":
        liftString = squatString;
        break;
      case "B":
        liftString = benchString;
        break;
      case "D":
        liftString = deadliftString;
        break;
    }        
    let lift: Lift = platformLift as Lift;

   
    const flightTimes: FlightTime = {} as FlightTime
    
    
    
    // TEMP (for FIX below)
    let fixCount = 0
    
    
    
    for (let f = 0; f < flightsOnPlatform.length; f++) {
      
      const flight = flightsOnPlatform[f];
      
      const unorderedLiftersInFlightLift = getLiftersInFlightLift(day, session, platform, flight, lift, entries);
      // check if flight/lift started (i.e. some lifts have taken place)
      const flightStarted = checkFlightLiftStarted(lift, unorderedLiftersInFlightLift)

      
      if (flightStarted) {
        // don't recalc and update the flight start time, as it's already started
        flightTimes[flight] = sessionData[liftString][flight]
        
        // check if there are lifts left in the flight
        const flightLifting = {...lifting}
        const flightLift = getFlightLift(unorderedLiftersInFlightLift)
        if (flightLift != null) {
          flightLifting.lift = flightLift
        }
        const liftersInFlightLift = getLiftingOrder(unorderedLiftersInFlightLift, flightLifting).orderedEntries;
        const liftsLeftInCurrentFlightLift = getLiftsLeftInCurrentFlightLift(lift, liftersInFlightLift, flightLifting);
        //console.log(liftsLeftInCurrentFlightLift);
        
        if (liftsLeftInCurrentFlightLift > 0) {
/*          
          const inProgress = inProgressTemplate.replace("{flightName}", flightName)
                                                .replace("{liftString}", liftString)
                                                .replace("{liftsLeftInCurrentFlightLift}", liftsLeftInCurrentFlightLift.toString());
          const timerString: TimerString = {timerType: "inProgress", timerText: inProgress, timerLift: liftString, timerTime: timerValueString};
          timers.push(timerString)
*/          
          timerValue += liftsLeftInCurrentFlightLift*liftDuration;
          
        } else {
          
          // flight must be complete, set end time if not set and set timer from end time
          const timeData: TimeData = flightTimes[flight]
          if (timeData.endTime === '') {
            timeData.endTime = currentTime
            
            
            
            
            // TEMP TO 'FIX' MISSING END TIMES
            const fixOffset = getOffsetSecs(time, currentTime) / 60
            const flightCount = unorderedLiftersInFlightLift.length * 3
            fixCount += flightCount
            if (fixOffset >= fixCount) {
              timeData.endTime = getDeltaTime(time, flightCount + (timerValue - offsetSeconds) / 60)
              //console.log('FIX', fixOffset, fixCount, timeData.endTime, currentTime)
            }
           
            
            
            
            flightTimes[flight] = timeData
          }
          timerValue += getOffsetSecs(timeData.startTime, timeData.endTime)
        }
        
      } else {
      
        const numLiftersInFlight = unorderedLiftersInFlightLift.length

  /*      
        const measuredTime = new Date(0);
  ??      if (timerValue == 1) {
  ??        timerValue = 0;
  ??      }
        measuredTime.setSeconds(timerValue);
        
        const timerValueString = measuredTime.toTimeString().substring(0,5);

        // no lifts attempted in this flight, display full flight details
        const flightName = flightTemplate.replace("{flight}", localizeFlight(flight, language));
        const startsIn = startsInTemplate.replace("{flightName}", flightName)
                                          .replace("{liftString}", liftString)
                                          .replace("{liftersInFlightLift}", numLiftersInFlight.toString())
                                          .replace("{timerValueString}", timerValueString);
        const timerString: TimerString = {timerType: "startsIn", timerText: startsIn, timerLift: liftString, timerTime: timerValueString};
        timers.push(timerString)
  */
        
        const startTime = getEndTimeSecs(time, timerValue - offsetSeconds)

        const timeData: TimeData = {
          startTime: startTime,
          endTime: '',
          startOffset: timerValue / 60,
          endOffset: -1,
        }
        
        flightTimes[flight] = timeData

        timerValue += (numLiftersInFlight*3)*liftDuration;
        
      }

      if (flightBreakValue > 0) {
        if ((f+1) < flightsOnPlatform.length) {
          const flightBreak = flightBreakTemplate.replace("{flightBreak}", flightBreakValue.toString());
          const timerString: TimerString = {timerType: "flightBreak", timerText: flightBreak, timerLift: liftString, timerTime: ""};
          timers.push(timerString)
          timerValue += flightBreakValue*60;
        }
      }
      
    }
    
    liftTimes[liftString] = flightTimes
    
    l += 1;
    if (liftBreakValue > 0) {
      if (l < lifts.length) {
        const liftBreak = liftBreakTemplate.replace("{liftBreak}", liftBreakValue.toString());
        const timerString: TimerString = {timerType: "liftBreak", timerText: liftBreak, timerLift: liftString, timerTime: ""};
        timers.push(timerString)
        timerValue += liftBreakValue*60;
      }
    }
    
  }
  
  sessionTimes[session] = liftTimes
    
  if (returnType === "TIMERS") {
    return timers;
  }
  return sessionTimes;
}

  
