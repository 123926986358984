// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Lifting state only tracks manual overrides.
//
// Outside of overrides, the state of the meet is fully-calculated by the LiftingView.
//
// For safety, correctness, and ease of understanding, the state of the meet is
// intentionally *not* stored in the global state. It is continuously recalculated.
//
// Please do not attempt to store meet state in the Redux store!

import {
  MarkLiftAction,
  UpdateLiftAction,
  SetLiftingGroupAction,
  OverrideAttemptAction,
  OverrideEntryIdAction,
  OverwriteStoreAction,
} from "../types/actionTypes";
import { LiftingState } from "../types/stateTypes";
import { checkExhausted } from "../types/utils";

const initialState: Array<LiftingState> = [
  {
    // Specifies the initial settings for the control widgets on the lifting page.
    // The intention is that the score table sets these manually.
    day: 1,
    session: 1,
    platform: 1,
    flight: "A",
    lift: "S",

    // These properties are normally calculated, but exist here as a mechanism
    // for a one-shot override of the normal logic. After being handled,
    // they are unset.
    overrideAttempt: null, // Allows selecting an attempt, even if it's completed.
    overrideEntryId: null, // Allows selecting a lifter, even if they've already gone.
  },
  {
    day: 1,
    session: 1,
    platform: 2,
    flight: "A",
    lift: "S",
    overrideAttempt: null,
    overrideEntryId: null,
  }
]

type Action =
  | MarkLiftAction
  | UpdateLiftAction
  | SetLiftingGroupAction
  | OverrideAttemptAction
  | OverrideEntryIdAction
  | OverwriteStoreAction;

export default (state: Array<LiftingState> = initialState, action: Action): Array<LiftingState> => {

  switch (action.type) {

    case "MARK_LIFT":
    case "UPDATE_LIFT": 
    {
      // Unset any overrides, returning to normal lifting flow.
      let lifting = state.slice();
      let platformLifting = lifting[action.platform-1]
      platformLifting = {
        ...platformLifting,
        overrideAttempt: null,
        overrideEntryId: null,
      }
      lifting[action.platform-1] = platformLifting
      return [
        ...lifting,
      ];
    }

    case "SET_LIFTING_GROUP":
    {
      // Change all the real state stuff.
      let lifting = state.slice();
      let platformLifting = lifting[action.platform-1]
      platformLifting = {
        ...platformLifting,
        day: action.day,
        session: action.session,
        flight: action.flight,
        lift: action.lift,
        // If the group changes, unset any overrides.
        overrideAttempt: null,
        overrideEntryId: null,
      }
      lifting[action.platform-1] = platformLifting
      return [
        ...lifting,
      ];
    }

    case "OVERRIDE_ATTEMPT":
    {
      let lifting = state.slice();
      let platformLifting = lifting[action.platform-1]
      platformLifting = {
        ...platformLifting,
        overrideAttempt: action.attempt,
      }
      lifting[action.platform-1] = platformLifting
      return [
        ...lifting,
      ];
    }

    case "OVERRIDE_ENTRY_ID":
    {
      let lifting = state.slice();
      let platformLifting = lifting[action.platform-1]
      platformLifting = {
        ...platformLifting,
        overrideEntryId: action.entryId,
      }
      lifting[action.platform-1] = platformLifting
      return [
        ...lifting,
      ];
    }

    case "OVERWRITE_STORE":
      return action.store.lifting;

    default:
      checkExhausted(action);
      return state;
  }
};
