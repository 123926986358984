import { AnyAction, Dispatch } from "redux";

type Subscriber = (action: AnyAction) => void;
export class ReduxStoreActionEmitter {
  private readonly _subscribers: Set<Subscriber>;
  constructor() {
    this._subscribers = new Set();
  }

  subscribe(subscriber: Subscriber) {
    this._subscribers.add(subscriber);
    return () => this._subscribers.delete(subscriber);
  }

  createReduxMiddleware() {
    const me = this;
    return function subscriberMiddleware() {
      return function (next: Dispatch<AnyAction>) {
        return function (action: { type: string }) {
          me._subscribers.forEach((subscriber) => subscriber(action));
          return next(action);
        };
      };
    };
  }
}
