// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2020 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { RecordsState, RegistrationState, MeetState } from "../../types/stateTypes";
import {
  Sex,
  Equipment,
  RecordLift,
  RecordType,
  Entry,
  Lift,
  Language,
  LiftingRecord,
  ImportedLiftingRecord,
  PotentialLiftingRecord,
  RecordAttempt,
} from "../../types/dataTypes";
import { calculateRecordKey, upsertRecord, clearBrokenRecords } from "../../reducers/recordsReducer";
import {
  getFinalTotalKg,
  getBest3SquatKg,
  getBest3BenchKg,
  getWeightClassForEntry,
  getAttemptWeight,
  getIPFAge,
  getBest5BenchKg,
  getBest5SquatKg,
  getBest5DeadliftKg,
} from "../entry";
import { checkExhausted } from "../../types/utils";
import { getString } from "../../logic/strings";

// Determines if the attempt would break any current records in the state.
export function wouldBreakConfirmedRecords(state: RecordsState, potentialRecord: PotentialLiftingRecord): RecordAttempt[] {
  const key = calculateRecordKey(potentialRecord);
  const breakRecords: RecordAttempt[] = [];
  
  state.recordNames.forEach((recordName: string) => {
    const confirmedRecord = state.recordSets[recordName][key];
    //console.log(confirmedRecord, potentialRecord.weight);
    // If there is a confirmed record for this type, return record name if our attempt is:
    //   larger
    //   larger or equal if the record is a standard
    //   larger if the record is zero
    if (confirmedRecord !== undefined) {
      if (potentialRecord.weight > confirmedRecord.weight || (confirmedRecord.fullName === 'Standard' && potentialRecord.weight >= confirmedRecord.weight) || (confirmedRecord.weight === 0)) {
        breakRecords.push({recordName: recordName, division: potentialRecord.division, attemptWeight: potentialRecord.weight, recordWeight: confirmedRecord.weight});
      }
    }
  });
  //console.log(breakRecords);
  return breakRecords;
}


// // Determines if the attempt would break the current record in the state.
// export function wouldBreakConfirmedRecord(state: RecordsState, potentialRecord: PotentialLiftingRecord): boolean {
//   const key = calculateRecordKey(potentialRecord);
//   const confirmedRecord = state.confirmedRecords[key];
//   
//   console.log(key, confirmedRecord);
//   
//   // If there is a confirmed record for this type, return true if our attempt is larger
//   if (confirmedRecord !== undefined) {
//     return potentialRecord.weight > confirmedRecord.weight;
//   } else {
//     // Otherwise, there is no record for this class. Turns out this most likely means someone has messed up and there isn't meant to be a record in this category.
//     // So, we do nothing and state it isn't a record.
//     return false;
//   }
// }

export function getRecordTypeForEntry(entry: Readonly<Entry>): RecordType {
  return entry.events[0] === "SBD" ? "FullPower" : "SingleLift";
}

export function getWeightForUnconfirmedRecord(recordLift: RecordLift, entry: Entry): number {
  switch (recordLift) {
    case "S":
      return getBest5SquatKg(entry);
    case "B":
      return getBest5BenchKg(entry);
    case "D":
      return getBest5DeadliftKg(entry);
    case "Total":
      return getFinalTotalKg(entry);
    default:
      checkExhausted(recordLift);
      return -1;
  }
}

// If they're lifting in sleeves, but wraps & sleeves are combined, upgrade the record to wraps as there won't be a seperate category
function getEquipmentForEntry(entry: Entry, meet: MeetState): Equipment {
  return meet.combineSleevesAndWraps && entry.equipment === "Sleeves" ? "Wraps" : entry.equipment;
}

export function getRecordAttempts(
  updatedRecordState: RecordsState,
  meet: MeetState,
  entry: Entry,
  recordLift: RecordLift,
  attemptOneIndexed: number,
  language: Language,
  equipment: Equipment,
  divisions: Array<string>
): RecordAttempt[] {
  const weightClass = getWeightClassForEntry(
    entry,
    meet.weightClassesKgMen,
    meet.weightClassesKgWomen,
    meet.weightClassesKgMx,
    language
  );

  if (!meet.recordsEnabled) {
    return [];
  }

  //console.log(updatedRecordState, recordsIsEmpty(updatedRecordState));
  
  if (recordsIsEmpty(updatedRecordState)) {
    return [];
  }
  
  // If they have no divisions, it cannot be a record attempt
  if (divisions.length === 0) {
    return [];
  }

  // If they can't set records, it cannot be a record attempt
  if (! entry.canBreakRecords) {
    return [];
  }
  
  // Work out if they're competing in a full power event, or single lift, wrt to records
  const recordType = getRecordTypeForEntry(entry);

  // Calculate the weight for the type of record requested
  let weight: number;
  if (recordLift === "Total") {
    const bestSquat = getBest3SquatKg(entry);
    const bestBench = getBest3BenchKg(entry);
    // Can't set a total record if you've bombed on bench or squats
    if (bestSquat === 0 || bestBench === 0) {
      return [];
    }
    // Total records only get announced during deadlifts, take the sum of the best S + B and the current attempted deadlift
    weight = bestSquat + bestBench + getAttemptWeight(entry, "D", attemptOneIndexed);
  } else {
    weight = getAttemptWeight(entry, recordLift, attemptOneIndexed);
  }

  let breakConfirmedRecords: RecordAttempt[] = [];

  divisions.forEach((division: string) => {

    const potentialRecord: PotentialLiftingRecord = {
      division: division,
      sex: entry.sex,
      weightClass,
      equipment,
      recordLift,
      recordType,
      weight,
    };
    const breakConfirmedDivisionRecords = wouldBreakConfirmedRecords(updatedRecordState, potentialRecord);
    breakConfirmedRecords.push(...breakConfirmedDivisionRecords);
    
  });

  if (breakConfirmedRecords.length > 0) {
    // Check to see if they've already bombed out. This prevents falsely advertising a bench/deadlift record if the participant has bombed out.
    // This assumes normal SBD order of lifts. If things are done out of order, the official records will still end up correct, but the on-screen notices won't work
    if (recordType === "FullPower") {
      // Cannot set a bench, deadlift or total record if you've bombed on squats
      if (recordLift !== "S" && getBest3SquatKg(entry) === 0) {
        breakConfirmedRecords = [];
      }
      // Cannot set a deadlift or total record if you've bombed on bench.
      if ((recordLift === "D" || recordLift === "Total") && getBest3BenchKg(entry) === 0) {
        breakConfirmedRecords = [];
      }
    }
  }
  
  return breakConfirmedRecords;
}

function getEquipmentDivisionOverrides(divisions: Array<string>): Array<string> {
  var divisionOverrides: Array<string> = [];
  divisions.forEach((division: string) => {
      const divisionOverride = getEquipmentDivisionOverride(division);
      if (divisionOverride !== division) {
        divisionOverrides.push(divisionOverride);
      }
  });
  return divisionOverrides;
}

function getEquipmentDivisionOverride(division: string): string {
  // Fixme: need a lookup table or something
  var newDivision = division.replace('FR-', 'F-');
  newDivision = newDivision.replace('MR-', 'M-');
  return newDivision;
}

export function getRecordAttemptsText(
  currentEntry: Entry,
  lift: Lift,
  updatedRecordState: RecordsState,
  meet: MeetState,
  attemptOneIndexed: number,
  language: Language,
  overrideEquipment?: boolean,
  overrideDivision?: boolean,
): string {

  var equipment = getEquipmentForEntry(currentEntry, meet);
  var divisions = currentEntry.divisions;
  const weightClass = getWeightClassForEntry(
    currentEntry,
    meet.weightClassesKgMen,
    meet.weightClassesKgWomen,
    meet.weightClassesKgMx,
    language
  );
  var extraText = "";
  
  if (typeof(overrideEquipment) !== 'undefined' && overrideEquipment === true) {
    if (currentEntry.equipment === "Sleeves") {
      equipment = 'Single-ply';
      extraText = "Also Equipped: ";
      divisions = getEquipmentDivisionOverrides(divisions);
    }
  }

  if (typeof(overrideDivision) !== 'undefined' && overrideDivision === true) {
    divisions = getDivisionRipple(divisions, weightClass, currentEntry.sex);
    extraText = "Also division(s): ";
  }
  
  if ((typeof(overrideEquipment) !== 'undefined' && overrideEquipment === true) &&
      (typeof(overrideDivision) !== 'undefined' && overrideDivision === true)) {
    extraText = "Also Equipped division(s): ";
  }
  
  const recordAttempts: string[] = [];

  const recordType = getRecordTypeForEntry(currentEntry);
  
  const liftRecordAttempts = getRecordAttempts(updatedRecordState, meet, currentEntry, lift, attemptOneIndexed, language, equipment, divisions);
  // Is it a record attempt for the lift?
  if (liftRecordAttempts.length > 0) {
    let localizedLift: string = "";
    if (lift === "S") localizedLift = getString("lifting.records-squat", language);
    else if (lift === "B") {
      localizedLift = getString("lifting.records-bench", language);
      // FIXME: hard coded!
      localizedLift = recordType === "SingleLift" ? "BENCH A/C" : localizedLift;
    }
    else if (lift === "D") localizedLift = getString("lifting.records-deadlift", language);
    else {
      checkExhausted(lift);
    }
    liftRecordAttempts.forEach((recordAttempt) => {
      recordAttempts.push(recordAttempt.recordName + " " + recordAttempt.division + " " + localizedLift + " (" + recordAttempt.attemptWeight + ")");
    });
  }

  // Total records are only announced during deadlifts in full power meets
  const canSetTotalRecords = getRecordTypeForEntry(currentEntry) === "FullPower" && lift === "D";
  const totalRecordAttempts = getRecordAttempts(updatedRecordState, meet, currentEntry, "Total", attemptOneIndexed, language, equipment, divisions);
  if (canSetTotalRecords && totalRecordAttempts.length > 0) {
    totalRecordAttempts.forEach((recordAttempt) => {
      const localizedLift = getString("lifting.records-total", language)
      recordAttempts.push(recordAttempt.recordName + " " + recordAttempt.division + " " + localizedLift + " (" + recordAttempt.attemptWeight + ")");
    });
  }

  // If any records are being attempted, set the text
  if (recordAttempts.length > 0) {
    const officialOrUnOfficial = currentEntry.canBreakRecords
//      ? getString("lifting.records-official", language)
      ? ""
      : getString("lifting.records-unofficial", language);
    var recordTypes = recordAttempts.join(" & ");
    if (extraText !== "") recordTypes = extraText + recordTypes;
    let messageTemplate = getString("lifting.records-attempt-1-record-notice", language).replace("{Lift}",recordTypes)
    messageTemplate = messageTemplate.replace("{OfficialOrUnofficial}", officialOrUnOfficial);
    return messageTemplate;
  }
  return "";
}

// FIXME: cloned and amended getRecordAttemptsText

export interface RecordAttemptData {
  recordName: string,
  recordDivision: string,
  recordLift: string,
  attemptWeight: number,
  recordWeight: number,
}

export function getRecordAttemptsList(
  currentEntry: Entry,
  lift: Lift,
  updatedRecordState: RecordsState,
  meet: MeetState,
  attemptOneIndexed: number,
  language: Language,
  overrideEquipment?: boolean,
  overrideDivision?: boolean,
): RecordAttemptData[] {

  var equipment = getEquipmentForEntry(currentEntry, meet);
  var divisions = currentEntry.divisions;
  const weightClass = getWeightClassForEntry(
    currentEntry,
    meet.weightClassesKgMen,
    meet.weightClassesKgWomen,
    meet.weightClassesKgMx,
    language
  );

  const recordAttempts: RecordAttemptData[] = [];

// British MR-O Deadlift (400) & English MR-O Deadlift (400) & British MR-J Deadlift (400) & English MR-J Deadlift (400) Record Attempt

  const recordType = getRecordTypeForEntry(currentEntry);
  
  const liftRecordAttempts = getRecordAttempts(updatedRecordState, meet, currentEntry, lift, attemptOneIndexed, language, equipment, divisions);
  // Is it a record attempt for the lift?
  if (liftRecordAttempts.length > 0) {
    let localizedLift: string = "";
    if (lift === "S") localizedLift = getString("lifting.records-squat", language);
    else if (lift === "B") {
      localizedLift = getString("lifting.records-bench", language);
      // FIXME: hard coded!
      localizedLift = recordType === "SingleLift" ? "Bench A/C" : localizedLift;
    }
    else if (lift === "D") localizedLift = getString("lifting.records-deadlift", language);
    else {
      checkExhausted(lift);
    }
    liftRecordAttempts.forEach((recordAttempt) => {
      recordAttempts.push({recordName: recordAttempt.recordName, recordDivision: recordAttempt.division, recordLift: localizedLift, attemptWeight: recordAttempt.attemptWeight, recordWeight: recordAttempt.recordWeight});
    });
  }

  // Total records are only announced during deadlifts in full power meets
  const canSetTotalRecords = getRecordTypeForEntry(currentEntry) === "FullPower" && lift === "D";
  const totalRecordAttempts = getRecordAttempts(updatedRecordState, meet, currentEntry, "Total", attemptOneIndexed, language, equipment, divisions);
  if (canSetTotalRecords && totalRecordAttempts.length > 0) {
    totalRecordAttempts.forEach((recordAttempt) => {
      const localizedLift = getString("lifting.records-total", language)
      recordAttempts.push({recordName: recordAttempt.recordName, recordDivision: recordAttempt.division, recordLift: localizedLift, attemptWeight: recordAttempt.attemptWeight, recordWeight: recordAttempt.recordWeight});
    });
  }

  return recordAttempts
}

// // Determines if the attempt is higher then any other successful attempt for the record type
// // This is used to determine if we should display the record attempt notification
// // NOTE: Does not consider if the entry is eligible to break records. This is useful so we can display official/unoffocial attempt info
// // NOTE: This assume that the RecordState being passed in is the most up to date version.
// // - Ensure you call getUpdatedState first.
// export function isRecordAttempt(
//   updatedRecordState: RecordsState,
//   meet: MeetState,
//   entry: Entry,
//   recordLift: RecordLift,
//   attemptOneIndexed: number,
//   language: Language
// ): boolean {
//   const weightClass = getWeightClassForEntry(
//     entry,
//     meet.weightClassesKgMen,
//     meet.weightClassesKgWomen,
//     meet.weightClassesKgMx,
//     language
//   );
// 
//   if (!meet.recordsEnabled) {
//     return false;
//   }
// 
//   // If they have no divisions, it cannot be a record attempt
//   if (entry.divisions.length === 0) {
//     return false;
//   }
// 
//   // Work out if they're competing in a full power event, or single lift, wrt to records
//   const recordType = getRecordTypeForEntry(entry);
// 
//   // Calculate the weight for the type of record requested
//   let weight: number;
//   if (recordLift === "Total") {
//     const bestSquat = getBest3SquatKg(entry);
//     const bestBench = getBest3BenchKg(entry);
//     // Can't set a total record if you've bombed on bench or squats
//     if (bestSquat === 0 || bestBench === 0) {
//       return false;
//     }
//     // Total records only get announced during deadlifts, take the sum of the best S + B and the current attempted deadlift
//     weight = bestSquat + bestBench + getAttemptWeight(entry, "D", attemptOneIndexed);
//   } else {
//     weight = getAttemptWeight(entry, recordLift, attemptOneIndexed);
//   }
// 
//   const potentialRecord: PotentialLiftingRecord = {
//     division: entry.divisions[0],
//     sex: entry.sex,
//     weightClass,
//     equipment: getEquipmentForEntry(entry, meet),
//     recordLift,
//     recordType,
//     recordName,
//     weight,
//   };
// 
//   if (wouldBreakConfirmedRecord(updatedRecordState, potentialRecord)) {
//     // Check to see if they've already bombed out. This prevents falsely advertising a bench/deadlift record if the participant has bombed out.
//     // This assumes normal SBD order of lifts. If things are done out of order, the official records will still end up correct, but the on-screen notices won't work
//     if (recordType === "FullPower") {
//       // Cannot set a bench, deadlift or total record if you've bombed on squats
//       if (recordLift !== "S" && getBest3SquatKg(entry) === 0) {
//         return false;
//       }
// 
//       // Cannot set a deadlift or total record if you've bombed on bench.
//       if ((recordLift === "D" || recordLift === "Total") && getBest3BenchKg(entry) === 0) {
//         return false;
//       }
//     }
// 
//     return true;
//   } else {
//     return false;
//   }
// }

function addPotentialRecordIfRelevant(
  event: RecordLift,
  potentialRecords: LiftingRecord[],
  basePotentialRecord: {
    date: string;
    fullName: string;
    location: string;
    division: string;
    equipment: Equipment;
    recordType: RecordType;
    sex: Sex;
    weightClass: string;
  },
  entry: Readonly<Entry>
): LiftingRecord | null {
  var addedPotentialRecord: LiftingRecord | null = null;
  if (event === "Total" || (entry.events.length > 0 && entry.events[0].indexOf(event) !== -1)) {
    const weightLifted = getWeightForUnconfirmedRecord(event, entry);
    // Ensure they've actually lifted something in this event
    if (weightLifted > 0) {
      addedPotentialRecord = {
        ...basePotentialRecord,
        recordLift: event,
        weight: weightLifted,
      }
      potentialRecords.push(addedPotentialRecord);
    }
  }
  return addedPotentialRecord;
}

type IpfAgeDivision = "Sub-Junior" | "Junior" | "Open" | "Master 1" | "Master 2" | "Master 3" | "Master 4";

function getQualiftingIpfDivisions(entry: Entry, meetDate: string): IpfAgeDivision[] {
  // Work out every single IPF division they qualify for based on their age
  const qualifyingAgeDivisions: IpfAgeDivision[] = ["Open"];
  const ipfAge = getIPFAge(entry, meetDate);
  // If age is 0, it means no data is available. In which case we won't try do any division smarts
  if (ipfAge > 0) {
    if (ipfAge < 19) {
      qualifyingAgeDivisions.push("Sub-Junior");
    }
    if (ipfAge < 24) {
      qualifyingAgeDivisions.push("Junior");
    }
    if (ipfAge > 39) {
      qualifyingAgeDivisions.push("Master 1");
    }
    if (ipfAge > 49) {
      qualifyingAgeDivisions.push("Master 2");
    }
    if (ipfAge > 59) {
      qualifyingAgeDivisions.push("Master 3");
    }
    if (ipfAge > 69) {
      qualifyingAgeDivisions.push("Master 4");
    }
  }

  return qualifyingAgeDivisions;
}

export function getDivisionName(division: string): IpfAgeDivision {
  const divisionParts = division.split('-')
  if (divisionParts.length !== 2) return 'Open'
  const divisionSuffix = divisionParts[1]
  switch (divisionSuffix) {
    case "SJ":
      return "Sub-Junior";
    case "J":
      return "Junior";
    case "O":
      return "Open";
    case "M1":
      return "Master 1";
    case "M2":
      return "Master 2";
    case "M3":
      return "Master 3";
    case "M4":
      return "Master 4";
  }
  return 'Open'
}

function getCustomPotentialRecords(
  potentialRecords: readonly LiftingRecord[],
  entry: Readonly<Entry>,
  meetDate: string
): LiftingRecord[] {
  // Create an array which will contain all the new potential records. Copy what we've already got
  const updatedPotentialRecords: LiftingRecord[] = [...potentialRecords];
  updatedPotentialRecords.forEach((potentialRecord) => console.log(potentialRecord))

  
  // we're entering all divisions in registration, so don't need this
//   const qualifyingAgeDivisions = getQualiftingIpfDivisions(entry, meetDate);
//   qualifyingAgeDivisions.forEach((qualifyingDiv) => {
//     potentialRecords.forEach((potentialRecord) => {
//       // Only add potential records for new divisions
//       if (potentialRecord.division !== qualifyingDiv) {
//         // For every div we qualify for, add a new potential record for every record we already qualify for (ie if its a 3 Lift, add SBD & Total records for the qualify div)
//         const divChange = { division: qualifyingDiv };
//         updatedPotentialRecords.push({ ...potentialRecord, ...divChange });
//       }
//     });
//   });

  
  return updatedPotentialRecords;
}

export function toLocaleDateString(
  meetDateString: string,
): string {
  const meetDate: Date = new Date(meetDateString);
  return meetDate.toLocaleDateString();
}
    
// Go over every entry & all their lifts and see if they break any records.
// If they do, update the records state with the new values
// Finally, return the updated state of the records.
// This is used to view the current pending records, and to confirm them prior to export
export function getUpdatedRecordState(
  initialRecordState: RecordsState,
  meet: MeetState,
  registrationState: RegistrationState,
  language: Language
): RecordsState {
  // If records aren't enabled, theres no work to be done. Return early
  if (!meet.recordsEnabled) {
    return initialRecordState;
  }
  if (recordsIsEmpty(initialRecordState)) {
    return initialRecordState;
  }
  let newRecordState: RecordsState = { ...initialRecordState };
  registrationState.entries.forEach((entry) => {
    const weightClass = getWeightClassForEntry(
      entry,
      meet.weightClassesKgMen,
      meet.weightClassesKgWomen,
      meet.weightClassesKgMx,
      language
    );
    const recordType: RecordType = getRecordTypeForEntry(entry);
    const equipment: Equipment = getEquipmentForEntry(entry, meet);
    
    // FIXME: separate out the non-generic ripples and uplifts (inc bench only) into Fed specific code
    const entryDivisions = entry.divisions
    const rippleDivisions = getDivisionRipple(entryDivisions, weightClass, entry.sex);
    const divisionsSet = new Set([...entryDivisions ,...rippleDivisions])
    const divisions = [...divisionsSet]
    const upliftDivisions = getEquipmentDivisionOverrides(divisions);
    
    // Can only set a record in a fullpower meet if you've set a total, or are on track to set one
    const canSetRecords = entry.canBreakRecords && (recordType !== "FullPower" || getFinalTotalKg(entry) > 0);
    if (canSetRecords && divisions.length > 0) {

      var potentialRecords: LiftingRecord[] = []
      divisions.forEach((division: string) => {
        const potentialDivisionRecords: LiftingRecord[] = getPotentialRecords(meet, entry, division, equipment, weightClass, recordType)
        potentialRecords.push(...potentialDivisionRecords)
      });
      
      upliftDivisions.forEach((division: string) => {
        const potentialUpliftDivisionRecords: LiftingRecord[] = getPotentialRecords(meet, entry, division, 'Single-ply', weightClass, recordType)
        potentialRecords.push(...potentialUpliftDivisionRecords)
      });
      
      // For all potential records, check if they break the existing imported record. If so, update the state with them
      // NOTE: This should be expanded to consider who achieved the record first.
      // To do that we probably need to do a two pass loop, firstly identifying lifts which break existing records and adding them to a list.
      // Then looping over the list and sorting out ties based on who achieved it first.
      // For now, I can just live with this limitation and sort it out later
      
      // clear down broken records
//      newRecordState = clearBrokenRecords(newRecordState);

      potentialRecords.forEach((potentialRecord) => {
        const breakRecords = wouldBreakConfirmedRecords(newRecordState, potentialRecord);
        breakRecords.forEach((recordAttempt) => {
          newRecordState = upsertRecord(potentialRecord, newRecordState, recordAttempt.recordName);
        });
      });
    }
  });

  return newRecordState;
}

function getPotentialRecords(
  meet: MeetState,
  entry: Entry,
  division: string,
  equipment: Equipment,
  weightClass: string,
  recordType: RecordType,
): LiftingRecord[] {

  let potentialRecords: LiftingRecord[] = [];

  const basePotentialRecord = {
    // FIXME: should really hold records internally in OL standard format
    date: toLocaleDateString(meet.date),
    fullName: entry.name,
    location: meet.name,
    division: division,
    equipment: equipment,
    recordType: recordType,
    sex: entry.sex,
    weightClass,
  };

  // Iterate through the various events and create a potential record if they've lifted in them
  addPotentialRecordIfRelevant("S", potentialRecords, basePotentialRecord, entry);
  const addedPotentialRecord: LiftingRecord | null = addPotentialRecordIfRelevant("B", potentialRecords, basePotentialRecord, entry);
  if (addedPotentialRecord !== null && recordType === "FullPower") {
    // Create a potential bench only record too
    const changeRecordType: { recordType: RecordType } = { recordType: "SingleLift" };
    potentialRecords.push({ ...addedPotentialRecord, ...changeRecordType });
  }
  addPotentialRecordIfRelevant("D", potentialRecords, basePotentialRecord, entry);
  if (recordType === "FullPower") {
    addPotentialRecordIfRelevant("Total", potentialRecords, basePotentialRecord, entry);
  }
  return potentialRecords;
}

// export interface RecordCategory {
//   name: string;
//   sex: Sex;
//   division: string;
//   weightClass: string;
//   equipment: Equipment;
//   lift: RecordLift;
// }
// 
// export interface RecordCategoryGrouping {
//   category: RecordCategory;
//   records: ImportedLiftingRecord[];
// }

// function makeCategory(record: LiftingRecord, recordName: string): RecordCategory {
//   const category: RecordCategory = {
//     name: recordName,
//     sex: record.sex,
//     division: record.division,
//     weightClass: record.weightClass,
//     equipment: record.equipment,
//     lift: record.lift,
//   };
//   return category;
// }
// 
// function makeKey(category: RecordCategory): string {
//   return `${category.sex}-${category.division}-${category.weightClass}-${category.equipment}`;
// }

function getLiftSortOrder(lift: RecordLift): number {
  if (lift === "S") return 0;
  if (lift === "B") return 1;
  if (lift === "D") return 2;
  if (lift === "Total") return 3;
  checkExhausted(lift);
  return -1;
}

function getSexSortOrder(sex: Sex): number {
  if (sex === "M") return 0;
  if (sex === "F") return 1;
  if (sex === "Mx") return 2;
  checkExhausted(sex);
  return -1;
}

function getRecordTypeSortOrder(recordType: RecordType): number {
  if (recordType === "FullPower") return 0;
  if (recordType === "SingleLift") return 1;
  checkExhausted(recordType);
  return -1;
}

function getEquipmentSortOrder(equipment: Equipment): number {
  if (equipment === "Bare") return 0;
  if (equipment === "Sleeves") return 1;
  if (equipment === "Wraps") return 2;
  if (equipment === "Single-ply") return 3;
  if (equipment === "Multi-ply") return 4;
  if (equipment === "Unlimited") return 5;
  checkExhausted(equipment);
  return -1;
}

function getWeightClassSortOrder(weightClassString: string): number {
  const isSHW = weightClassString.endsWith("+");
  if (isSHW) {
    return Number.POSITIVE_INFINITY;
  }

  const asNumber = Number(weightClassString.replace("+", ""));
  return asNumber;
}

function compareRecord(a: LiftingRecord, b: LiftingRecord, divisions: readonly string[]): number {
  if (a.equipment !== b.equipment) {
    return getEquipmentSortOrder(a.equipment) - getEquipmentSortOrder(b.equipment);
  }
  if (a.sex !== b.sex) {
    return getSexSortOrder(a.sex) - getSexSortOrder(b.sex);
  }
  if (a.division !== b.division) {
    return divisions.indexOf(a.division) - divisions.indexOf(b.division);
  }
  if (a.weightClass !== b.weightClass) {
    return getWeightClassSortOrder(a.weightClass) - getWeightClassSortOrder(b.weightClass);
  }
  if (a.recordType !== b.recordType) {
    return getRecordTypeSortOrder(a.recordType) - getRecordTypeSortOrder(b.recordType);
  }
  if (a.recordLift !== b.recordLift) {
    return getLiftSortOrder(a.recordLift) - getLiftSortOrder(b.recordLift);
  }

  return 0;
}

// function compareCategory(a: RecordCategory, b: RecordCategory, divisions: readonly string[], recordNames: readonly string[]): number {
//   if (a.name !== b.name) {
//     return recordNames.indexOf(a.name) - recordNames.indexOf(b.name);
//   }
//   if (a.sex !== b.sex) {
//     return getSexSortOrder(a.sex) - getSexSortOrder(b.sex);
//   }
//   if (a.equipment !== b.equipment) {
//     return getEquipmentSortOrder(a.equipment) - getEquipmentSortOrder(b.equipment);
//   }
//   if (a.division !== b.division) {
//     return divisions.indexOf(a.division) - divisions.indexOf(b.division);
//   }
//   if (a.weightClass !== b.weightClass) {
//     return getWeightClassSortOrder(a.weightClass) - getWeightClassSortOrder(b.weightClass);
//   }
//   if (a.lift !== b.lift) {
//     return getLiftSortOrder(a.lift) - getLiftSortOrder(b.lift);
//   }
// 
//   return 0;
// }
// 
// function compareRecords(a: LiftingRecord, b: LiftingRecord): number {
//   if (a.recordType !== b.recordType) {
//     return getRecordTypeSortOrder(a.recordType) - getRecordTypeSortOrder(b.recordType);
//   }
// 
//   if (a.recordLift !== b.recordLift) {
//     return getLiftSortOrder(a.recordLift) - getLiftSortOrder(b.recordLift);
//   }
// 
//   return 0;
// }

// Sorts records, leaving recordNames in the order they were imported in
export function sortRecords(
  records: RecordsState,
  meet: MeetState
): ImportedLiftingRecord[] {
  const sortedRecords: ImportedLiftingRecord[] = [];
  records.recordNames.forEach((recordName: string) => {
    const recordSet = Object.values(records.recordSets[recordName]);
    recordSet.sort((a, b) => compareRecord(a, b, meet.divisions));
    const importedRecordSet: ImportedLiftingRecord[] = recordSet.map((record) => {
      return Object.assign({}, record, {recordName: recordName});
    });
    sortedRecords.push(...importedRecordSet);
  });
  return sortedRecords;
}
 
// // Takes all records, groups them into categories and then sorts the categories. This effectively controls the order the records are rendered
// export function groupAndSortRecordsIntoCategories(
//   records: LiftingRecord[],
//   meetState: MeetState,
//   recordNames: string[],
// ): RecordCategoryGrouping[] {
//   // Group all records into categories of sex, division, class, equipment, recordType
//   const unSortedCategories = new Map<string, LiftingRecord[]>();
//   records.forEach((record) => {
//     const key = makeKey(record);
//     let recordsInCategory = unSortedCategories.get(key);
//     if (recordsInCategory === undefined) {
//       recordsInCategory = [];
//       unSortedCategories.set(key, recordsInCategory);
//     }
//     recordsInCategory.push(record);
//   });
// 
//   // Now that all categories have records, within each category sort the records based on their lift & Type (S,B,D,T order), Full Power first
//   for (const records of unSortedCategories.values()) {
//     records.sort((a, b) => compareRecords(a, b));
//   }
// 
//   // Ditch the map. Flatten it out into an array, which we'll sort
//   const categories: RecordCategoryGrouping[] = [];
//   unSortedCategories.forEach((records) => {
//     const category = makeCategory(records[0]);
//     categories.push({
//       category,
//       records,
//     });
//   });
// 
//   // Now sort the categories
//   categories.sort((a, b) => compareCategory(a.category, b.category, meetState.divisions, recordNames));
// 
//   return categories;
// }

export function recordsIsEmpty(recordState: RecordsState): boolean {
  // size of recordSets will be undefined (no array), 0 (empty), or the number of entries
  const numEntries = recordState.recordNames.length;
  if (numEntries === undefined || numEntries === 0) {
    return true;
  }
  return false;
}
  
export function getDivisionSortOrder(division: string): number {
  // FIXME: validation
  const divisionType = division.split("-")[1];
  if (divisionType === "SJ") return 0;
  if (divisionType === "J") return 1;
  if (divisionType === "O") return 2;
  if (divisionType === "M1") return 3;
  if (divisionType === "M2") return 4;
  if (divisionType === "M3") return 5;
  if (divisionType === "M4") return 6;
  return -1;
}

export function checkDivisionsJunior(divisions: Array<string>): boolean {
  let junior = false;
  divisions.forEach((division) => {
    const divisionType = division.split("-")[1];
    if (['SJ', 'J'].indexOf(divisionType) !== -1) {
      junior = true;
    }
  });
  return junior;
}

export function getDivisionJunior(divisions: Array<string>): string {
  let junior = "";
  divisions.forEach((division) => {
    const divisionType = division.split("-")[1];
    if (['SJ', 'J'].indexOf(divisionType) !== -1) {
      junior = division;
    }
  });
  return junior;
}

export function checkDivisionsMaster(divisions: Array<string>): boolean {
  let master = false;
  divisions.forEach((division) => {
    const divisionType = division.split("-")[1];
    if (['M1', 'M2', 'M3', 'M4'].indexOf(divisionType) !== -1) {
      master = true;
    }
  });
  return master;
}

export function getDivisionMaster(divisions: Array<string>): string {
  // assumes only one division is master
  let master = "";
  divisions.forEach((division) => {
    const divisionType = division.split("-")[1];
    if (['M1', 'M2', 'M3', 'M4'].indexOf(divisionType) !== -1) {
      master = division;
    }
  });
  return master;
}

export function getDivisionJuniorRipples(division: string, weightClass: string, sex: Sex): Array<string> {
  var ripples;
  if ((sex === "F" && weightClass === "43") || (sex === "M" && weightClass === "53")) {
    // there are no non-junior classes for the lowest M and F weight classes
    ripples = ["SJ", "J"];
  } else {
    ripples = ["SJ", "J", "O"];
  }
  const divisionType = division.split("-")[1];
  const position = ripples.indexOf(divisionType);
  return ripples.slice(position+1);
}

export function getDivisionMasterRipples(division: string): Array<string> {
  const ripples = ["M4", "M3", "M2", "M1", "O"];
  const divisionType = division.split("-")[1];
  const position = ripples.indexOf(divisionType);
  return ripples.slice(position+1);
}

export function getDivisionRipple(divisions: Array<string>, weightClass: string, sex: Sex): Array<string> {

  // note - this returns all rippled divisions including open, even if that is separately specified for an entry
  var divisionRipple: Array<string> = [];
  const divisionJunior = getDivisionJunior(divisions);
  const divisionMaster = getDivisionMaster(divisions);
  
  if (divisionJunior !== "") {
    const divisionPrefix = divisionJunior.split("-")[0];
    const divisionJuniorRipples = getDivisionJuniorRipples(divisionJunior, weightClass, sex);
    divisionJuniorRipples.forEach((ripple) => {
      divisionRipple.push(divisionPrefix + "-" + ripple);
    });
  }

  if (divisionMaster !== "") {
    const divisionPrefix = divisionMaster.split("-")[0];
    const divisionMasterRipples = getDivisionMasterRipples(divisionMaster);
    divisionMasterRipples.forEach((ripple) => {
      divisionRipple.push(divisionPrefix + "-" + ripple);
    });
  }
    
  return divisionRipple;
}  

export function compareDivisions(a: string, b: string): number {
  if (a !== b) {
    return getDivisionSortOrder(a) - getDivisionSortOrder(b);
  }
  return 0;
}

export function sortDivisions(divisions: string[]): void {
  divisions.sort((a, b) => compareDivisions(a, b));
}

export function compareWeightClasses(a: string, b: string): number {
  if (a !== b) {
    return getWeightClassSortOrder(a) - getWeightClassSortOrder(b);
  }
  return 0;
}

export function sortWeightClasses(weightClasses: string[]): void {
  weightClasses.sort((a, b) => compareWeightClasses(a, b));
}

function compareSexes(a: Sex, b: Sex): number {
  if (a !== b) {
    return getSexSortOrder(a) - getSexSortOrder(b);
  }
  return 0;
}

export function sortSexes(sexes: Sex[]): void {
  sexes.sort((a, b) => compareSexes(a, b));
}

function compareEquipment(a: Equipment, b: Equipment): number {
  if (a !== b) {
    return getEquipmentSortOrder(a) - getEquipmentSortOrder(b);
  }
  return 0;
}

export function sortEquipment(equipment: Equipment[]): void {
  equipment.sort((a, b) => compareEquipment(a, b));
}

