// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { newDefaultOrl } from "../logic/orl";
import { OrlAction, OverwriteStoreAction } from "../types/actionTypes";
import { OrlState } from "../types/stateTypes";
import { Orl } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

const initialState: OrlState = {
  // Object for ORL.
  // This owns all ORL information.
  inUse: false,
  visible: false,
  success: false,
  timer: 0,
};

type Action = OrlAction | OverwriteStoreAction;

export default (state: OrlState = initialState, action: Action): OrlState => {
  
  switch (action.type) {

    case "TOGGLE_ORL": {
      const inUse = action.inUse;
      return {
        ...state,
        inUse: inUse,
      };
    }

    case "UPDATE_ORL": {
      const visible = action.visible;
      const success = action.success;
      const timer = action.timer;
      return {
        ...state,
        visible: visible,
        success: success,
        timer: timer,
      };
    }

    case "START_ORL_TIMER": {
      return {
        ...state,
        timer: action.value,
      };
    }

    case "STOP_ORL_TIMER": {
      return {
        ...state,
        timer: action.value,
      };
    }

    case "UPDATE_ORL_TIMER": {
      return {
        ...state,
        timer: action.value,
      };
    }
    
    case "OVERWRITE_STORE": {
      // TODO: currently code to add an orl to the store if one doesn't exist
      if (action.store.orl) {
        return action.store.orl;
      } else {
        const orl = newDefaultOrl();
        return {
          inUse: orl.inUse,
          visible: orl.visible,
          success: orl.success,
          timer: orl.timer,
        };
      }
    }

    default:
      checkExhausted(action);
      return state;
  }
};
