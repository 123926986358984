import React from "react";
import { TextAlignProperty } from "csstype";

import styles from "./StreamOverlayView.module.scss";
import { getBackgroundColors, getTableData } from "../../logic/config";
import { GlobalState, MeetState } from "../../types/stateTypes";
import { connect } from "react-redux";

import "./ObsContainer.css"

export interface TableEntry {
  data: string,
  backgroundColor: BACKGROUNDCOLOR,
  changed?: boolean,
}

interface StateProps {
  meet: MeetState;
}

export interface DataTableProps {
  top: number;
  left: number;
  title: TableEntry;
  header: TableEntry[];
  data: Array<TableEntry[]>;
  widths: number[];
  aligns: string[];
  noToggle?: boolean;
}

type Props = StateProps & DataTableProps

export enum BACKGROUNDCOLOR {
  TITLE,
  HEADER,
  COUNT,
  NAME,
  ATTEMPT,
  GOODLIFT,
  NOLIFT,
  ATTEMPTLIFT,
  DEFAULT,
  HIGHLIGHT,
  GAP,
  IMPORTANT,
  INFORMATION,
  TRANSPARENT,
}

export function getTitleWidth(widths: number[], xGap: number) {
  let titleWidth = 0
  for (const width of widths) {
    titleWidth += width
  }
  titleWidth += xGap * (widths.length - 1)
  return titleWidth
}

class DataTable extends React.Component<Props> {

  getBackgroundColor(num: BACKGROUNDCOLOR, backgroundColors: any) {
    switch (num) {
      case BACKGROUNDCOLOR.TITLE:
        return backgroundColors.title
      case BACKGROUNDCOLOR.HEADER:
        return backgroundColors.header
      case BACKGROUNDCOLOR.COUNT:
        return backgroundColors.count
      case BACKGROUNDCOLOR.NAME:
        return backgroundColors.name
      case BACKGROUNDCOLOR.ATTEMPT:
        return backgroundColors.attempt
      case BACKGROUNDCOLOR.GOODLIFT:
        return backgroundColors.goodlift
      case BACKGROUNDCOLOR.NOLIFT:
        return backgroundColors.nolift
      case BACKGROUNDCOLOR.ATTEMPTLIFT:
        return backgroundColors.attemptlift
      case BACKGROUNDCOLOR.DEFAULT:
        return backgroundColors.default
      case BACKGROUNDCOLOR.HIGHLIGHT:
        return backgroundColors.highlight
      case BACKGROUNDCOLOR.GAP:
        return backgroundColors.gap
      case BACKGROUNDCOLOR.IMPORTANT:
        return backgroundColors.important
      case BACKGROUNDCOLOR.INFORMATION:
        return backgroundColors.information
      case BACKGROUNDCOLOR.TRANSPARENT:
        return ''
      default:
        return backgroundColors.default
    }
  }
  
  render() {

    const noToggle = this.props.noToggle !== undefined ? this.props.noToggle : false

    const backgroundColorSet = getBackgroundColors()
    let backgroundColors
    if (! noToggle && this.props.meet.toggleOverlayAlpha) {
      backgroundColors = backgroundColorSet.reducedBackgroundColors
    } else {
      backgroundColors = backgroundColorSet.normalBackgroundColors
    }
  
    const tableData = getTableData()
    const color = tableData.color
    const entryHeight = tableData.entryHeight
    const entryYGap = tableData.entryYGap
    const entryXGap = tableData.entryXGap
    const paddingLeft = tableData.paddingLeft
    const paddingRight = tableData.paddingRight
    const unit = tableData.unit
    const fontTitle = tableData.fontTitle
    const fontHeader = tableData.fontHeader
    const fontData = tableData.fontData
    const fontSize = tableData.fontSize
    const backgroundImageTitle = tableData.backgroundImageTitle
    const backgroundImageHeader = tableData.backgroundImageHeader
    const backgroundImageData = tableData.backgroundImageData
    const backgroundImageTitleUrl = backgroundImageTitle === '' ? '' : "url(./stream/" + backgroundImageTitle + ")"
    const backgroundImageHeaderUrl = backgroundImageHeader === '' ? '' : "url(./stream/" + backgroundImageHeader + ")"
    const backgroundImageDataUrl = backgroundImageData === '' ? '' : "url(./stream/" + backgroundImageData + ")"

    let table = null;
    let top = this.props.top
    let left = this.props.left

    let titleWidth = 0
    if (this.props.title.data.length > 0 && this.props.widths.length > 0) {
      titleWidth = getTitleWidth(this.props.widths, entryXGap)
    }

    const entries: JSX.Element[] = []

    if (titleWidth > 0) {
      entries.push(
        (
          <div style={{ display: "flex" , zIndex: 'inherit'}}>
            <span className={styles.LifterInfo} 
              style={{
                position: 'absolute',
                fontFamily: fontTitle,
                fontSize: fontSize + unit,
                textAlign: 'center' as TextAlignProperty, 
                top: top,
                left: left, 
                width: titleWidth + unit, 
                height: entryHeight + unit,
                color: color,
                backgroundImage: backgroundImageTitleUrl,
                backgroundColor: this.getBackgroundColor(this.props.title.backgroundColor, backgroundColors),
                paddingLeft: 0,
                paddingRight: 0,
                zIndex: 'inherit',
            }}>
              {this.props.title.data}
            </span>
          </div>
        )
      )
      top += entryHeight + entryYGap
    }
    
    if (this.props.header.length > 0) {
      let colLeft = left
      for (const [index, entry] of this.props.header.entries()) {
        const align = this.props.aligns[index]
        let padding
        let textOverflow = ''
        if (align === 'center') {
          padding = 0
        } else {
          if (align === 'left') {
            padding = paddingLeft
            textOverflow = 'ellipsis'
          } else {  // align === right
            padding = paddingRight
          }
        }
        entries.push(
          (
            <div style={{ display: "flex", zIndex: 'inherit'}}>
              <span className={styles.LifterInfo} 
                style={{
                  position: 'absolute',
                  fontFamily: fontHeader,
                  fontSize: fontSize + unit,
                  textAlign: align as TextAlignProperty, 
                  top: top,
                  left: colLeft,
                  width: this.props.widths[index] + unit,
                  height: entryHeight + unit,
                  color: color,
                  backgroundImage: backgroundImageHeaderUrl,
                  backgroundColor: this.getBackgroundColor(entry.backgroundColor, backgroundColors),
                  paddingLeft: padding + unit,
                  paddingRight: padding + unit,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: textOverflow,
                  zIndex: 'inherit',
                }}>
                {entry.data}
              </span>
            </div>
          )
        )

        colLeft += this.props.widths[index]

        if (index < this.props.header.length - 1 && entryXGap > 0) {
          entries.push(
            (
              <div style={{ display: "flex", zIndex: 'inherit'}}>
                <span className={styles.LifterInfo} 
                  style={{
                    position: 'absolute',
                    top: top,
                    left: colLeft,
                    width: entryXGap + unit,
                    height: entryHeight + unit,
                    backgroundImage: backgroundImageHeaderUrl,
                    backgroundColor: this.getBackgroundColor(BACKGROUNDCOLOR.GAP, backgroundColors),
                    zIndex: 'inherit',
                  }}>
                </span>
              </div>
            )
          )
          colLeft += entryXGap
        }
      }
      top += entryHeight + entryYGap
    }

    if (this.props.data.length > 0) {
      for (const rowEntry of this.props.data) {
        let colLeft = left
        for (const [index, colEntry] of rowEntry.entries()) {
          const align = this.props.aligns[index]
          let padding
          let textOverflow = ''
          if (align === 'center') {
            padding = 0
          } else {
            if (align === 'left') {
              padding = paddingLeft
              textOverflow = 'ellipsis'
            } else {  // align === right
              padding = paddingRight
            }
          }
          const classname = (colEntry.changed !== undefined && colEntry.changed === true) ? 'blinker' : ''
          entries.push(
            (
              <div className={classname} style={{ display: "flex", zIndex: 'inherit'}}>
                <span className={styles.LifterInfo} 
                  style={{
                    position: 'absolute',
                    fontFamily: fontData,
                    fontSize: fontSize + unit,
                    textAlign: align as TextAlignProperty, 
                    top: top,
                    left: colLeft,
                    width: this.props.widths[index] + unit,
                    height: entryHeight + unit,
                    color: color,
                    backgroundImage: backgroundImageDataUrl,
                    backgroundColor: this.getBackgroundColor(colEntry.backgroundColor, backgroundColors),
                    paddingLeft: padding + unit,
                    paddingRight: padding + unit,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: textOverflow,
                    zIndex: 'inherit',
                  }}>
                  {colEntry.data}
                </span>
              </div>
            )
          )

          colLeft += this.props.widths[index]

          if (index < rowEntry.length - 1 && entryXGap > 0) {
            entries.push(
              (
                <div style={{ display: "flex", zIndex: 'inherit'}}>
                  <span className={styles.LifterInfo} 
                    style={{
                      position: 'absolute',
                      top: top,
                      left: colLeft,
                      width: entryXGap + unit,
                      height: entryHeight + unit,
                      backgroundImage: backgroundImageDataUrl,
                      backgroundColor: this.getBackgroundColor(BACKGROUNDCOLOR.GAP, backgroundColors),
                      zIndex: 'inherit',
                    }}>
                  </span>
                </div>
              )
            )
            colLeft += entryXGap
          }

        }
        top += entryHeight + entryYGap
      }
    }

    table = (
      <>
        {entries}
      </>
    )
    
    return (
      table
    );

  }
}

const mapStateToProps = (state: GlobalState): StateProps => {
  return {
    meet: state.meet,
  };
};

export default connect(mapStateToProps)(DataTable);
