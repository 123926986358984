// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { localDateToIso8601 } from "../logic/date";
import { kg2lbs, lbs2kg, displayWeight } from "../logic/units";
import { PlateColors } from "../constants/plateColors";

import { MeetSetupAction, OverwriteStoreAction } from "../types/actionTypes";
import { Language, Plate } from "../types/dataTypes";
import { MeetState } from "../types/stateTypes";
import { checkExhausted } from "../types/utils";

const defaultPlatformsOnDay = 1;
const defaultSessionsOnDayOnPlatform = 1;

const defaultBarAndCollarsWeightKg = 25; // Assuming metal 2.5kg collars.
const defaultBarAndCollarsWeightLbs = 45; // Assuming plastic collars.

// Default kg plates, allowing for increments of 0.5kg.
const defaultPlatesKg: ReadonlyArray<Plate> = [
  { weightKg: 50, pairCount: 0, color: PlateColors.PLATE_DEFAULT_GREEN },
  { weightKg: 25, pairCount: 8, color: PlateColors.PLATE_DEFAULT_RED },
  { weightKg: 20, pairCount: 1, color: PlateColors.PLATE_DEFAULT_BLUE },
  { weightKg: 15, pairCount: 1, color: PlateColors.PLATE_DEFAULT_YELLOW },
  { weightKg: 10, pairCount: 1, color: PlateColors.PLATE_DEFAULT_GREEN },
  { weightKg: 5, pairCount: 1, color: PlateColors.PLATE_DEFAULT_BLACK },
  { weightKg: 2.5, pairCount: 1, color: PlateColors.PLATE_DEFAULT_BLACK },
  { weightKg: 2, pairCount: 0, color: PlateColors.PLATE_DEFAULT_BLUE },
  { weightKg: 1.5, pairCount: 0, color: PlateColors.PLATE_DEFAULT_YELLOW },
  { weightKg: 1.25, pairCount: 1, color: PlateColors.PLATE_DEFAULT_BLACK },
  { weightKg: 1, pairCount: 1, color: PlateColors.PLATE_DEFAULT_BLUE },
  { weightKg: 0.75, pairCount: 1, color: PlateColors.PLATE_DEFAULT_RED },
  { weightKg: 0.5, pairCount: 1, color: PlateColors.PLATE_DEFAULT_GREEN },
  { weightKg: 0.25, pairCount: 1, color: PlateColors.PLATE_DEFAULT_BLUE },
];

// Default lbs plates, allowing for increments of 1lb.
const defaultPlatesLbs: ReadonlyArray<Plate> = [
  { weightKg: lbs2kg(100), pairCount: 0, color: PlateColors.PLATE_DEFAULT_GREEN },
  { weightKg: lbs2kg(55), pairCount: 0, color: PlateColors.PLATE_DEFAULT_RED },
  { weightKg: lbs2kg(45), pairCount: 8, color: PlateColors.PLATE_DEFAULT_GRAY },
  { weightKg: lbs2kg(35), pairCount: 0, color: PlateColors.PLATE_DEFAULT_GRAY },
  { weightKg: lbs2kg(25), pairCount: 1, color: PlateColors.PLATE_DEFAULT_GRAY },
  { weightKg: lbs2kg(10), pairCount: 2, color: PlateColors.PLATE_DEFAULT_GRAY },
  { weightKg: lbs2kg(5), pairCount: 1, color: PlateColors.PLATE_DEFAULT_GRAY },
  { weightKg: lbs2kg(2.5), pairCount: 1, color: PlateColors.PLATE_DEFAULT_GRAY },
  { weightKg: lbs2kg(1.25), pairCount: 1, color: PlateColors.PLATE_DEFAULT_GRAY },
  { weightKg: lbs2kg(0.5), pairCount: 2, color: PlateColors.PLATE_DEFAULT_GRAY },
];

const initialState: MeetState = {
  // Sanction information.
  name: "",
  country: "",
  state: "",
  city: "",
  federation: "",
  date: localDateToIso8601(new Date()),
  lengthDays: 1,
  platformsOnDays: [defaultPlatformsOnDay],
  sessionsOnDaysOnPlatforms: [[defaultSessionsOnDayOnPlatform]],

  // Competition Rules.
  divisions: [],
  weightClassesKgMen: [],
  weightClassesKgWomen: [],
  weightClassesKgMx: [],
  formula: "Wilks",
  ageCoefficients: "FosterMcCulloch",
  combineSleevesAndWraps: false,
  combineSingleAndMulti: false,
  allow4thAttempts: true,
  recordsEnabled: true,

  // Weights and Loading Setup.
  inKg: true,
  showAlternateUnits: true,
  squatBarAndCollarsWeightKg: defaultBarAndCollarsWeightKg,
  benchBarAndCollarsWeightKg: defaultBarAndCollarsWeightKg,
  deadliftBarAndCollarsWeightKg: defaultBarAndCollarsWeightKg,
  plates: defaultPlatesKg,
  
  // Miscellaneous.
  columnDivisionWidthPx: 90,

  // stream colours
  titleBackgroundColor: "rgba(100,0,0,0.3)",
  headerBackgroundColor: "rgba(100,100,100,0.3)",
  backgroundColor: "rgba(100,0,0,0.3)",
  highlightBackgroundColor: "rgba(0,200,0,0.3)",
  titleTextColor: "white",
  headerTextColor: "white",
  textColor: "white",
  goodliftTextColor: "green",
  noliftTextColor: "red",
  fullscreenWidth: 1820,
  fullscreenHeight: 980,
  panelledWidth: 1520,
  panelledHeight: 880,
  toggleOverlayAlpha: false,

};

// Given a sorted list of weight classes (in kg) and a bodyweight (in kg),
// return a string describing the weight class.
export const getWeightClassStr = (
  classes: ReadonlyArray<number>,
  bodyweightKg: number,
  language?: Language
): string => {
  if (bodyweightKg === 0) return "";
  if (classes.length === 0) return "";

  for (let i = 0; i < classes.length; i++) {
    if (bodyweightKg <= classes[i]) {
      return displayWeight(classes[i], language);
    }
  }
  return displayWeight(classes[classes.length - 1], language) + "+";
};

// Converts a kg weightclass to pounds.
//
// For example, the 90kg class is technically 198.41lbs,
// but this will return "198".
export const wtclsStrKg2Lbs = (kgcls: string): string => {
  const shw: boolean = kgcls.endsWith("+");
  const asNumber = Number(kgcls.replace("+", ""));

  // Convert to pounds and round down.
  let truncated = Math.floor(kg2lbs(asNumber));

  // This works for everything but the 183 class, which
  // rounds down to 182.
  if (truncated === 182) {
    truncated = 183;
  }

  return shw ? String(truncated) + "+" : String(truncated);
};

// Given a sorted list of weight classes (in kg) and a bodyweight (in kg),
// return a string describing the weight class.
//
// This is a separate method because it turns out that many exact translations
// of kilo values are not what the audience expects for traditionally-reported
// pounds classes. So a bunch of rounding must occur.
export const getWeightClassLbsStr = (classes: ReadonlyArray<number>, bodyweightKg: number): string => {
  if (bodyweightKg === 0) return "";
  if (classes.length === 0) return "";

  for (let i = 0; i < classes.length; i++) {
    if (bodyweightKg <= classes[i]) {
      return wtclsStrKg2Lbs(String(classes[i]));
    }
  }
  return wtclsStrKg2Lbs(String(classes[classes.length - 1])) + "+";
};

type Action = MeetSetupAction | OverwriteStoreAction;

export default (state: MeetState = initialState, action: Action): MeetState => {
  switch (action.type) {
    case "SET_MEET_NAME":
      return { ...state, name: action.name };

    case "SET_FORMULA":
      return { ...state, formula: action.formula };

    case "SET_FEDERATION":
      return { ...state, federation: action.federation };

    case "SET_DIVISIONS":
      return { ...state, divisions: action.divisions };

    case "SET_MEET_DATE":
      return { ...state, date: action.date };

    case "SET_LENGTH_DAYS": {
      const numDays = Number(action.length);

      let newPlatformsOnDays: Array<number> = [];
      if (numDays > state.platformsOnDays.length) {
        const diff = numDays - state.platformsOnDays.length;
        newPlatformsOnDays = state.platformsOnDays.slice();
        for (let i = 0; i < diff; i++) {
          newPlatformsOnDays.push(defaultPlatformsOnDay);
        }
      } else if (numDays < state.platformsOnDays.length) {
        newPlatformsOnDays = state.platformsOnDays.slice(0, numDays);
      }

      let newSessionsOnDaysOnPlatforms: Array<Array<number>> = [];
      if (numDays > state.sessionsOnDaysOnPlatforms.length) {
        const diff = numDays - state.sessionsOnDaysOnPlatforms.length;
        newSessionsOnDaysOnPlatforms = state.sessionsOnDaysOnPlatforms.slice();
        for (let i = 0; i < diff; i++) {
          newSessionsOnDaysOnPlatforms.push([defaultSessionsOnDayOnPlatform]);
        }
      } else if (numDays < state.sessionsOnDaysOnPlatforms.length) {
        newSessionsOnDaysOnPlatforms = state.sessionsOnDaysOnPlatforms.slice(0, numDays);
      }

      if (newPlatformsOnDays.length > 0 && newSessionsOnDaysOnPlatforms.length > 0) {
        return { ...state, lengthDays: numDays, platformsOnDays: newPlatformsOnDays, sessionsOnDaysOnPlatforms: newSessionsOnDaysOnPlatforms };
      } else if (newPlatformsOnDays.length > 0) {
        return { ...state, lengthDays: numDays, platformsOnDays: newPlatformsOnDays };
      } else if (newSessionsOnDaysOnPlatforms.length > 0) {
        return { ...state, lengthDays: numDays, sessionsOnDaysOnPlatforms: newSessionsOnDaysOnPlatforms };
      }
      return { ...state, lengthDays: numDays };
    }

    case "SET_PLATFORM_COUNT": {
      const day = Number(action.day);
      const count = Number(action.count);

      const newPlatformsOnDays: Array<number> = state.platformsOnDays.slice();
      newPlatformsOnDays[day - 1] = count;

      let newSessionsOnDaysOnPlatforms: Array<Array<number>> = [];
      if (count > state.sessionsOnDaysOnPlatforms[day - 1].length) {
        const diff = count - state.sessionsOnDaysOnPlatforms[day - 1].length;
        newSessionsOnDaysOnPlatforms = state.sessionsOnDaysOnPlatforms.slice();
        const newSessions = newSessionsOnDaysOnPlatforms[day - 1];
        for (let i = 0; i < diff; i++) {
          newSessions.push(defaultSessionsOnDayOnPlatform);
        }
      } else if (count < state.sessionsOnDaysOnPlatforms[day - 1].length) {
        newSessionsOnDaysOnPlatforms = state.sessionsOnDaysOnPlatforms.slice();
        const newSessions = newSessionsOnDaysOnPlatforms[day - 1];
        newSessions.length = count;
      }

      if (newSessionsOnDaysOnPlatforms.length > 0) {
        return { ...state, platformsOnDays: newPlatformsOnDays, sessionsOnDaysOnPlatforms: newSessionsOnDaysOnPlatforms };
      }
      return { ...state, platformsOnDays: newPlatformsOnDays };
    }

    case "SET_SESSION_COUNT": {
      const day = Number(action.day);
      const platform = Number(action.platform);
      const count = Number(action.count);

      const newSessionsOnDaysOnPlatforms: Array<Array<number>> = state.sessionsOnDaysOnPlatforms.slice();
      newSessionsOnDaysOnPlatforms[day - 1][platform - 1] = count;
      return { ...state, sessionsOnDaysOnPlatforms: newSessionsOnDaysOnPlatforms };
    }

    case "SET_IN_KG": {
      // Changing the units also changes the loading, so re-initialize from defaults.
      const defaultPlates = action.inKg ? defaultPlatesKg : defaultPlatesLbs;
      const defaultBar = action.inKg ? defaultBarAndCollarsWeightKg : lbs2kg(defaultBarAndCollarsWeightLbs);
      return {
        ...state,
        inKg: action.inKg,
        plates: defaultPlates,
        squatBarAndCollarsWeightKg: defaultBar,
        benchBarAndCollarsWeightKg: defaultBar,
        deadliftBarAndCollarsWeightKg: defaultBar,
      };
    }

    case "SET_WEIGHTCLASSES": {
      const sex = action.sex;
      const classesKg = action.classesKg;
      switch (sex) {
        case "M":
          return { ...state, weightClassesKgMen: classesKg };
        case "F":
          return { ...state, weightClassesKgWomen: classesKg };
        case "Mx":
          return { ...state, weightClassesKgMx: classesKg };
        default:
          checkExhausted(sex);
          return state;
      }
    }

    case "SET_BAR_AND_COLLARS_WEIGHT_KG": {
      switch (action.lift) {
        case "S":
          return { ...state, squatBarAndCollarsWeightKg: action.weightKg };
        case "B":
          return { ...state, benchBarAndCollarsWeightKg: action.weightKg };
        case "D":
          return { ...state, deadliftBarAndCollarsWeightKg: action.weightKg };
        default:
          checkExhausted(action.lift);
          return state;
      }
    }

    case "SET_PLATE_CONFIG": {
      const { weightKg, pairCount, color } = action;

      // Find the index of the object in the platesOnSide array by comparing weights.
      const index = state.plates.findIndex((p) => p.weightKg === weightKg);

      // Clone the array.
      const newPlates: Array<Plate> = state.plates.slice();

      // Replace with a new object in the new array.
      newPlates[index] = { weightKg, pairCount, color };

      return { ...state, plates: newPlates };
    }

    case "SET_DIVISION_COLUMN_WIDTH":
      return { ...state, columnDivisionWidthPx: action.width };

    case "UPDATE_MEET": {
      const changes = action.changes;

      // Make a new MeetState with just the changes overwritten.
      const newState = Object.assign({}, state);
      return Object.assign(newState, changes);
    }

    case "UPDATE_BROWSERDATA": {
      console.log('meetReducer', action)
      const browserData = action.data;
      return { 
        ...state, 
        titleBackgroundColor: browserData.titleBackgroundColor,
        headerBackgroundColor: browserData.headerBackgroundColor,
        backgroundColor: browserData.backgroundColor,
        highlightBackgroundColor: browserData.highlightBackgroundColor,
        titleTextColor: browserData.titleTextColor,
        headerTextColor: browserData.headerTextColor,
        textColor: browserData.textColor,
        goodliftTextColor: browserData.goodliftTextColor,
        noliftTextColor: browserData.noliftTextColor,
        fullscreenWidth: browserData.fullscreenWidth,
        fullscreenHeight: browserData.fullscreenHeight,
        panelledWidth: browserData.panelledWidth,
        panelledHeight: browserData.panelledHeight,
        toggleOverlayAlpha: browserData.toggleOverlayAlpha,
      };
    }

    case "OVERWRITE_STORE":
      return action.store.meet;

    default:
      checkExhausted(action);
      return state;
  }
};
