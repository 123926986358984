// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Defines logic for creating and working with Drl objects.

import React from 'react';

import { config } from '../logic/config';

//
// server.json contains
//   a list of server names to select from, with hostname details
//   a set of modes for REACT_APP_MODES, giving server details for each one
let globalServerData = require('./server.json');
const servers = globalServerData.servers;

// when running as a client the config data won't be loaded when this module is imported, so get the config dynamically
function getAppMode() {
  // from a client, appMode will be set in config (after load), for a server it's in the environment
  const appMode = config.appMode ? config.appMode : process.env.REACT_APP_MODE;
  return appMode;
}

function getServerData() {
  const appMode = getAppMode();
  return globalServerData.modes[appMode];
}

export interface SelectOption {
  value: string;
  label: string;
}

export const getServernameSelectOptions = (): Array<SelectOption> => {
  let selectOptions: Array<SelectOption> = [];
  for (let server of servers) {
    const selectOption: SelectOption = { value: server.hostname, label: server.servername };
    selectOptions.push(selectOption);
  }
  
  console.log(selectOptions)
  
  return selectOptions;
}

export const getServername = (): string => {
  for (let server of servers) {
    // HACK - return first
    return server.hostname
  }
  return "";
}

export function getOperationMode(): string {
  const serverData = getServerData();
  if (serverData) {
    return serverData.operationMode;
  } else {
    console.log('operationMode not set, exiting');
    process.exit(1);
  }
}

export function getServerUrl(hostname: string): string {
  const hostnameDetails = getHostnameDetails(hostname);
  let url: string = "";
  if (hostnameDetails.operationMode === "primary") {
    url = getPrimaryUrl(hostnameDetails.hostname);
  } else if (hostnameDetails.operationMode === "relay") {
    url = getRelayUrl(hostnameDetails.hostname);
  }
  return url;
}

export function getHostnameDetails(hostname: string) {
  const hostnameFacets = hostname.split(',');
  return {
    operationMode: hostnameFacets[0],
    hostname: hostnameFacets[1],
  }
}

export function getPrimaryPort(): string {
  return getServerData().primaryHttpPort;
}

export function getRelayPort(): string {
  return getServerData().relayHttpPort;
}

export function getPrimaryUrl(hostname: string): string {
  return "https://" + hostname + ":" + getPrimaryPort();
}

export function getRelayUrl(hostname: string): string {
  return "https://" + hostname + ":" + getRelayPort();
}

export function getPrimaryUrlNoPort(hostname: string): string {
  return "https://" + hostname;
}



