// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Defines logic for creating and working with Stream objects.

import { LiftingState, RegistrationState, MeetState } from "../types/stateTypes";
import { Entry, Event, LiftStatus, Lift, Language, DivisionEventRanking } from "../types/dataTypes";
import { getEntriesInFlight, getLiftingOrder } from "../logic/liftingOrder";
import {
  getWeightClassForEntry,
  liftToAttemptFieldName,
  liftToStatusFieldName,
  getBestForLift,
} from "../logic/entry";
import { getPotentialRankings } from "../logic/divisionPlace";
import { localizeEquipment } from "../logic/strings";

import { Image, Text, Box, Screen, ScreenPosition, ScreenOffset, Drl, ConvertedDrl, Stream, LifterStreamData, LifterStreamInfo } from "../types/dataTypes";
import { newDrl, newScreenOffset, getDrlScreenHeight, getDrlScreenWidth } from "./drl"

export const newDefaultLifterStreamInfo = (): LifterStreamInfo => {
  return {
    entryId: 0,
    lift: "",
    name: "",
    lot: 0,
    bodyWeightKg: 0,
    weightClass: "",
    divisions: [],
    events: [],
    equipment: "",
    guest: false,
    insta: "",
    attemptOneIndexed: 0,
  };
};

export const newDefaultLifterData = (): LifterStreamData => {
  const lifterStreamInfo = newDefaultLifterStreamInfo();
  return {
    lifterInfo: lifterStreamInfo,
    lifts: [0, 0, 0, 0, 0],
    liftResults: [0, 0, 0, 0, 0],
    bestForLift: 0,
    divisionEventRankings: [],
  };
};

export const newStream = (
    lifterVisible: boolean,
    loaded: boolean,
    attempted: boolean,
    lifterData: LifterStreamData,
    reloadConfig: number,
): Stream => {
  return {
    lifterVisible: lifterVisible,
    loaded: loaded,
    attempted: attempted,
    lifterData: lifterData,
    reloadConfig: reloadConfig,
  };
};

export const createLifterStreamInfo = (
  entryId: number,
  lift: string,
  name: string,
  lot: number,
  bodyWeightKg: number,
  weightClass: string,
  divisions: string[],
  events: Array<Event>,
  equipment: string,
  guest: boolean,
  insta: string,
  attempt: number,
): LifterStreamInfo => {
  return {
    entryId: entryId,
    lift: lift,
    name: name,
    lot: lot,
    bodyWeightKg: bodyWeightKg,
    weightClass: weightClass,
    divisions: divisions,
    events: events,
    equipment: equipment,
    guest: guest,
    insta: insta,
    attemptOneIndexed: attempt,
  };
};

export const getUnscaledScreenOffset = (screenOffset: ScreenOffset, scale: number): ScreenOffset => {
  // return screen position sized so when scaled later it is returns to passed value
  const top = screenOffset.top / scale;
  const left = screenOffset.left / scale;
  return {
    top: top,
    left: left,
  };
};

export const getItemsInComponents = (componentIds: Array<string>, drl: Drl): Drl => {
  // get items in DRL components that match a set of component IDs 
  // so that a portion of the DRL screen can be displayed
  const images = drl.images.filter((image) => componentIds.includes(image.componentId));
  const texts = drl.texts.filter((text) => componentIds.includes(text.componentId));
  const boxes = drl.boxes.filter((box) => componentIds.includes(box.componentId));
  const screen = drl.screen;
  const drlItems = newDrl(images, texts, boxes, screen);
  return drlItems;
};
 
export const getOffsetForItems = (drl: Drl): ScreenOffset => {
  // get min top and left across all elements in a passed DRL so that
  // they can be displayed at the passed position but keep their offsets to each other
  // (the DRL is likely a subset of items from getItemsInComponents)
  const minImageTop = Math.min.apply(Math, drl.images.map(function(image) { return image.top; }));
  const minImageLeft = Math.min.apply(Math, drl.images.map(function(image) { return image.left; }));
  const minTextTop = Math.min.apply(Math, drl.texts.map(function(text) { return text.top; }));
  const minTextLeft = Math.min.apply(Math, drl.texts.map(function(text) { return text.left; }));
  const minBoxTop = Math.min.apply(Math, drl.boxes.map(function(box) { return box.top; }));
  const minBoxLeft = Math.min.apply(Math, drl.boxes.map(function(box) { return box.left; }));
  const minTop = Math.min(minImageTop, minTextTop, minBoxTop);
  const minLeft = Math.min(minImageLeft, minTextLeft, minBoxLeft);
  const screenOffset = newScreenOffset(minTop, minLeft);
  return screenOffset;
};

export const getMovedItems = (drl: Drl, drlScreenOffset: ScreenOffset, unscaledScreenOffset: ScreenOffset): Drl => {
  // get an updated set of Drl items that have had their top/left converted
  // from DRL to stream values
  if (typeof drlScreenOffset === 'undefined') {
    return drl;
  };
  const images: Array<Image> = [];
  drl.images.forEach( image => {
    const newImage = { ...image };
    newImage.left = image.left - drlScreenOffset.left + unscaledScreenOffset.left;
    newImage.top = image.top - drlScreenOffset.top + unscaledScreenOffset.top;
    images.push(newImage);
  });
  const texts: Array<Text> = [];
  drl.texts.forEach( text => {
    const newText = { ...text };
    newText.left = text.left - drlScreenOffset.left + unscaledScreenOffset.left;
    newText.top = text.top - drlScreenOffset.top + unscaledScreenOffset.top;
    texts.push(newText);
  });
  const boxes: Array<Box> = [];
  drl.boxes.forEach( box => {
    const newBox = { ...box };
    newBox.left = box.left - drlScreenOffset.left + unscaledScreenOffset.left;
    newBox.top = box.top - drlScreenOffset.top + unscaledScreenOffset.top;
    boxes.push(newBox);
  });
  const screen = drl.screen;
  const drlItems = newDrl(images, texts, boxes, screen);
  return drlItems;
};

export const getConvertedDrl = (drl: Drl, componentIds: Array<string>, screenPosition: ScreenPosition): ConvertedDrl => {
  // get the set of items for this container
  const containerDrl = getItemsInComponents(componentIds, drl);
  // get their relative screen offset
  const drlScreenOffset = getOffsetForItems(containerDrl);
  // get their scale factor
  const drlScreenHeight = getDrlScreenHeight(drl);
  const drlScreenWidth = getDrlScreenWidth(drl);
  const scaley = screenPosition.height / drlScreenHeight;
  const scalex = screenPosition.width / drlScreenWidth;
  const scale = Math.min(scalex, scaley);
  // get their unscaled target position
  const screenOffset = newScreenOffset(screenPosition.top, screenPosition.left);
  const unscaledScreenOffset = getUnscaledScreenOffset(screenOffset, scale);
  // update their screen postions
  const movedContainerDrl = getMovedItems(containerDrl, drlScreenOffset, unscaledScreenOffset);
  return {
    convertedDrl: movedContainerDrl,
    scale: scale,
  };
};

export const getLifterStreamData = (
  lifting: LiftingState,
  registration: RegistrationState,
  meet: MeetState,
  language: Language,
  ): LifterStreamData | null => {

    // get the info to display for a lifter on the stream overlay
    // note that we do this as a snapshot so we don't lose the info after the result is actioned
    // (so we can continue to display the lifter with his lights until they are toggled)
    // we call this when UPDATE_BARLOADED is received (to get the initial state)

    const entriesInFlight = getEntriesInFlight(lifting.day, lifting.session, lifting.platform, lifting.flight, registration.entries);
    const now = getLiftingOrder(entriesInFlight, lifting);

    // If there is no lifter, don't return anything
    if (now.currentEntryId === null) {
      return null;
    }

    const entryIndex = registration.lookup[now.currentEntryId];
    const entry = registration.entries[entryIndex];
    const lift = lifting.lift;

    const fieldKg = liftToAttemptFieldName(lift);
    const fieldResults = liftToStatusFieldName(lift);

    const lifts = entry[fieldKg];
    const liftResults = entry[fieldResults];

    const bestForLift = getBestForLift(entry, lift);

    const weightClass = getWeightClassForEntry(
      entry,
      meet.weightClassesKgMen,
      meet.weightClassesKgWomen,
      meet.weightClassesKgMx,
      language
    );

    const divisions = entry.divisions.length > 0 ? entry.divisions.join(", ") : "";
    const equipment = localizeEquipment(entry.equipment, language);
    const guest = entry.guest;
    const insta = (typeof entry.instagram === "string" && entry.instagram !== "") ? ("@" + entry.instagram) : "";
    
    const lifterInfo = createLifterStreamInfo(
      now.currentEntryId,
      lift,
      entry.name,
      entry.lot,
      entry.bodyweightKg,
      weightClass,
      entry.divisions,
      entry.events,
      equipment,
      guest,
      insta,
      now.attemptOneIndexed,
    );

    // FIXME: need to cater for other event types (than SBD)
    let divisionEventRankings: DivisionEventRanking[] = [];
    if (lift === "D" && now.attemptOneIndexed > 1) {
      divisionEventRankings = getPotentialRankings(
        entry,
        lift,
        now.attemptOneIndexed,
        registration.entries,
        meet,
        language,
      );
    }
    
    return {
      lifterInfo: lifterInfo,
      lifts: lifts,
      liftResults: liftResults,
      bestForLift: bestForLift,
      divisionEventRankings: divisionEventRankings,
    };
}

export const getBest3LiftKg = (lifts: Array<number>, liftResults: Array<number>): number => {
  let best3 = 0.0;
  if (liftResults[0] > 0) best3 = Math.max(best3, lifts[0]);
  if (liftResults[1] > 0) best3 = Math.max(best3, lifts[1]);
  if (liftResults[2] > 0) best3 = Math.max(best3, lifts[2]);
  return best3;
};
