import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ConfigLoader from './ConfigLoader';
import App from '../App';

class Config extends Component {
  render() {
    // When the configuration is loaded, render the App module
    return <ConfigLoader ready={() => <App />} />;
  }
}

ReactDOM.render(<Config />, document.getElementById('root'));

export default Config;
