// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Defines the logic for calculating the division Place of a lifter, shared between
// the Lifting page, the Rankings page, and data export code.
//
// The algorithm used is particularly bad -- the foremost goal was to make an interface
// that allowed for maximum code reuse between the Rankings and Lifting pages,
// which have slightly different needs.

import { getFinalEventTotalKg, getFinalTotalKg, getPrognosisTotalKg, liftToStatusFieldName } from "./entry";

// Import points formulas.
import { getAgeAdjustedPoints } from "./coefficients/coefficients";

// Import age coefficients.
import { checkExhausted } from "../types/utils";
import { AgeCoefficients, Sex, Event, Equipment, Entry, Formula, PointsEventRanking } from "../types/dataTypes";
import { Lift, Language } from "../types/dataTypes";
import { MeetState } from "../types/stateTypes";
import { goodlift } from "./coefficients/goodlift";
import { getWeightClassStr } from "../reducers/meetReducer";
import { getEnabledCategories } from "trace_events";

// Specifies a points category under which entries can be ranked together.
export type PointsCategory = {
  sex: Sex;
  event: Event;
  equipment: Equipment;
};

// Wraps up all the entries in a category with the category's descriptors.
export type PointsCategoryResults = {
  category: PointsCategory;
  orderedEntries: Array<Entry>;
};

// Generates a unique String out of a Category, for purposes of using as a Map key.
const categoryToKey = (category: PointsCategory): string => {
  return JSON.stringify(category);
};
const keyToCategory = (key: string): PointsCategory => {
  return JSON.parse(key);
};

// Returns a copy of the entries array sorted by Formula Place (Rank).
// All entries are assumed to be part of the same category.
const sortByFormulaPlaceInCategory = (
  entries: Array<Entry>,
  category: PointsCategory,
  formula: Formula,
  ageCoefficients: AgeCoefficients,
  inKg: boolean,
  meetDate: string
): Array<Entry> => {
  // Make a map from Entry to initial index.
  const indexMap = new Map();
  for (let i = 0; i < entries.length; i++) {
    indexMap.set(entries[i], i);
  }

  // Pre-calculate all the points into an array to avoid computing them multiple
  // times in the sort.
  const memoizedPoints = new Array(entries.length);
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    const totalKg = getFinalEventTotalKg(entry, category.event);

    memoizedPoints[i] = getAgeAdjustedPoints(ageCoefficients, meetDate, formula, entry, category.event, totalKg, inKg);
  }

  // Clone the entries array to avoid modifying the original.
  const clonedEntries = entries.slice();

  // Sort in the given category, first place having the lowest index.
  clonedEntries.sort((a, b) => {
    const aIndex = indexMap.get(a);
    const bIndex = indexMap.get(b);

    // Appease the type checker even though this can't happen.
    if (aIndex === undefined || bIndex === undefined) return 0;

    // Guests always sort higher than non-guests.
    // This is phrased a little strangely to also handle undefined.
    if (a.guest !== b.guest) {
      if (a.guest) return 1;
      if (b.guest) return -1;
    }

    // First sort by points, higher sorting lower.
    const aPoints = memoizedPoints[aIndex];
    const bPoints = memoizedPoints[bIndex];
    if (aPoints !== bPoints) return bPoints - aPoints;

    // If points are equal, sort by Bodyweight, lower sorting lower.
    if (a.bodyweightKg !== b.bodyweightKg) return a.bodyweightKg - b.bodyweightKg;

    // Otherwise, they're equal.
    return 0;
  });

  return clonedEntries;
};

// Determines the sort order by Event.
const getEventSortOrder = (ev: Event): number => {
  return ["SBD", "BD", "SB", "SD", "S", "B", "D"].indexOf(ev);
};

// Determines the sort order by Equipment.
const getEquipmentSortOrder = (eq: Equipment): number => {
  // Combine classic and equipped lifting.
  return ["Bare", "Sleeves", "Wraps", "Single-ply", "Multi-ply", "Unlimited"].indexOf(eq);
};

// Determines the sort order by Sex.
const getSexSortOrder = (sex: Sex): number => {
  switch (sex) {
    case "F":
      return 0;
    case "M":
      return 1;
    case "Mx":
      return 2;
    default:
      checkExhausted(sex);
      return 3;
  }
};

// Determines the sort (and therefore presentation) order for the Category Results.
// The input array is sorted in-place; nothing is returned.
export const sortPointsCategoryResults = (results: Array<PointsCategoryResults>): void => {
  results.sort((a, b) => {
    const catA = a.category;
    const catB = b.category;

    // First, sort by Sex.
    const aSex = getSexSortOrder(catA.sex);
    const bSex = getSexSortOrder(catB.sex);
    if (aSex !== bSex) return aSex - bSex;

    // Next, sort by Event.
    const aEvent = getEventSortOrder(catA.event);
    const bEvent = getEventSortOrder(catB.event);
    if (aEvent !== bEvent) return aEvent - bEvent;

    // Finally, sort by Equipment.
    const aEquipment = getEquipmentSortOrder(catA.equipment);
    const bEquipment = getEquipmentSortOrder(catB.equipment);
    if (aEquipment !== bEquipment) return aEquipment - bEquipment;

    return 0;
  });
};

// Generates objects representing the various ByPoints categories.
// The returned objects are sorted in intended order of presentation.
export const getAllRankings = (
  entries: ReadonlyArray<Entry>,
  formula: Formula,
  ageCoefficients: AgeCoefficients,
  combineSleevesAndWraps: boolean,
  combineSingleAndMulti: boolean,
  inKg: boolean,
  meetDate: string
): Array<PointsCategoryResults> => {
  // Generate a map from category to the entries within that category.
  // The map is populated by iterating over each entry and having the entry
  // append itself to per-category lists.
  const categoryMap = new Map();
  for (let i = 0; i < entries.length; i++) {
    const e = entries[i];

    // Remember consistent properties.
    const sex = e.sex;
    let equipment: Equipment = e.equipment;

    // If the results combine Sleeves and Wraps, promote Sleeves to Wraps.
    if (combineSleevesAndWraps && equipment === "Sleeves") {
      equipment = "Wraps";
    }

    // If the results combine Sleeves and Wraps, promote Sleeves to Wraps.
    if (combineSingleAndMulti && equipment === "Single-ply") {
      equipment = "Multi-ply";
    }

    // Iterate over each event, adding to the map.
    for (let evidx = 0; evidx < e.events.length; evidx++) {
      const event = e.events[evidx];
      const category = { sex, event, equipment };
      const key = categoryToKey(category);

      const catEntries = categoryMap.get(key);
      catEntries === undefined ? categoryMap.set(key, [e]) : catEntries.push(e);
    }
  }

  // Iterate over each category and assign a Place to the entries therein.
  const results = [];
  for (const [key, catEntries] of categoryMap) {
    const category = keyToCategory(key);
    const orderedEntries = sortByFormulaPlaceInCategory(catEntries, category, formula, ageCoefficients, inKg, meetDate);
    results.push({ category, orderedEntries });
  }

  sortPointsCategoryResults(results);
  return results;
};

// get current and potential points rankings for an entry, for overall for each event the entry is in
export const getPotentialPointsRankings = (
  entry: Entry,
  lift: Lift,
  attemptOneIndexed: number,
  allEntries: ReadonlyArray<Entry>,
  meet: MeetState,
  language: Language,
): PointsEventRanking[] => {

  // FIXME: hard-coded for no age coeffs and best lifter
  const ageCoefficients = "None"
  
  const combineSleevesAndWraps = meet.combineSleevesAndWraps;
  const combineSingleAndMulti = meet.combineSingleAndMulti;
  
  // filter out entries that match the entry we are checking
  // - that way there will only be categories in the results that the lifter is in
  // for events and divisons we have to check many to many
  const entries = allEntries.filter((e) => e.sex === entry.sex && 
                                           e.events.some(ev => entry.events.includes(ev)) && 
                                           e.equipment === entry.equipment);
  
  // get current rankings
  const results = getAllRankings(
    entries,
    meet.formula,
    ageCoefficients,
    combineSleevesAndWraps,
    combineSingleAndMulti,
    meet.inKg,
    meet.date
  );  
  
  // mark lift as success in entry copy
  const potentialEntry = Object.assign({}, entry);
  const fieldStatus = liftToStatusFieldName(lift);
  const potentialEntryFieldStatus = [...potentialEntry[fieldStatus]];
  potentialEntryFieldStatus[attemptOneIndexed - 1] = 1;
  potentialEntry[fieldStatus] = potentialEntryFieldStatus;
  
  // create new set of entries and set the updated entry
  const potentialEntries: Array<Entry> = entries.slice();
  const index = potentialEntries.findIndex((obj) => obj.id === entry.id);
  potentialEntries[index] = potentialEntry;

  // get potential rankings
  const potentialResults = getAllRankings(
    potentialEntries,
    meet.formula,
    ageCoefficients,
    combineSleevesAndWraps,
    combineSingleAndMulti,
    meet.inKg,
    meet.date
  );  
  
  // get current and potential ranking for entry
  const pointsRankings: PointsEventRanking[] = [];
  results.forEach((result) => {
    const event = result.category.event;
    // only process results that are for our entry (others will be caused by other lifters in the original selection set)
    if (entry.events.includes(event)) {
      const currentPlace = result.orderedEntries.findIndex((obj) => obj.id === entry.id) + 1;
      const potentialResultIndex = potentialResults.findIndex((obj) => obj.category.event === event)
      const potentialResult = potentialResults[potentialResultIndex];
      const potentialPlaceIndex = potentialResult.orderedEntries.findIndex((obj) => obj.id === entry.id);
      const potentialPlace = potentialPlaceIndex + 1;
      
      const totalKg = getFinalEventTotalKg(entry, event);
      
      // get the potential points and the points of the next and previous highest places
      const potentialTotal = getFinalEventTotalKg(potentialResult.orderedEntries[potentialPlaceIndex], event);
      const potentialPoints = getAgeAdjustedPoints(ageCoefficients, meet.date, meet.formula, potentialResult.orderedEntries[potentialPlaceIndex], event, potentialTotal, meet.inKg);
      let nextPlaceTotal = 0;
      let nextPlacePoints = 0;
      if (potentialPlace !== 1) {
        nextPlaceTotal = getFinalEventTotalKg(potentialResult.orderedEntries[potentialPlaceIndex - 1], event);
        nextPlacePoints = getAgeAdjustedPoints(ageCoefficients, meet.date, meet.formula, potentialResult.orderedEntries[potentialPlaceIndex - 1], event, nextPlaceTotal, meet.inKg);
      }
      let prevPlaceTotal = 0;
      let prevPlacePoints = 0;
      if (potentialPlace !== potentialResult.orderedEntries.length) {
        prevPlaceTotal = getFinalEventTotalKg(potentialResult.orderedEntries[potentialPlaceIndex + 1], event);
        prevPlacePoints = getAgeAdjustedPoints(ageCoefficients, meet.date, meet.formula, potentialResult.orderedEntries[potentialPlaceIndex + 1], event, prevPlaceTotal, meet.inKg);
      }
      
      pointsRankings.push({event: event, currentPlace: currentPlace, potentialPlace: potentialPlace, potentialPoints: potentialPoints, nextPlacePoints: nextPlacePoints, prevPlacePoints: prevPlacePoints});
    }
  });
  
  return pointsRankings;
};

// if placings are by division, the existing logic already works
// the following is only for points based
export const getEntriesForPointsPlacings = (
  rankType: string, 
  equipment: Equipment,
  sex: Sex,
  event: Event,
  day: number,
  session: number,
  lift: Lift,
  attemptOneIndexed: number,
  entries: ReadonlyArray<Entry>
) => {

  let placingsLift = lift
  let placingsAttemptOneIndexed = attemptOneIndexed
  const override = true
  if (override) {
    placingsLift = 'D'
    placingsAttemptOneIndexed = 3
  }

  let interimRankedEntries: Array<Entry> = []
  let prognosisRankedEntries: Array<Entry> = []
  if (rankType === 'SEX_EQUIPMENT_POINTS') {
    // get all
    prognosisRankedEntries = entries.filter((e) => 
      e.events[0] === event &&
      e.sex === sex && 
      e.equipment === equipment);
    if (placingsLift === 'D' && placingsAttemptOneIndexed > 1) {
      interimRankedEntries = prognosisRankedEntries.slice()
    } else {
      // only get this session
      interimRankedEntries = entries.filter((e) => 
        e.events[0] === event &&
        e.sex === sex && 
        e.day === day &&
        e.session === session &&
        e.equipment === equipment);
    }
  } else if (rankType === 'EQUIPMENT_POINTS') {
    // get all
    prognosisRankedEntries = entries.filter((e) => 
      e.events[0] === event &&
      e.equipment === equipment);
    if (placingsLift === 'D' && placingsAttemptOneIndexed > 1) {
      interimRankedEntries = prognosisRankedEntries.slice()
    } else {
      // get only get this session
      interimRankedEntries = entries.filter((e) => 
        e.events[0] === event &&
        e.day === day &&
        e.session === session &&
        e.equipment === equipment);
    }
  }
  return {
    prognosisRankedEntries: prognosisRankedEntries,
    interimRankedEntries: interimRankedEntries,
  } 
}

// session view will have to show actual placings (otherwise there would be 2 of some of the placings)
// flight view needs to state it is sub-total placing

export interface PointsInfo {
  entryId: number;
  bodyweight: number;
  guest: boolean;
  prognosisTotal: number;
  finalTotal: number;
  prognosisPoints: number;
  finalPoints: number;
}

export const getPlacings = (entries: Array<Entry>, type: string) => {
  const pointsEntries: Array<PointsInfo> = []
  entries.forEach(entry => {
    const pointsInfo: PointsInfo = {} as PointsInfo
    pointsInfo.entryId = entry.id
    pointsInfo.bodyweight = entry.bodyweightKg
    pointsInfo.guest = entry.guest
    pointsInfo.prognosisTotal = getPrognosisTotalKg(entry)
    pointsInfo.finalTotal = getFinalTotalKg(entry)
    pointsInfo.prognosisPoints = goodlift(pointsInfo.prognosisTotal, entry.bodyweightKg, entry.sex, entry.equipment, entry.events[0]);
    pointsInfo.finalPoints = goodlift(pointsInfo.finalTotal, entry.bodyweightKg, entry.sex, entry.equipment, entry.events[0]);
    pointsEntries.push(pointsInfo)
  })
  return sortByPoints(pointsEntries, type)
}

// Returns a copy of the EntryInfo array sorted by Points
const sortByPoints = (entries: PointsInfo[], type: string): PointsInfo[] => {

  // Clone the entries array to avoid modifying the original.
  const clonedEntries = entries.slice();

  // Sort in the given category, first place having the lowest index.
  clonedEntries.sort((a, b) => {

    // If either of the lifters are guests, sort the guest last
    if (a.guest !== b.guest) {
      return Number(a.guest) - Number(b.guest);
    }

    // First sort by points, higher sorting lower.
    if (type === 'Final') {
      if (a.finalPoints !== b.finalPoints) return b.finalPoints - a.finalPoints;
    } else if (type === 'Prognosis') {
      if (a.prognosisPoints !== b.prognosisPoints) return b.prognosisPoints - a.prognosisPoints;
    }

    // If points are equal, sort by Bodyweight, lower sorting lower.
    if (a.bodyweight !== b.bodyweight) return a.bodyweight - b.bodyweight;

    // Otherwise, they're equal.
    return 0;
  });

  return clonedEntries;
};

export interface EquipmentSexEvent {
  equipment: Equipment,
  sex: Sex,
  event: Event;
}
export interface EquipmentEvent {
  equipment: Equipment,
  event: Event;
}
export interface ESPointsResults {
  equipmentSexEvent: EquipmentSexEvent;
  orderedFinalPointsEntries: Array<PointsInfo>;
  orderedPrognosisPointsEntries: Array<PointsInfo>;
}
export interface EPointsResults {
  equipmentEvent: EquipmentEvent;
  orderedFinalPointsEntries: Array<PointsInfo>;
  orderedPrognosisPointsEntries: Array<PointsInfo>;
}

export interface PointsResults {
  esPointsResults: Array<ESPointsResults>,
  ePointsResults: Array<EPointsResults>,
}

export interface RankTypePointsResults {
  rankType: string,
  pointsResults: PointsResults, 
}

export const getPointsResults = (
  rankType: string,
  sourceEntries: Array<Entry>,      // only need rankType for these sex/equipment
  allEntries: ReadonlyArray<Entry>, // need placings for all for the above sex/equipment
  day: number,
  session: number,
  lift: Lift,
  attemptOneIndexed: number,
) => {
  const esPointsResults: Array<ESPointsResults> = []
  const ePointsResults: Array<EPointsResults> = []
  if (rankType === 'SEX_EQUIPMENT_WEIGHTCLASS') {
    // don't need to get points places
  } else {
    if (rankType === 'SEX_EQUIPMENT_POINTS') {
      const uniqueEquipmentSexEvent: EquipmentSexEvent[] = []
      const uniqueEquipmentSexEventSet: Set<string> = new Set()
      sourceEntries.forEach(entry => {
          const itemKey = entry.equipment + ":" + entry.sex + ":" + entry.events[0]
          if (! uniqueEquipmentSexEventSet.has(itemKey)) {
            uniqueEquipmentSexEventSet.add(itemKey)
            uniqueEquipmentSexEvent.push({equipment: entry.equipment, sex: entry.sex, event: entry.events[0]});
          }
      });
      // calculate the points placings for each of the categories
      for (const unique of uniqueEquipmentSexEvent) {
        const entriesForPointsPlacings = getEntriesForPointsPlacings(
          rankType, 
          unique.equipment,
          unique.sex,
          unique.event,
          day,
          session,
          lift,
          attemptOneIndexed,
          allEntries,
        )
        const prognosisPlacings = getPlacings(entriesForPointsPlacings.prognosisRankedEntries, 'Prognosis')
        const interimPlacings = getPlacings(entriesForPointsPlacings.interimRankedEntries, 'Final')
        esPointsResults.push({
          equipmentSexEvent: unique,
          orderedFinalPointsEntries: interimPlacings,
          orderedPrognosisPointsEntries: prognosisPlacings
        })
      }
    } else if (rankType === 'EQUIPMENT_POINTS') {
      const uniqueEquipmentEvent: EquipmentEvent[] = []
      const uniqueEquipmentEventSet: Set<string> = new Set()
      sourceEntries.forEach(entry => {
        const itemKey = entry.equipment + ":" + entry.events[0]
          if (! uniqueEquipmentEventSet.has(itemKey)) {
            uniqueEquipmentEventSet.add(itemKey)
            uniqueEquipmentEvent.push({equipment: entry.equipment, event: entry.events[0]});
          }
      });
      console.log('** uniqueEquipmentEventSet', uniqueEquipmentEventSet)
      // calculate the points placings for each of the categories
      for (const unique of uniqueEquipmentEvent) {
        const entriesForPointsPlacings = getEntriesForPointsPlacings(
          rankType, 
          unique.equipment,
          '' as Sex,
          unique.event,
          day,
          session,
          lift,
          attemptOneIndexed,
          allEntries,
        )
        const prognosisPlacings = getPlacings(entriesForPointsPlacings.prognosisRankedEntries, 'Prognosis')
        const interimPlacings = getPlacings(entriesForPointsPlacings.interimRankedEntries, 'Final')
        ePointsResults.push({
          equipmentEvent: unique,
          orderedFinalPointsEntries: interimPlacings,
          orderedPrognosisPointsEntries: prognosisPlacings,
        })
      }
    }
  }
  const pointsResults: PointsResults = {
    esPointsResults: esPointsResults,
    ePointsResults: ePointsResults,
  }
  return pointsResults
}

export const getRankTypeName = (code: string) => {
  if (code === 'SEW') return 'SEX_EQUIPMENT_WEIGHTCLASS'
  if (code === 'SEP') return 'SEX_EQUIPMENT_POINTS'
  if (code === 'EP') return 'EQUIPMENT_POINTS'
  return 'SEX_EQUIPMENT_WEIGHTCLASS'
}

export const getFlightRanktypes = (entries: Entry[]) => {
  const rankTypes: string[] = []
  const rankTypesSet: Set<string> = new Set()
  entries.forEach(entry => {
    const rankTypeName = getRankTypeName(entry.state)
    if (! rankTypesSet.has(rankTypeName)) {
      rankTypesSet.add(rankTypeName)
      rankTypes.push(rankTypeName);
    }
  });
  return rankTypes
}

interface EntryDetails {
  rankType: string,
  equipment: Equipment,
  division: string,
  weightClass: string,
  event: string,
  sex: Sex,
  day: number,
  session: number,
}

const getWeightClass = (sex: Sex, fClassesForSex: readonly number[], mClassesForSex: readonly number[], bodyweightKg: number): string => {
  const classesForSex = sex === 'F' ? fClassesForSex : mClassesForSex
  const weightClassStr = getWeightClassStr(classesForSex, bodyweightKg);
  return weightClassStr
}

const getIntendedBodyweightKg = (entry: Entry) => {
  let bodyweightKg = entry.bodyweightKg
  if (bodyweightKg === 0) {
    let intended = entry.country.trim()
    if (intended !== '') {
      const superclass = intended.startsWith('+') || intended.endsWith('+')
      intended = intended.replace('+', '')
      if (Number.isNaN(Number.parseFloat(intended))) {
      } else {
        bodyweightKg = Number.parseFloat(intended)
        if (superclass === true) {
          bodyweightKg += 0.5
        } else {
          bodyweightKg -= 0.5
        }
      }
    }
  }
  return bodyweightKg
}

export const getFlightEntryDetails = (entries: Entry[], fClassesForSex: readonly number[], mClassesForSex: readonly number[]) => {
  const entryDetails: EntryDetails[] = []
  // only process entries that have weighed in or have a nominated weight class in the country field
  entries.forEach(entry => {
    const rankTypeName = getRankTypeName(entry.state)
    const bodyweightKg = getIntendedBodyweightKg(entry)
    if (bodyweightKg !== 0) {
      const weightClass = getWeightClass(entry.sex, fClassesForSex, mClassesForSex, bodyweightKg)
      const entryDetail: EntryDetails = {
        rankType: rankTypeName,
        equipment: entry.equipment,
        division: entry.divisions[0],
        weightClass: weightClass,
        event: entry.events[0],
        sex: entry.sex,
        day: entry.day,
        session: entry.session,
      }
      entryDetails.push(entryDetail)
    }
  })
  return entryDetails
}

export interface RankTypeEntries {
  rankType: string,
  sex: string,
  weightClass: string,
  equipment: string,
  event: string,
  division: string,
  prognosisRankedEntries: Entry[],
  interimRankedEntries: Entry[],
}

export const getFlightRanktypeEntries = (sourceEntries: Entry[], entries: ReadonlyArray<Entry>, lift: Lift, attemptOneIndexed: number, fClassesForSex: readonly number[], mClassesForSex: readonly number[]) => {
  const rankTypesEntries: RankTypeEntries[] = []
  const rankTypesSet: Set<string> = new Set()

  const entryDetails = getFlightEntryDetails(sourceEntries, fClassesForSex, mClassesForSex)
  entryDetails.forEach(entryDetail => {
    let interimItemKey = ''
    let interimRankedEntries
    let prognosisRankedEntries
    if (entryDetail.rankType === 'SEX_EQUIPMENT_WEIGHTCLASS') {
      interimItemKey = entryDetail.rankType + ':' + entryDetail.equipment + ':' + entryDetail.division + ':' + entryDetail.weightClass + ':' + entryDetail.event
      if (! rankTypesSet.has(interimItemKey)) {
        rankTypesSet.add(interimItemKey)
        interimRankedEntries = entries.filter((e) => 
          // don't need sex, as divisions are sex-based // e.sex === rankingEntry.sex && 
          e.events[0] === entryDetail.event &&
          e.equipment === entryDetail.equipment && 
          e.divisions[0] === entryDetail.division && 
//          getWeightClass(e.sex, fClassesForSex, mClassesForSex, e.bodyweightKg) === entryDetail.weightClass);
          getWeightClass(e.sex, fClassesForSex, mClassesForSex, getIntendedBodyweightKg(e)) === entryDetail.weightClass);
        prognosisRankedEntries = interimRankedEntries.slice()
        rankTypesEntries.push({
          rankType: entryDetail.rankType,
          sex: entryDetail.sex,
          weightClass: entryDetail.weightClass,
          equipment: entryDetail.equipment,
          event: entryDetail.event,
          division: entryDetail.division,
          prognosisRankedEntries: prognosisRankedEntries,
          interimRankedEntries: interimRankedEntries,
        })
      }
    } else if (entryDetail.rankType === 'SEX_EQUIPMENT_POINTS') {
      interimItemKey = entryDetail.rankType + ':' + entryDetail.sex + ':' + entryDetail.equipment + ':' + entryDetail.event
      if (! rankTypesSet.has(interimItemKey)) {
        rankTypesSet.add(interimItemKey)
        // get all
        prognosisRankedEntries = entries.filter((e) => 
          e.events[0] === entryDetail.event &&
          e.sex === entryDetail.sex && 
          e.equipment === entryDetail.equipment);
        if (lift === 'D' && attemptOneIndexed > 1) {
          interimRankedEntries = prognosisRankedEntries.slice()
        } else {
          // get only get this session
          interimRankedEntries = entries.filter((e) => 
            e.events[0] === entryDetail.event &&
            e.sex === entryDetail.sex && 
            e.day === entryDetail.day &&
            e.session === entryDetail.session &&
            e.equipment === entryDetail.equipment);
        }
        rankTypesEntries.push({
          rankType: entryDetail.rankType,
          sex: entryDetail.sex,
          weightClass: '',
          equipment: entryDetail.equipment,
          event: entryDetail.event,
          division: entryDetail.division,
          prognosisRankedEntries: prognosisRankedEntries,
          interimRankedEntries: interimRankedEntries,
        })
      }
    } else if (entryDetail.rankType === 'EQUIPMENT_POINTS') {
      interimItemKey = entryDetail.rankType + ':' + entryDetail.equipment + ':' + entryDetail.event
      if (! rankTypesSet.has(interimItemKey)) {
        rankTypesSet.add(interimItemKey)
        // get all
        prognosisRankedEntries = entries.filter((e) => 
          e.events[0] === entryDetail.event &&
          e.equipment === entryDetail.equipment);
        if (lift === 'D' && attemptOneIndexed > 1) {
          interimRankedEntries = prognosisRankedEntries.slice()
        } else {
          // get only get this session
          interimRankedEntries = entries.filter((e) => 
            e.events[0] === entryDetail.event &&
            e.day === entryDetail.day &&
            e.session === entryDetail.session &&
            e.equipment === entryDetail.equipment);
        }
        rankTypesEntries.push({
          rankType: entryDetail.rankType,
          sex: 'Z', // this is for combined, but we want it to sort last
          weightClass: '',
          equipment: entryDetail.equipment,
          event: entryDetail.event,
          division: entryDetail.division,
          prognosisRankedEntries: prognosisRankedEntries,
          interimRankedEntries: interimRankedEntries,
        })
      }
    }
  })

  return rankTypesEntries
}

// NOTE - this uses prognosis entries, it is used to get all previously unencountered rank types
export const getPointsBasedRankTypeEntries = (entries: Entry[], rankTypeEntries: RankTypeEntries[], fClassesForSex: readonly number[], mClassesForSex: readonly number[]) => {
  const pointsBasesRankTypeEntries: RankTypeEntries[] = []
  const rankTypesSet: Set<string> = new Set()
  rankTypeEntries.forEach(rankTypeEntry => {
    if (rankTypeEntry.rankType !== 'SEX_EQUIPMENT_WEIGHTCLASS') {
      for (const entry of rankTypeEntry.prognosisRankedEntries) {
        const weightClass = getWeightClass(entry.sex, fClassesForSex, mClassesForSex, entry.bodyweightKg)
        const interimItemKey = 'SEX_EQUIPMENT_WEIGHTCLASS' + ':' + entry.sex + ':' + rankTypeEntry.equipment + ':' + weightClass + ':' + rankTypeEntry.event
        if (! rankTypesSet.has(interimItemKey)) {
          rankTypesSet.add(interimItemKey)
          const idx = rankTypeEntries.findIndex(rte => rte.rankType === 'SEX_EQUIPMENT_WEIGHTCLASS' && rte.sex === entry.sex && rte.equipment === rankTypeEntry.equipment && rte.weightClass === weightClass && rte.event === rankTypeEntry.event)
          if (idx !== -1) {
            // rank type already present, do nothing
          } else {
            // have a rank type not encountered before
            const interimRankedEntries = entries.filter((e) => 
              e.sex === entry.sex && 
              e.events[0] === rankTypeEntry.event &&
              e.equipment === rankTypeEntry.equipment && 
              getWeightClass(e.sex, fClassesForSex, mClassesForSex, e.bodyweightKg) === weightClass);
            const prognosisRankedEntries = interimRankedEntries.slice()
            pointsBasesRankTypeEntries.push({
              rankType: 'SEX_EQUIPMENT_WEIGHTCLASS',
              sex: entry.sex,
              weightClass: weightClass,
              equipment: rankTypeEntry.equipment,
              event: rankTypeEntry.event,
              division: rankTypeEntry.division,
              prognosisRankedEntries: prognosisRankedEntries,
              interimRankedEntries: interimRankedEntries,
            })
          }
        }
      }
    }
  })

  return pointsBasesRankTypeEntries
}

