// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { ImportRecordsAction, OverwriteStoreAction } from "../types/actionTypes";
import { RecordsState } from "../types/stateTypes";
import { ImportedLiftingRecord, LiftingRecord, RecordKeyComponents, RecordSet } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

type Action = ImportRecordsAction | OverwriteStoreAction;

export const initialState: RecordsState = {
  recordNames: [],
  recordSets: {},
  brokenRecords: [],
};

export function calculateRecordKey(record: RecordKeyComponents): string {
  return `${record.division}|${record.sex}|${record.weightClass}|${record.equipment}|${record.recordLift}|${record.recordType}`;
}

// Add or replace a record in the state and return the updated state. Used for unifying the imported records with new records in the meet
export function upsertRecord(record: LiftingRecord, state: RecordsState, recordName: string): RecordsState {
  
//  console.log("upsert", recordName, record);
  
  const recordKey = calculateRecordKey(record);
  const newRecordSet = { ...state.recordSets[recordName] };
  newRecordSet[recordKey] = record;

  // save records as they are broken
  const brokenRecords: ImportedLiftingRecord[] = state.brokenRecords.slice();
  const brokenRecord: ImportedLiftingRecord = Object.assign({}, record, {recordName: recordName});
  brokenRecords.push(brokenRecord);
  
  return {
    ...state,
    recordSets: {
      ...state.recordSets,
      [recordName] : newRecordSet,
      },
    brokenRecords: brokenRecords,
  }
}

// clear the saved broken records
export function clearBrokenRecords(state: RecordsState): RecordsState {
  const brokenRecords: ImportedLiftingRecord[] = [];
  return {
    ...state,
    brokenRecords: brokenRecords,
  };
}

export default (state: RecordsState = initialState, action: Action): RecordsState => {
  
  switch (action.type) {
    
    case "IMPORT_RECORDS": {
      // process sets of records by record name
      const importedRecordSets: RecordSet = {};
      const importedRecordNames = action.records.map((record) => record.recordName);
      const uniqueRecordNames = [...new Set(importedRecordNames)];
      uniqueRecordNames.forEach((importedRecordName) => {
        const importedRecords: { [recordKey: string]: LiftingRecord } = {};
        action.records.filter(record => record.recordName === importedRecordName).map((importedRecord) => {
          // @ts-ignore
          delete importedRecord.recordName;
          importedRecords[calculateRecordKey(importedRecord)] = importedRecord;
        });
        importedRecordSets[importedRecordName] = importedRecords;
      });

      return {
//        ...state,
        recordNames: uniqueRecordNames,
        recordSets: importedRecordSets,
        brokenRecords: [],
      };
      
    };

    case "OVERWRITE_STORE": {
      return action.store.records || state;
    }
    
    default:
      checkExhausted(action);
      return state;
  }
};
