// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Defines logic for creating and working with Drl objects.

import { Image, ScreenPosition, Text, Box, Screen, ScreenOffset, Drl } from "../types/dataTypes";

export const newScreenPosition = (): ScreenPosition => {
  return {
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  };
};
    
export const newDefaultImage = (imageId: string): Image => {
  const screenPosition = newScreenPosition();
  return {
    imageId: imageId,
    componentId: "",
    imageFile: "",
    imageShow: false,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  };
};

export const newDefaultText = (textId: string): Text => {
  return {
    textId: textId,
    componentId: "",
    text: "",
    color: [],
    font: "",
    fontSize: 0,
    textShow: false,
    left: 0,
    top: 0,
  };
};

export const newDefaultBox = (boxId: string): Box => {
  return {
    boxId: boxId,
    componentId: "",
    color: [],
    boxShow: false,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  };
};

// setting non-zero defaults until we find out why the initial UPDATE_SCREEN from DRL gets missed/lost/overwritten (maybe from load)
export const newScreen = (componentId: string = "background", width: number = 1920, height: number = 1080, screenShow: boolean = false): Screen => {
  return {
    componentId: componentId,
    width: width,
    height: height,
    screenShow: false,
  };
};

// defaults in case they don't get set...
export const getDrlScreenHeight = (drl: Drl): number => {
  return drl.screen.height > 0 ? drl.screen.height : 1080;
};
export const getDrlScreenWidth = (drl: Drl): number => {
  return drl.screen.width > 0 ? drl.screen.width : 1920;
};

export const newScreenOffset = (top: number = 0, left: number = 0): ScreenOffset => {
  return {
    top: top,
    left: left,
  };
};

export const newDefaultDrl = (): Drl => {
  const screen = newScreen();
  return {
    images: [],
    texts: [],
    boxes: [],
    screen: screen,
  };
};

export const newDrl = (images: Array<Image>, texts: Array<Text>, boxes: Array<Box>, screen: Screen): Drl => {
  return {
    images: images,
    texts: texts,
    boxes: boxes,
    screen: screen,
  };
};

//export const getUnscaledScreenOffset = (screenOffset: ScreenOffset, scale: number): ScreenOffset => {
//  const top = screenOffset.top / scale;
//  const left = screenOffset.left / scale;
//  return {
//    top: top,
//    left: left,
//  };
//};
