// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// Defines logic for creating and working with Drl objects.

import React from 'react';

import { RedirectPage, PrivateRoute, PublicRoute } from "../types/configTypes";
import { ScreenPosition, ScreenPositionData, ScreenGap, TimerPositionData } from "../types/dataTypes";

// default config entries are in src
let globalData = require('../config/global.json');
// any overrides are in public/stream
let streamDataUrl = process.env.PUBLIC_URL + '/stream/streamConfig.json'

//FIXME: tidy up the config loading code, plus make sure the user knows if config can't be loaded

const getConfig = (configPath: string) => {
  return fetch(configPath)
  .then(response => {
    if (!response.ok) {
      console.log('Failed to read', configPath)
      return null
    }
    try {
      const json = response.json()
      return json
    } catch (error) {
      console.error(error);    
      return null
    }
  }).catch((e: Error) => {
    console.log('Failed to read', configPath, e.message);
    return null
  });
}  

export const config: any = {};
export default config;

function loadConfig() {
  
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port;
  const appModeUrl = `${protocol}//${domain}:${port? port : ""}/appmode`
  return fetch(appModeUrl)
  .then(response => {
    if (!response.ok) {
      throw new Error("Network error");
    }
    try {
      const json = response.json()
      return json
    } catch (error) {
      console.error(error);    
      throw new Error("Json  error");
    }
  })
  .catch(error => {
    console.error("Can't get appmode from server, returning default:", error);
    return {appMode: getDefaultAppMode()};
  })
  .then((newconfig) => {
    for(let prop in config) {
      delete config[prop]
    }
    for(let prop in newconfig) {
      config[prop] = newconfig[prop]
    }
    config['publicRoutes'] = globalData[config.appMode].publicRoutes;
    config['privateRoutes'] = globalData[config.appMode].privateRoutes;
    config['redirectPages'] = globalData[config.appMode].redirectPages;
    config['streamConfig'] = globalData['streamConfig'];
    
    getConfig(streamDataUrl)
      .then((newConfig) => {
        if (newConfig.streamConfig !== undefined) {
          config['streamConfig'] = {...newConfig.streamConfig}
        }
      })
    
    return config;
  });
}
export {loadConfig}

function setStreamConfig(streamConfig: any) {
  config['streamConfig'] = streamConfig['streamConfig'];
}
export {setStreamConfig}

function loadStreamConfig() {
  config['streamConfig'] = globalData['streamConfig'];
  return config;
}
export {loadStreamConfig}

// config will contain public and private routes and redirect pages for the run mode
// config is loaded at startup, getting its data from global.json
//
// global.json contains public/private/redirect pages for each run mode.
// Set an environment variable REACT_APP_MODE on the server to
// the name of the run mode you want to run as. If REACT_APP_MODE doesn't exist,
// it will default to "client" (run as a client app with no authentication).
//
// There must be at least one public or private route. If you don't want
// authentication then just define public routes and leave empty arrays for
// private routes and redirect pages.
//
//   publicRoutes - route paths that are public (note that login/logout are toggled,
//                  and only displayed if private routes are in use)
//   privateRoutes - route paths that require authentication with the users allowed
//                   access
//   redirectPages - "home" pages for users after login
//

export const getDefaultAppMode = (): string => {
  return "client";
};

export const getUsernames = (): Array<string> => {
  const privateRoutes = getPrivateRoutes();
  let usernames = new Set<string>();
  for (let privateRoute of privateRoutes) {
    for(let username of privateRoute.usernames) {
      usernames.add(username);
    }
  }
  return Array.from(usernames).sort();
};      

export interface SelectOption {
  value: string;
  label: string;
}

export const getUsernameSelectOptions = (): Array<SelectOption> => {
  const usernames: Array<string> = getUsernames();
  let selectOptions: Array<SelectOption> = [];
  for (let username of usernames) {
    const selectOption: SelectOption = { value: username, label: username };
    selectOptions.push(selectOption);
  }
  return selectOptions;
}
  
export const getRedirectPages = (): Array<RedirectPage> => {
  return config.redirectPages;
};

export const getRedirectPageForUser = (redirectPages: Array<RedirectPage>, username: string) => {
  const index = redirectPages.findIndex((obj) => obj.username === username);
  if (index === -1) {
      // shouldn't happen, as long as array contains all usernames... Pick a benign tab we know is public
      return "/about";
  } else {
    return redirectPages[index].path;
  }
};

export const getPrivateRoutes = (): Array<PrivateRoute> => {
  return config.privateRoutes;
};

export const checkNoPrivateRoutes = () => {
  // returns true if there are no private routes
  const privateRoutes = getPrivateRoutes();
  return privateRoutes.length === 0 ? true : false;
}

export const getPrivateRouteUsers = (privateRoutes: Array<PrivateRoute>, path: string) => {
  const index = privateRoutes.findIndex((obj) => obj.path === path);
  if (index === -1) {
      // shouldn't happen, as long as array contains all paths...
      return [];
  } else {
    return privateRoutes[index].usernames;
  }
};

export const checkPathIsPrivate = (privateRoutes: Array<PrivateRoute>, path: string) => {
  const index = privateRoutes.findIndex((obj) => obj.path === path);
  if (index === -1) {
      // shouldn't happen, as long as array contains all paths...
      return [];
  } else {
    return privateRoutes[index].usernames;
  }
};

export const getPublicRoutes = (): Array<PublicRoute> => {
  return config.publicRoutes;
};

export const getFirstPublicRoute = () => {
  const publicRoutes = getPublicRoutes();
  return publicRoutes[0].path;
}

export const checkPublicRoute = (publicRoutes: Array<PublicRoute>, path: string) => {
  const index = publicRoutes.findIndex((obj) => obj.path === path);
  if (index === -1) {
      return false;
  } else {
    return true;
  }
};

export const getPublicRouteAccess = (publicRoutes: Array<PublicRoute>, path: string) => {
  const index = publicRoutes.findIndex((obj) => obj.path === path);
  if (index === -1) {
      // shouldn't happen, as long as array contains all paths...
      return "any";
  } else {
    return publicRoutes[index].access;
  }
};

export const getBrandingBoxImageFile = (): string => {
  return config.streamConfig.branding.brandingBoxImageFile;
};
export const getBrandingBoxPosition = (): ScreenPosition => {
  return config.streamConfig.branding.brandingBoxPosition;
};

export const getSponsorBoxImageFiles = (): Array<string> => {
  return config.streamConfig.sponsors.sponsorBoxImageFiles.filter((image: string) => image !== '');
};
export const getSponsorBoxPosition = (): ScreenPosition => {
  return config.streamConfig.sponsors.sponsorBoxPosition;
};

export const getLifterBoxImageFile = (): string => {
  return config.streamConfig.lifter.lifterBoxImageFile;
};
export const getInfoBoxImageFile = (): string => {
  return config.streamConfig.lifter.infoBoxImageFile;
};
export const getNameBoxImageFile = (): string => {
  return config.streamConfig.lifter.nameBoxImageFile;
};
export const getLiftBoxImageFile = (): string => {
  return config.streamConfig.lifter.liftBoxImageFile;
};

export const getLifterBoxPosition = (): ScreenPosition => {
  return config.streamConfig.lifter.lifterBoxPosition;
};
export const getInfoBoxPosition = (): ScreenPosition => {
  return config.streamConfig.lifter.infoBoxPosition;
};
export const getNameBoxPosition = (): ScreenPosition => {
  return config.streamConfig.lifter.nameBoxPosition;
};
export const getLiftBoxPosition = (): ScreenPosition => {
  return config.streamConfig.lifter.liftBoxPosition;
};

export const getInfoTextPosition = (): ScreenPositionData => {
  return config.streamConfig.lifter.infoTextPosition;
}
export const getRankingTextPosition = (): ScreenPositionData => {
  return config.streamConfig.lifter.rankingTextPosition;
}

export const getNameTextPosition = (): ScreenPositionData => {
  return config.streamConfig.lifter.nameTextPosition;
}

export const getLift1TextPosition = (): ScreenPositionData => {
  return config.streamConfig.lifter.lift1TextPosition;
}
export const getLift2TextPosition = (): ScreenPositionData => {
  return config.streamConfig.lifter.lift2TextPosition;
}
export const getLift3TextPosition = (): ScreenPositionData => {
  return config.streamConfig.lifter.lift3TextPosition;
}
export const getLiftBestTextPosition = (): ScreenPositionData => {
  return config.streamConfig.lifter.liftBestTextPosition;
}

export const getUpNextMovingImagePosition = (): ScreenPosition => {
  return config.streamConfig.upNext.upNextMovingImagePosition;
};
export const getUpNextMovingImageFile = (): string => {
  return config.streamConfig.upNext.upNextMovingImageFile;
};
export const getUpNextMovingImageDirection = (): string => {
  return config.streamConfig.upNext.upNextMovingImageDirection;
};

export const getUpNextThisFlightHeaderBoxImageFile = (): string => {
  return config.streamConfig.upNext.upNextThisFlightHeaderBoxImageFile;
};
export const getUpNextNextFlightHeaderBoxImageFile = (): string => {
  return config.streamConfig.upNext.upNextNextFlightHeaderBoxImageFile;
};
export const getUpNextDataBoxImageFile = (): string => {
  return config.streamConfig.upNext.upNextDataBoxImageFile;
};
export const getUpNextHeaderBoxPosition = (): ScreenPosition => {
  return config.streamConfig.upNext.upNextHeaderBoxPosition;
};
export const getUpNextThisFlightHeaderBoxText = (): string => {
  return config.streamConfig.upNext.upNextThisFlightHeaderBoxText;
};
export const getUpNextNextFlightHeaderBoxText = (): string => {
  return config.streamConfig.upNext.upNextNextFlightHeaderBoxText;
};
export const getUpNextHeaderBoxTextPosition = (): ScreenPositionData => {
  return config.streamConfig.upNext.upNextHeaderBoxTextPosition;
};
export const getUpNextDataBoxPosition = (): ScreenPosition => {
  return config.streamConfig.upNext.upNextDataBoxPosition;
};
export const getUpNextDataBoxNumberPosition = (): ScreenPositionData => {
  return config.streamConfig.upNext.upNextDataBoxNumberPosition;
};
export const getUpNextDataBoxNamePosition = (): ScreenPositionData => {
  return config.streamConfig.upNext.upNextDataBoxNamePosition;
};
export const getUpNextDataBoxWeightPosition = (): ScreenPositionData => {
  return config.streamConfig.upNext.upNextDataBoxWeightPosition;
};
export const getUpNextDataBoxGap = (): ScreenGap => {
  return config.streamConfig.upNext.upNextDataBoxGap;
};

export const getTableData = (): any => {
  return config.streamConfig.tableData;
}
type BackgroundColor = {
  [key: string]: string
}
export const getBackgroundRGBColors = (): BackgroundColor => {
  return config.streamConfig.backgroundColors;
}
type BackgroundAlpha = {
  [key: string]: number
}
export const getBackgroundAlphas = (): BackgroundAlpha => {
  return config.streamConfig.backgroundAlphas;
}
export type BackgroundColorEntry = {
  [key: string]: string
}
export interface BackgroundColors {
  normalBackgroundColors: BackgroundColorEntry
  reducedBackgroundColors: BackgroundColorEntry
}
const getRGBA = (rgb: string, alpha: number) => {
  const a = rgb.replace('rgb(', 'rgba(')
  return a.replace(')', ',' + alpha.toString() + ')') 
}
export const getBackgroundColors = (): BackgroundColors => {
  const rgbs = getBackgroundRGBColors()
  const alphas = getBackgroundAlphas()
  let normalBackgroundColors: BackgroundColorEntry = {}
  let reducedBackgroundColors: BackgroundColorEntry = {}
  for (const [key, value] of Object.entries(rgbs)) {
    normalBackgroundColors[key] = getRGBA(value, alphas.normal)
    reducedBackgroundColors[key] = getRGBA(value, alphas.reduced)
  }
  return {normalBackgroundColors: normalBackgroundColors, reducedBackgroundColors: reducedBackgroundColors}
}

export const getDataPositions = (): any => {
  return config.streamConfig.dataPositions;
}

export const getTestButtons = (): any => {
  return config.streamConfig.testButtons;
}
export const getTestImage = (): any => {
  return config.streamConfig.testImage;
}

export const getDrlBoxPosition = (): ScreenPosition => {
  return config.streamConfig.lights.drlBoxPosition;
};
export const getOrlBoxPosition = (): ScreenPosition => {
  return config.streamConfig.lights.orlBoxPosition;
};
export const getDrlOrlBoxPosition = (): ScreenPosition => {
  return config.streamConfig.lights.drlOrlBoxPosition;
};

export const getRecordAttemptBoxPosition = (): ScreenPosition => {
  return config.streamConfig.records.recordAttemptBoxPosition;
};

export const getOrlColor = (component: string): string => {
  if (component === "stream") {
    return config.streamConfig.streamOverlayColors.orlColor;
  } else if (component === "audience") {
    return config.streamConfig.audienceOverlayColors.orlColor;
  } else {
    return "white";
  }
};
export const getLifterColor = (component: string): string => {
  if (component === "stream") {
    return config.streamConfig.streamOverlayColors.lifterColor;
  } else if (component === "audience") {
    return config.streamConfig.audienceOverlayColors.lifterColor;
  } else {
    return "white";
  }
};
export const getUpNextColor = (component: string): string => {
  if (component === "stream") {
    return config.streamConfig.streamOverlayColors.upNextColor;
  } else if (component === "audience") {
    return config.streamConfig.audienceOverlayColors.upNextColor;
  } else {
    return "white";
  }
};
export const getRecordAttemptColor = (component: string): string => {
  if (component === "stream") {
    return config.streamConfig.streamOverlayColors.recordAttemptColor;
  } else if (component === "audience") {
    return config.streamConfig.audienceOverlayColors.recordAttemptColor;
  } else {
    return "white";
  }
};
export const getOrlFontSize = (component: string): string => {
  if (component === "streamTimer") {
    return config.streamConfig.orlFontSize.streamTimer;
  } else if (component === "streamLight") {
    return config.streamConfig.orlFontSize.streamLight;
  } else if (component === "audienceTimer") {
    return config.streamConfig.orlFontSize.audienceTimer;
  } else if (component === "audienceLight") {
    return config.streamConfig.orlFontSize.audienceLight;
  } else if (component === "drlTimer") {
    return config.streamConfig.orlFontSize.drlTimer;
  } else if (component === "drlLight") {
    return config.streamConfig.orlFontSize.drlLight;
  } else {
    return "75px";
  }
};

export const getBrandingOn = (): number => {
  return config.streamConfig.brandingOn;
};
export const getSponsorOn = (): number => {
  return config.streamConfig.sponsorOn;
};
export const getUpNextOn = (component: string): number => {
  if (component === "audience") {
    return 1;
  }
  return config.streamConfig.upNextOn;
};
export const getNextUpType = (): string => {
  return config.streamConfig.upNextType;
};
export const getRecordsOn = (component: string): number => {
  if (component === "audience") {
    return 1;
  }
  return config.streamConfig.recordsOn;
};
export const getRecordsType = (): string => {
  return config.streamConfig.recordsType;
};
export const getLightsOn = (component: string): number => {
  if (component === "audience") {
    return 1;
  }
  return config.streamConfig.lightsOn;
};
export const getLifterTimeout = (component: string): number => {
  if (component === "audience") {
    return 0;
  }
  return config.streamConfig.lifterTimeout;
};
export const getLifterReappear = (component: string): number => {
  if (component === "audience") {
    return 1;
  }
  return config.streamConfig.lifterReappear;
};
export const getLiftBreak = (): number => {
  return config.streamConfig.liftBreak;
}
export const getLongLiftBreak = (): number => {
  return config.streamConfig.longLiftBreak;
}
export const getFlightBreak = (): number => {
  return config.streamConfig.flightBreak;
}

export const getLiftDuration = (): number => {
  if (config.streamConfig.liftDuration === undefined) {
    return 60
  }
  return config.streamConfig.liftDuration;
}

export const getWeighinDuration = (): number => {
  if (config.streamConfig.weighinDuration === undefined) {
    return 90
  }
  return config.streamConfig.weighinDuration;
}

export const getKitcheckDuration = (): number => {
  if (config.streamConfig.kitcheckDuration === undefined) {
    return 0
  }
  return config.streamConfig.kitcheckDuration;
}

export const getStartsInImages = (): Map<string, string> => {
  const imageMap = new Map<string, string>()
  imageMap.set('squat', config.streamConfig.startsIn.squatImage)
  imageMap.set('bench', config.streamConfig.startsIn.benchImage)
  imageMap.set('deadlift', config.streamConfig.startsIn.deadliftImage)
  return imageMap
};
export const getStartsInImagePosition = (): ScreenPosition => {
  return config.streamConfig.startsIn.startsInImagePosition;
};
export const getStartsInBoxPosition = (): TimerPositionData => {
  return config.streamConfig.startsIn.startsInTimerPosition;
};
