// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { ClockAction, OverwriteStoreAction } from "../types/actionTypes";
import { ClockState } from "../types/stateTypes";
import { SessionClockData, SessionClock, Clock } from "../types/dataTypes";
import { checkExhausted } from "../types/utils";

const initialState: ClockState = {
};

type Action = ClockAction | OverwriteStoreAction;

export default (state: ClockState = initialState, action: Action): ClockState => {
  
  switch (action.type) {

    case "UPDATE_CLOCK_TIME": 
    case "UPDATE_CLOCK_INUSE": 
    case "UPDATE_CLOCK_NAME": 
    case "UPDATE_CLOCK_PLACING": 
    {
      console.log(action)
      
      const day = action.day.toString();
      const platform = action .platform.toString();
      const session = action.session.toString();
      
      let dayClock
      const dayIndex = Object.keys(state).indexOf(day)
      if (dayIndex === -1) {
        dayClock = {}
      } else {
        dayClock = {...state[day]}
      }
      console.log(dayClock)

      let platformClock
      const platformIndex = Object.keys(dayClock).indexOf(platform)
      if (platformIndex === -1) {
        platformClock = {}
      } else {
        platformClock = {...dayClock[platform]}
      }
      console.log(platformClock)

      let sessionClockData: SessionClockData
      let sessionClock: SessionClock
      const sessionIndex = Object.keys(platformClock).indexOf(session)
      if (sessionIndex === -1) {
        sessionClock = {
          ['weighin']: {time: null, inUse: false},
          ['liftoff']: {time: null, inUse: false},
        }
        sessionClockData = {
          name: '',
          placing: 'SEX_EQUIPMENT_WEIGHTCLASS',
          sessionClock: sessionClock,
        }
      } else {
        sessionClockData = {...platformClock[session]}
        sessionClock = sessionClockData.sessionClock
      }
      console.log(sessionClock)

      if (action.type === "UPDATE_CLOCK_TIME") {
        sessionClock[action.tag].time = action.time
      } else if (action.type === "UPDATE_CLOCK_INUSE") {
        sessionClock[action.tag].inUse = action.inUse
      } else if (action.type === "UPDATE_CLOCK_NAME") {
        sessionClockData.name = action.name
      } else if (action.type === "UPDATE_CLOCK_PLACING") {
        sessionClockData.placing = action.placing
      }
      
      platformClock[session] = sessionClockData
      dayClock[platform] = platformClock

      console.log(dayClock)
      
      return {
        ...state,
        [day]: dayClock,
      };
    }

    case "OVERWRITE_STORE": {
      // TODO: currently code to add a clock to the store if one doesn't exist
      if (action.store.clock) {
        return action.store.clock;
      } else {
        return {...initialState};
      }
    }

    default:
      checkExhausted(action);
      return state;
  }
};
