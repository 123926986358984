// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { UpdateWarmupAction, UpdateActualAction, SetReplaysAction, UpdateCoachesAction, OverwriteStoreAction } from "../types/actionTypes";
import { Lift, EntryWarmups, EntryWarmup, EntryActuals, EntryReplays, EntryReplay, Coach } from "../types/dataTypes";
import { AppState } from "../types/stateTypes";
import { CoachLifters } from "../components/coach/Coach";

import { checkExhausted } from "../types/utils";

export const newDefaultWarmup = (): EntryWarmup => {
  return {
    offset: 30,
    relativeToAttnl: false,
  }
}

export const newDefaultWarmups = (entryId: number): EntryWarmups => {
  return {
    entryId: entryId,
    squatWarmup: newDefaultWarmup(),
    benchWarmup: newDefaultWarmup(),
    deadliftWarmup: newDefaultWarmup(),
  }
};

export const newDefaultActuals = (entryId: number): EntryActuals => {
  return {
    entryId: entryId,
    squatWarmup: '',
    benchWarmup: '',
    deadliftWarmup: '',
  }
};

export const newDefaultReplay = (attempt: number): EntryReplay => {
  return {
    attempt: attempt,
    replays: [],
  }
}

export const newDefaultReplays = (entryId: number): EntryReplays => {
  return {
    entryId: entryId,
    squatReplays: [],
    benchReplays: [],
    deadliftReplays: [],
  }
};

const newDefaultCoach = (name: string): Coach => {
  return {
    name: name,
    lifters: [],
  }
}

const initialState: AppState = {
  warmups: [],
  actuals: [],
  replays: [],
  coaches: [],
};

type Action = UpdateWarmupAction | UpdateActualAction | SetReplaysAction |UpdateCoachesAction | OverwriteStoreAction;

const getNewReplays = (replays: Array<EntryReplay>, attempt: number, entryReplay: EntryReplay) => {

  // Clone the replays array, since one slot will reference a new object.
  const newReplays: Array<EntryReplay> = replays.slice();

  const index = newReplays.findIndex((obj) => obj.attempt === attempt);
  if (index === -1) {
    newReplays.push(entryReplay);
  } else {
    newReplays[index] = entryReplay;
  }

  return newReplays;
}


export default (state: AppState = initialState, action: Action): AppState => {
  switch (action.type) {

    case "UPDATE_WARMUP":
    {
      
      //console.log(action)
      
      const entryId = action.entryId
      const lift = action.lift
      const offset = action.offset
      const relativeToAttnl = action.relativeToAttnl
      
      const entryWarmup: EntryWarmup = {
        offset: offset,
        relativeToAttnl: relativeToAttnl,
      }
      
      //console.log(entryWarmup)

      // Clone the warmup array, since one slot will reference a new object.
      const newWarmups: Array<EntryWarmups> = state.warmups.slice();
      
      //console.log(newWarmups)
      
      const index = newWarmups.findIndex((obj) => obj.entryId === entryId);
      let oldEntry;
      if (index === -1) {
        oldEntry = newDefaultWarmups(entryId);
      } else {
        oldEntry = newWarmups[index];
      }

      //console.log(oldEntry)
      
      // Make a new entry from the old entry, with the data overwritten.
      const newEntry = Object.assign({}, oldEntry);
      if (lift === 'S') {
        newEntry.squatWarmup = {...entryWarmup}
      } else if (lift === 'B') {
        newEntry.benchWarmup = {...entryWarmup}
      } else if (lift === 'D') {
        newEntry.deadliftWarmup = {...entryWarmup}
      }
      
      //console.log(newEntry)
      
      if (index === -1) {
        newWarmups.push(newEntry);
      } else {
        newWarmups[index] = newEntry;
      }

      //console.log(newWarmups)
      
      return {
        ...state,
        warmups: newWarmups,
      };
    }

    case "UPDATE_ACTUAL":
    {
      
      //console.log(action)
      
      const entryId = action.entryId
      const lift = action.lift
      const actualTime = action.actualTime
      
      // Clone the actual array, since one slot will reference a new object.
      const newActuals: Array<EntryActuals> = state.actuals.slice();
      
      //console.log(newActuals)
      
      const index = newActuals.findIndex((obj) => obj.entryId === entryId);
      let oldEntry;
      if (index === -1) {
        oldEntry = newDefaultActuals(entryId);
      } else {
        oldEntry = newActuals[index];
      }

      //console.log(oldEntry)
      
      // Make a new entry from the old entry, with the data overwritten.
      const newEntry = Object.assign({}, oldEntry);
      if (lift === 'S') {
        newEntry.squatWarmup = actualTime
      } else if (lift === 'B') {
        newEntry.benchWarmup = actualTime
      } else if (lift === 'D') {
        newEntry.deadliftWarmup = actualTime
      }
      
      //console.log(newEntry)
      
      if (index === -1) {
        newActuals.push(newEntry);
      } else {
        newActuals[index] = newEntry;
      }

      //console.log(newActuals)
      
      return {
        ...state,
        actuals: newActuals,
      };
    }

    case "SET_REPLAYS":
    {
      
      //console.log(action)
      
      const entryId = action.entryId
      const lift = action.lift
      const attempt = action.attempt
      const replays = action.replays
      
      // Clone the replays array, since one slot will reference a new object.
      let newReplays: Array<EntryReplays> = []
      if (state.replays !== undefined) {
        newReplays = state.replays.slice();
      }
      
      //console.log(newReplays)
      
      const index = newReplays.findIndex((obj) => obj.entryId === entryId);
      let oldEntry;
      if (index === -1) {
        oldEntry = newDefaultReplays(entryId);
      } else {
        oldEntry = newReplays[index];
      }

      //console.log(oldEntry)

      const entryReplay: EntryReplay = {
        attempt: attempt,
        replays: [...replays],
      }

      // Make a new entry from the old entry, with the data overwritten.
      const newEntry = Object.assign({}, oldEntry);
      if (lift === 'S') {
        const newSquatReplays = getNewReplays(newEntry.squatReplays, attempt, entryReplay)
        newEntry.squatReplays = [...newSquatReplays]
      } else if (lift === 'B') {
        const newBenchReplays = getNewReplays(newEntry.benchReplays, attempt, entryReplay)
        newEntry.benchReplays = [...newBenchReplays]
      } else if (lift === 'D') {
        const newDeadliftReplays = getNewReplays(newEntry.deadliftReplays, attempt, entryReplay)
        newEntry.deadliftReplays = [...newDeadliftReplays]
      }
      
      //console.log(newEntry)
      
      if (index === -1) {
        newReplays.push(newEntry);
      } else {
        newReplays[index] = newEntry;
      }

      //console.log(newReplays)
      
      return {
        ...state,
        replays: newReplays,
      };
    }

    case "UPDATE_COACHES":
    {
      console.log(action)
      const coaches = action.coaches
      console.log(coaches)
      console.log(typeof coaches)
      
      // build a new coaches array
      const newCoaches: Array<Coach> = []
      
      for (const [entryKey, entry] of Object.entries(coaches)) {
        console.log(entry)
        if (entry.name !== '') {
          const lifters: number[] = []
          for (const [lotKey, lot] of Object.entries(entry.lots)) {
            if (lot !== '') {
              lifters.push(Number(lot))
            }
          }
          const coach = {name: entry.name, lifters: lifters}
          newCoaches.push(coach)
        }
      }

      return {
        ...state,
        coaches: newCoaches,
      };
    }

    case "OVERWRITE_STORE":
      if (action.store.app) {
        return action.store.app;
      } else {
        return {
          ...initialState,
        }
      }

    default:
      checkExhausted(action);
      return state;
  }
};
