// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// The parent component of the FlightOrder page, contained by the FlightOrderContainer.

import React from "react";
import { connect } from "react-redux";

import LiftingTableBare from "./LiftingTableBare";

import { Entry, Flight } from "../../types/dataTypes";
import { GlobalState, ClockState, LiftingState, MeetState } from "../../types/stateTypes";
import { getLiftingOrder, getFlightLift } from "../../logic/liftingOrder";
import { getClockSessionName } from "../../logic/clock";

//import styles from "./FlightDetailsBare.module.scss";
import styles from "./LiftingTableBare.module.scss";
import { getTableData, getTestImage } from "../../logic/config";
import { getTitleWidth } from "../overlays/DataTable";

interface OwnProps {
  onlyPlatform: boolean;
  day: number;
  session: number;
  platform: number;
  knownFlights: Flight[];
  shownEntries: Array<Entry>;
  lifting: LiftingState;
  scale: number;
  full?: boolean;
  bare?: boolean;
}

interface StateProps {
  clocks: ClockState;
  meet: MeetState,
}

type Props = StateProps & OwnProps;

class FlightDetailsBare extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {

    const { onlyPlatform, day, session, platform, knownFlights, shownEntries, lifting, scale, full, bare } = this.props;

    const tableData = getTableData()

    const entryHeight = tableData.entryHeight
    const entryYGap = tableData.entryYGap
    
    // get table heights
    const heights = [];
    let totalHeight = 0;
    for (let i = 0; i < knownFlights.length; i++) {
      const flight = knownFlights[i];
      const entriesInFlight = shownEntries.filter((e) => e.flight === flight);
      const numRows = entriesInFlight.length + 2
      const tableHeight = (numRows * entryHeight) + ((numRows - 1) * entryYGap)
      heights.push(tableHeight)
      totalHeight += tableHeight
    }
    totalHeight += (heights.length - 1) * entryHeight
    let top = (1080 - totalHeight) / 2

    // FIXME: get these from generator
    const widths = [140, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 42, 66, 42]
    const entryXGap = 2
    const titleWidth = getTitleWidth(widths, entryXGap)

    let fullScale = 1
    let makeFull = false
    let left = 0
    if (full !== undefined && full === true) {
      makeFull = true
      // scale to fit screen, but don't exceed width or height
/*
      const xScale = 1920 / titleWidth
      const yScale = 1080 / totalHeight
      fullScale = Math.min(xScale, yScale)
      const scaledHeight = totalHeight * fullScale
      top = ((1080 - scaledHeight) / 2) / fullScale
      const scaledWidth = titleWidth * fullScale
      left = (scaledWidth - titleWidth) / 2
      console.log(titleWidth, xScale, totalHeight, yScale, top, left)
*/
      const xScale = 1920 / titleWidth
      const yScale = 1080 / totalHeight
      fullScale = Math.min(xScale, yScale)
      const scaledHeight = totalHeight * fullScale
      top = ((1080 - (scaledHeight)) / 2) / fullScale
    }
    let isBare = false
    if (bare !== undefined && bare === true) {
      isBare = true
      top = 0
    }

    // For each flight, see if there are any lifters, and build a LiftingTable.
    const flightDetails = [];
    for (let i = 0; i < knownFlights.length; i++) {
      const flight = knownFlights[i];
      const entriesInFlight = shownEntries.filter((e) => e.flight === flight);
      
      // check what lift the flight is on, and use that to get the lifting order
      const flightLifting = {...lifting}
      const flightLift = getFlightLift(entriesInFlight)
      if (flightLift != null) {
        flightLifting.lift = flightLift
      }
      
      const liftingOrder = getLiftingOrder(entriesInFlight, flightLifting);
      
      let currentEntryId = null;
      if (day === lifting.day && session === lifting.session && platform === lifting.platform && flight === lifting.flight) {
        currentEntryId = liftingOrder.currentEntryId;
      }
      
      const id = flight;
      if (makeFull) {
        flightDetails.push(<LiftingTableBare key={id} top={top} flight={flight} orderedEntries={liftingOrder.orderedEntries} currentEntryId={currentEntryId} platform={platform}/>);
      } else if (isBare) {
        flightDetails.push(<LiftingTableBare key={id} top={top} flight={flight} orderedEntries={liftingOrder.orderedEntries} currentEntryId={currentEntryId} platform={platform} left={0}/>);
      } else {
        flightDetails.push(<LiftingTableBare key={id} top={top} flight={flight} orderedEntries={liftingOrder.orderedEntries} currentEntryId={currentEntryId} platform={platform}/>);
      }

      top += heights[i] + entryHeight
    }

    let testImageUrl = ''
    const testImage = getTestImage()
    if (testImage !== undefined && testImage !== '') {
      testImageUrl = "url(./stream/" + testImage + ")"
    }
    console.log(testImageUrl)

    if (makeFull) {
      return (
        <div style={{position: 'absolute', top: '0px', left: '0px', width: '1920px', height: '1080px', overflow: 'hidden', backgroundImage: testImageUrl}}>
          <div style={{transform: `scale(${fullScale})`}}>
            {flightDetails}
          </div>
        </div>
      )
    }
    if (isBare) {
      return (
        <div>
          <div style={{transform: `scale(${fullScale})`}}>
            {flightDetails}
          </div>
        </div>
      )
    }
    return (
      <div style={{position: 'absolute', top: '0px', left: '0px', width: '1920px', height: '1080px', overflow: 'hidden', backgroundImage: testImageUrl}}>
        {flightDetails}
      </div>
    )

  }
}

/*
<div style={{
  //transformOrigin: "center top",     
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) scale(" + scale + ")",
}}>
*/

const mapStateToProps = (state: GlobalState): StateProps => ({
  clocks: state.clock,
  meet: state.meet,
});

export default connect(mapStateToProps)(FlightDetailsBare);
