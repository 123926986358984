// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect, RouteProps } from "react-router-dom";
import {Helmet} from "react-helmet";

import OpenLifterIntlProvider from "./components/translations/OpenLifterIntlProvider";

import RootContainer from "./containers/RootContainer";
import ResultsContainer from "./containers/ResultsContainer";
import Navigation from "./components/Navigation";

import configureStore from "./store";

import { getDefaultLanguage } from "./logic/strings";

import { ReduxStoreActionEmitter } from "./ReduxStoreActionEmitter";
import { ReduxSocketIoSynchronisationService } from "./ReduxSocketIoSynchronisationService";

import { config } from './logic/config';

import { getServername } from './server/server';
import ObsLeadersViewBare from "./components/leaders/ObsLeadersViewBare";
import ObsSessionDetailsBare from "./components/flight-details/ObsSessionDetailsBare";
import ObsFlightDetailsBare from "./components/flight-details/ObsFlightDetailsBare";
import ObsResultsViewBare from "./components/leaders/ObsResultsViewBare";
import ObsReplaysView from "./components/replays/ObsReplaysView";

function notPossiblyOnOverlay(): boolean {
  return typeof window.location.href !== "string" || window.location.href.toLowerCase().indexOf("stream") === -1;
}

function notOnAppLogin(): boolean {
  return typeof window.location.href !== "string" || window.location.href.toLowerCase().indexOf("/app") === -1;
}

//if (!__DEV__) {
//  console.log = () => {};
//}

class App extends React.Component {

  reduxSocketIoSynchronisationService: ReduxSocketIoSynchronisationService | undefined = undefined;

  constructor(props: any) {
    super(props);
    this.reduxSocketConnect = this.reduxSocketConnect.bind(this);
  }

  reduxSocketConnect(servername: string, username: string, password: string) {
    if (this.reduxSocketIoSynchronisationService) {
        this.reduxSocketIoSynchronisationService.connect(servername, username, password);
    }
  }

  render() {
    // We want to wrap an action emitter around the global store, to synchronise via a socket.
    const reduxStoreActionEmitter = new ReduxStoreActionEmitter();
    const store = configureStore({ language: getDefaultLanguage() }, reduxStoreActionEmitter);
    this.reduxSocketIoSynchronisationService = new ReduxSocketIoSynchronisationService(store);
    reduxStoreActionEmitter.subscribe((action) => this.reduxSocketIoSynchronisationService!.onStoreAction(action));

    const servername = getServername()
    this.reduxSocketConnect(servername, 'dummy', 'dummy');

    const PrivateRoute = ({ path, component, exact }:
                          { path: string, component: any, exact?: boolean}) => {
      // PrivateRoute is a wrapper component that protects a Route from unauthenticated access
      // If the Route is defined as public in global.json, access is allowed
      // It the Route is defined as private, it checks whether the user is logged in and allowed to access the Route
      // If access is allowed it returns a Route component, otherwise it returns a redirect to the /login screen
      if (exact) {
        const redirectpath = '/live';
        return (<Redirect to={{pathname: redirectpath, state: { from: {path} }}} />);
//        return (<Route exact path={path} component={component} />);
      } else {
        return (<Route path={path} component={component} />);
      }
    };

    return (
      // GlobalProvider is a HOC that sets up a global context, containing user authentication data and public/private/redirect paths
      // The data and paths are available to the Login/Logout components, and also used to show/hide Routes and Navs
      // Note that the global context is not stored in the store
      //
      // Provider is a React component from the react-redux library.
      // Its purpose is to "provide" the given store to its child components.
      // Because the Provider wraps the whole App here, the store is global state.
      //
      // Switch iterates over its children (Routes/PrivateRoutes) and renders the first one that matches the current path name
      //
      // Route takes a path and a component, and renders the given component if the current path matches the specified path.
      // PrivateRoute takes a path and a component, and renders the given component if the user is allowed to access and it matches the specified path.
      <Provider store={store}>
        <OpenLifterIntlProvider>

          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=0.45, shrink-to-fit=no" />
          </Helmet>

          <Router basename={process.env.REACT_APP_ROUTER_BASENAME}>
            <div>
              <Navigation />
              <Switch>
                <PrivateRoute exact path="/" component={RootContainer} />
                <PrivateRoute path="/live" component={ObsLeadersViewBare} />
                <PrivateRoute path="/flight" component={ObsFlightDetailsBare} />
                <PrivateRoute path="/session" component={ObsSessionDetailsBare} />
                <PrivateRoute path="/results" component={ObsResultsViewBare} />
                <PrivateRoute path="/replays" component={ObsReplaysView} />
              </Switch>
            </div>
          </Router>
        </OpenLifterIntlProvider>
      </Provider>
    );
  }
}

export default App;

//<meta name="viewport" content="width=device-width, initial-scale=0.5, maximum-scale=5, shrink-to-fit=yes" />
